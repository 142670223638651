import { Modal, ModalBody, ModalHeader, useToast } from 'oat-common-ui';
import React from 'react';
import { Offering, OfferingSettings, OfferingSettingsInput, useGetOfferingSettingsByOfferingIdQuery, useGetSeriesManagerQuery } from '../../gql/generated';
import useGetEfcData from '../../hooks/useGetEfcData';
import useStores from '../../stores/useStores';
import seriesListFilter from '../../utils/seriesListFilter';
import OfferingModal from './OfferingModal';

interface OfferingModalWrapperProps {
  offeringId: string;
  copy?: boolean;
  offering?: any;
  onClose: () => void;
  onCreate: (offeringName: string, startDate: Date, endDate: Date, offeringSettings: OfferingSettingsInput[]) => Promise<void>;
  onEdit: (offeringName: string, startDate: Date, endDate: Date, offeringSettings: OfferingSettingsInput[]) => Promise<void>;
  onCopy: (offeringName: string, startDate: Date, endDate: Date) => Promise<void>;
  activeOfferings: Offering[];
  archivedOfferings: Offering[];
}

const OfferingModalWrapper = ({ offeringId, copy, offering, onClose, onCreate, onEdit, onCopy, activeOfferings, archivedOfferings }: OfferingModalWrapperProps) => {
  const { seriesManagerStore, offeringSettingsStore, efcStore, regionsStore, userInfoStore } = useStores();
  const { data, loading, error } = useGetOfferingSettingsByOfferingIdQuery({ variables: { offeringId }, fetchPolicy: 'no-cache', skip: !offeringId });
  const {
    data: seriesData,
    loading: seriesLoading,
    error: seriesError,
  } = useGetSeriesManagerQuery({ variables: { brand: userInfoStore.userInfo.brand }, fetchPolicy: 'no-cache' });
  const { loading: efcIsLoading, error: efcError } = useGetEfcData();
  const { error: toastError } = useToast();

  if (loading || efcIsLoading || seriesLoading) {
    return (
      <Modal isOpen>
        <ModalHeader onClose={onClose}>Loading</ModalHeader>
        <ModalBody />
      </Modal>
    );
  }

  const allError = error || seriesError || efcError;
  if (allError) {
    toastError(allError.message);
    onClose();
  }

  if (seriesData) {
    seriesManagerStore.setSeriesSettings(seriesData?.seriesByBrand.series as string[]);
  }

  regionsStore.setRegions(userInfoStore.userInfo.brand);

  if (data) {
    offeringSettingsStore.setOfferingSettings(offeringId, data.offeringSettingsByOfferingId as OfferingSettings[]);
  }

  if ((!offeringId || data) && efcStore.series && seriesManagerStore.isLoaded) {
    const fullSeriesList = seriesListFilter(efcStore.series, seriesManagerStore.seriesSettings);

    return (
      <OfferingModal
        openModal
        copy={copy}
        offering={offering}
        onClose={onClose}
        onCreate={onCreate}
        onEdit={onEdit}
        onCopy={onCopy}
        activeOfferings={activeOfferings}
        archivedOfferings={archivedOfferings}
        seriesList={fullSeriesList}
        offeringSettings={offeringId ? offeringSettingsStore.offeringSettings[offeringId] : undefined}
        states={regionsStore.states}
      />
    );
  }

  return null;
};

export default OfferingModalWrapper;

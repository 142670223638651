import { observer } from 'mobx-react-lite';
import { assignStringValue, BlockUi, IOffer, IOfferStatusError, OATLink, OfferingLayout, OfferStatus, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Link, Redirect } from 'react-router-dom';
import apolloClient from '../../apolloClient';
import MainFooter from '../../components/MainFooter';
import MainHeader from '../../components/MainHeader';
import {
  GetOfferingByIdDocument,
  GetOffersByOfferingIdDocument,
  Offer,
  useCopyOfferMutation,
  useDeleteOfferMutation,
  usePreviewOfferingMutation,
  usePublishOfferingMutation,
  useUpdateOfferingStatusMutation,
  useUpdateOfferStatusMutation,
} from '../../gql/generated';
import useStores from '../../stores/useStores';
import buildOfferUrl from '../../utils/buildOfferUrl';

const OfferingComponent = () => {
  const {
    offeringsStore: { currentOffering, updateOffering, setCurrentOffering, getOfferingById },
    offersStore: { getOffersByOfferingId, removeOffer, updateOffer, loadOffers, copyOffer },
    efcStore,
    userInfoStore,
    seriesManagerStore: { uniqueSeries },
  } = useStores();
  const [deleteOffer] = useDeleteOfferMutation();
  const [copyOfferMutation] = useCopyOfferMutation();
  const [updateOfferStatus] = useUpdateOfferStatusMutation();
  const [updateOfferingStatus] = useUpdateOfferingStatusMutation();
  const [publishOffering] = usePublishOfferingMutation();
  const [previewOffering] = usePreviewOfferingMutation();
  const { success, error } = useToast();
  const [createOfferType, setCreateOfferType] = useState('');

  const myoffers = getOffersByOfferingId(currentOffering.id);
  const [offers, setOffers] = useState(myoffers);
  const offerTypeFilters = Array.from(new Set(offers.map(item => item.offerType)));
  const seriesFilters = Array.from(new Set(offers.map(item => item.series)));
  const [offerMarketFlagMap, setOfferMarkteFlagMap] = useState(new Map<string, IOfferStatusError>());
  const [block, setBlock] = useState(false);
  const [previewBlock, setPreviewBlock] = useState(false);

  const handleChangeOffering = async (statusParam: OfferStatus) => {
    try {
      const res = await trackPromise(
        updateOfferingStatus({
          variables: { id: assignStringValue(currentOffering.id), rev: assignStringValue(currentOffering.rev), status: statusParam.toString() },
        }),
      );

      updateOffering(assignStringValue(currentOffering.id), {
        status: res?.data?.updateOfferingStatus?.status,
        rev: res?.data?.updateOfferingStatus?.rev,
      });

      const changedOffering = getOfferingById(currentOffering.id);
      if (changedOffering) {
        setCurrentOffering(changedOffering);
        success('Offering status updated successfully.');
      }

      if (res.data?.updateOfferingStatus.offers) {
        let newUpdatedOffers: any[] = [];
        let newOfferMarketFlagMap = new Map();
        res.data.updateOfferingStatus.offers.forEach(offer => {
          const currOffer = offers.filter(o => o.id === offer.offerId)[0];
          currOffer.rev = offer.rev;
          currOffer.status = offer.status;
          newOfferMarketFlagMap.set(offer.offerId, { success: offer.success, error: offer.error });
          newUpdatedOffers.push(currOffer);
        });
        setOfferMarkteFlagMap(newOfferMarketFlagMap);
        setOffers(newUpdatedOffers);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleRemoveOffer = async (offer: IOffer) => {
    try {
      await trackPromise(deleteOffer({ variables: { id: assignStringValue(offer.id), rev: assignStringValue(offer.rev) } }));
      removeOffer(assignStringValue(offer.id));
      setOffers(getOffersByOfferingId(currentOffering.id));
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleCopyOffer = async (offer: IOffer) => {
    try {
      const res = await trackPromise(copyOfferMutation({ variables: { id: assignStringValue(offer.id) } }));
      const copiedOffer = res.data?.copyOffer.offer;
      if (copiedOffer) {
        copyOffer(copiedOffer);
        setOffers(getOffersByOfferingId(currentOffering.id));
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleChangeOfferStatus = async (offer: IOffer, status: OfferStatus) => {
    try {
      const res = await trackPromise(updateOfferStatus({ variables: { id: assignStringValue(offer.id), rev: assignStringValue(offer.rev), status: status.toString() } }));
      const resOffer = res.data?.updateOfferStatus?.offer as Offer;
      updateOffer(resOffer.id, resOffer);
      setOffers(getOffersByOfferingId(currentOffering.id));
      offerMarketFlagMap.set(assignStringValue(resOffer.id), { success: true, error: '' });
      success('Offer status updated successfully.');
    } catch (e) {
      offerMarketFlagMap.set(assignStringValue(offer.id), { success: false, error: (e as Error).message });
      error((e as Error).message);
    }
  };

  const handleOnCreateOffer = (type: string) => {
    setCreateOfferType(type);
  };

  if (createOfferType) {
    return <Redirect to={buildOfferUrl(currentOffering.id, createOfferType, 'info', 'en')} />;
  }

  const renderOfferLink = (offer: IOffer) => {
    return (
      <Link component={OATLink} id={`offer-link-${offer.id}`} to={buildOfferUrl(currentOffering.id, offer.offerType, 'info', 'en', offer.id)}>
        {offer.offerType}
      </Link>
    );
  };

  const validatePublish = () => {
    return offers.filter(o => o.status === OfferStatus.READY_TO_PUBLISH || (o.isPublished && o.status === OfferStatus.PAUSED)).length > 0 || currentOffering.isDirty;
  };

  const handlePublish = async () => {
    try {
      setBlock(true);
      let offeringId = currentOffering.id;
      const res = await trackPromise(publishOffering({ variables: { id: assignStringValue(offeringId), rev: assignStringValue(currentOffering.rev) } }));

      if (res.data?.publishOffering?.success) {
        success('Offering status updated successfully.');
        offeringId = res.data?.publishOffering?.offering?.id;
        const changedOffering = await apolloClient.query({ query: GetOfferingByIdDocument, variables: { id: offeringId }, fetchPolicy: 'no-cache' });
        setCurrentOffering(changedOffering.data.offering);
        updateOffering(offeringId, changedOffering.data.offering);

        const offersRefresh = await apolloClient.query({ query: GetOffersByOfferingIdDocument, variables: { offeringId }, fetchPolicy: 'no-cache' });
        setOffers(offersRefresh.data.offersByOfferingId);
        loadOffers(offeringId, offersRefresh.data.offersByOfferingId);
      }

      setBlock(false);
    } catch (e) {
      setBlock(false);
      error((e as Error).message);
    }
  };

  const handlePreviewPublish = async () => {
    try {
      setPreviewBlock(true);
      const offeringId = currentOffering.id;
      await trackPromise(previewOffering({ variables: { id: assignStringValue(offeringId), rev: assignStringValue(currentOffering.rev) } }));

      success('Published to preview environment successfully.');
      setPreviewBlock(false);
    } catch (e) {
      setPreviewBlock(false);
      error((e as Error).message);
    }
  };

  return (
    <>
      <MainHeader
        breadCrumbs={[
          { name: '', component: <Link to="/dashboard">{userInfoStore.properties.fullName}</Link> },
          { name: `Sales Period: ${currentOffering.name} (${currentOffering.startDate} - ${currentOffering.endDate}) ` },
        ]}
      />
      <OfferingLayout
        canPreview={!userInfoStore.isLexus()}
        offering={currentOffering}
        offers={offers}
        offerTypeFilters={offerTypeFilters}
        seriesFilters={seriesFilters}
        seriesMap={efcStore.uniqueYearSeries}
        uniqueSeriesMap={uniqueSeries}
        offerMarketFlagMap={offerMarketFlagMap}
        renderOfferLink={renderOfferLink}
        onCreateOffer={handleOnCreateOffer}
        onChangeOfferStatus={handleChangeOfferStatus}
        onChangeOfferingStatus={handleChangeOffering}
        onRemoveOffer={handleRemoveOffer}
        onCopyOffer={handleCopyOffer}
        statusOptions={[OfferStatus.IN_PROGRESS, OfferStatus.READY_TO_PUBLISH, OfferStatus.PAUSED]}
        createButtonText="Create Offer"
      />
      <BlockUi blocking={block} title="Publishing Offering ..." />
      <BlockUi blocking={previewBlock} title="Publish to Preview In Progress ..." />
      <MainFooter
        isShowPublish
        isDisablePublish={!validatePublish()}
        onPublish={handlePublish}
        onPreviewPublish={handlePreviewPublish}
        isShowPreview={userInfoStore.isLexus()}
        darkTheme
      />
    </>
  );
};

export default observer(OfferingComponent);

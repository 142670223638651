import { dateStringToDate, ErrorMessages, IOffering } from 'oat-common-ui';
import OfferingsSettingsType from '../../pages/Dashboard/OfferingModal/models/OfferingSettingsType';
import { validateAllOfferings, validateIndividualOfferings } from './utils';

const validateOfferings = <T extends IOffering>(
  activeOfferings: T[],
  archivedOfferings: T[],
  offeringName: string,
  startDate: Date,
  endDate: Date,
  enhancedList: OfferingsSettingsType[],
  offeringId?: string,
  copy?: boolean,
) => {
  if (!offeringName) {
    return ErrorMessages.OFFERING_NO_NAME;
  }

  const allOfferings = [...activeOfferings, ...archivedOfferings];
  const startDateTime = dateStringToDate(startDate).getTime();
  const endDateTime = dateStringToDate(endDate).getTime();

  const { error, dontCheckDate } = validateAllOfferings(offeringName, allOfferings, startDateTime, endDateTime, offeringId);
  let errorMsg = error;

  const { offeringError } = validateIndividualOfferings(enhancedList, errorMsg, startDate, endDate);
  errorMsg = offeringError;

  if (!dontCheckDate && copy && startDateTime <= dateStringToDate(new Date()).getTime()) {
    return ErrorMessages.OFFERING_START_DATE_INVALID;
  }

  return errorMsg;
};

export default validateOfferings;

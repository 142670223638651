import { AdminConstants } from 'oat-admin-common';
import { defaultProperties, lexusProperties, toyotaProperties } from '../constants/brandProperties';
import { User } from '../gql/generated';

const { BRAND_LEXUS, BRAND_TOYOTA } = AdminConstants;

class UserInfoStore {
  isLoaded = false;
  properties = defaultProperties;
  userInfo: User = {
    azureGroups: '',
    brand: '',
    email: '',
    name: '',
    objectId: '',
    regionCodes: '',
    roles: '',
    tdaCodes: '',
    userTypes: '',
  };

  isLexus = () => {
    return this.userInfo.brand === BRAND_LEXUS;
  };

  setUserInfo = (userInfo: User, isLoaded: boolean) => {
    this.userInfo = userInfo;
    this.isLoaded = isLoaded;

    if (userInfo.brand === BRAND_TOYOTA) {
      this.properties = toyotaProperties;
    }
    if (userInfo.brand === BRAND_LEXUS) {
      this.properties = lexusProperties;
    }
  };

  isLdaLcertDropdownEnabled = () => {
    const { azureGroups } = this.userInfo;
    const hasLcertGroup = azureGroups.indexOf('lcert') > -1;
    const hasLdaGroup =
      azureGroups.indexOf('lsa') > -1 || //
      azureGroups.indexOf('lea') > -1 ||
      azureGroups.indexOf('lwa') > -1 ||
      azureGroups.indexOf('lca') > -1;

    return hasLcertGroup && hasLdaGroup;
  };
}

export default UserInfoStore;

import { makeObservable, observable, runInAction } from 'mobx';
import { OfferMarketing } from '../gql/generated';

interface IOfferMarketing {
  [offerId: string]: OfferMarketing[];
}

class OfferMarketingStore {
  offerMarketing = {} as IOfferMarketing;
  currentOfferMarketing: OfferMarketing[] = [];

  constructor() {
    makeObservable(this, {
      offerMarketing: observable,
      currentOfferMarketing: observable,
    });
  }

  offerMarketingExists = (offerId: string) => {
    return Object.keys(this.offerMarketing).findIndex(id => id === offerId) !== -1;
  };

  getOfferMarketingById = (offerId: string) => {
    return this.offerMarketing[offerId];
  };

  setOfferMarketing = (offerId: string, offerMarketing: OfferMarketing[]) => {
    runInAction(() => {
      this.offerMarketing = { ...this.offerMarketing, [offerId]: offerMarketing };
    });
  };

  setCurrentOfferMarketing = (offerMarketing: OfferMarketing[]) => {
    runInAction(() => {
      this.currentOfferMarketing = offerMarketing;
    });
  };
}

export default OfferMarketingStore;

import { EfcApi } from 'oat-common-ui';
import { useGetSeriesManagerQuery } from '../gql/generated';
import useStores from '../stores/useStores';
import useGetEfcData from './useGetEfcData';

const useGetEfcSeriesData = () => {
  const { seriesManagerStore, userInfoStore, efcStore } = useStores();

  const { data, loading, error } = useGetSeriesManagerQuery({ variables: { brand: userInfoStore.userInfo.brand }, fetchPolicy: 'no-cache' });
  const { loading: efcLoading, error: efcError } = useGetEfcData();

  // set Series
  if (data) {
    seriesManagerStore.setSeriesSettings(data?.seriesByBrand.series as string[]);
  }

  // process unique years with EFC and Series manager data
  if (seriesManagerStore.isLoaded && efcStore.series) {
    const uniqueSeries = EfcApi.getSeriesV1UniqueSeries(efcStore.series).concat(
      seriesManagerStore.seriesSettings.map(item => ({ code: item.split(' ').join('').toLowerCase(), title: item })),
    );
    seriesManagerStore.setUniqueSeries(uniqueSeries);
  }

  return {
    loading: loading || efcLoading,
    error: error || efcError,
  };
};

export default useGetEfcSeriesData;

import { createContext, useContext } from 'react';
import OfferTabsStore from './CashOfferTabStore';
import DashboardStore from './DashboardStore';
import EFCStore from './EFCStore';
import MiscTypesStore from './MiscTypesStore';
import OfferingSettingsStore from './OfferingSettingsStore';
import OfferingsStore from './OfferingsStore';
import OfferMarketingStore from './OfferMarketingStore';
import OffersStore from './OffersStore';
import RegionsStore from './RegionStore';
import SeriesManagerStore from './SeriesManagerStore';
import TokenStore from './TokenStore';
import UserInfoStore from './UserInfoStore';

const storesContext = createContext({
  dashboardStore: new DashboardStore(),
  efcStore: new EFCStore(),
  miscTypesStore: new MiscTypesStore(),
  offersStore: new OffersStore(),
  offeringsStore: new OfferingsStore(),
  offerTabsStore: new OfferTabsStore(),
  regionsStore: new RegionsStore(),
  seriesManagerStore: new SeriesManagerStore(),
  tokenStore: new TokenStore(),
  userInfoStore: new UserInfoStore(),
  offeringSettingsStore: new OfferingSettingsStore(),
  offerMarketingStore: new OfferMarketingStore(),
});

const useStores = () => useContext(storesContext);

export default useStores;

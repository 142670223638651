import { makeObservable, observable, runInAction } from 'mobx';
import { GetOfferingsForDashboardQuery, Offering } from '../gql/generated';

class OfferingsStore {
  activeOfferings: Offering[] = [];
  archivedOfferings: Offering[] = [];
  currentOffering: Offering = {
    brand: '',
    created: '',
    createdBy: '',
    endDate: '',
    id: '',
    isDownstream: '',
    name: '',
    rev: '',
    startDate: '',
    status: '',
    updated: '',
    updatedBy: '',
    isDirty: false,
  };

  offeringsLoaded = false;

  constructor() {
    makeObservable(this, {
      activeOfferings: observable,
      archivedOfferings: observable,
      currentOffering: observable,
    });
  }

  setCurrentOffering = (offering: Offering) => {
    runInAction(() => {
      this.currentOffering = offering;
    });
  };

  getOfferingById = (offeringId: string) => {
    return [...this.activeOfferings, ...this.archivedOfferings].find(item => item.id === offeringId);
  };

  offeringExists = (offeringId: string) => {
    return [...this.activeOfferings, ...this.archivedOfferings].findIndex(item => item.id === offeringId) !== -1;
  };

  loadOfferings = (data: GetOfferingsForDashboardQuery | undefined) => {
    runInAction(() => {
      if (data) {
        this.activeOfferings = [...(data.active as Offering[])];
        this.archivedOfferings = [...(data.archived as Offering[])];
        this.offeringsLoaded = true;
      }
    });
  };

  addOffering = (offering: Offering) => {
    runInAction(() => {
      this.activeOfferings = [...this.activeOfferings, offering];
    });
  };

  updateOffering = (offeringId: string, props: Partial<Offering>) => {
    runInAction(() => {
      this.activeOfferings = this.activeOfferings.map(item => {
        if (item.id === offeringId) {
          return {
            ...item,
            ...props,
          };
        }

        return item;
      });
    });
  };

  removeOffering = (offeringId: string) => {
    runInAction(() => {
      this.activeOfferings = this.activeOfferings.filter(item => item.id !== offeringId);
    });
  };
}

export default OfferingsStore;

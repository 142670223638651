import { OfferTypes } from 'oat-common-ui';
import { ReactNode, useState } from 'react';
import OfferTabBreadCrumbs from '../../../components/OfferTabComponents/OfferTabBreadCrumbs';
import { Offering, useGetOfferingWithOfferQuery } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import Error from '../../Error';
import Loading from '../../Loading';
import useGetEfcSeriesData from '../../../hooks/useGetEfcSeriesData';

interface Props {
  offerType: OfferTypes;
  component: ReactNode;
}

const FinancialBase = ({ offerType, component }: Props) => {
  const { userInfoStore, offeringsStore, offersStore } = useStores();
  const { offeringId, offerId } = useUrlParams();

  const { data, loading, error } = useGetOfferingWithOfferQuery({
    variables: { offeringId, offerId },
    fetchPolicy: 'no-cache',
  });

  const [loaded, setLoaded] = useState(false);
  const breadCrumbs = OfferTabBreadCrumbs(userInfoStore.properties.fullName, offeringsStore.getOfferingById(offeringId), offerType);

  const { loading: seriesLoading, error: seriesError } = useGetEfcSeriesData();

  if (loading || seriesLoading) {
    return <Loading breadCrumbs={breadCrumbs} />;
  }

  if (error || seriesError) {
    return <Error breadCrumbs={breadCrumbs} />;
  }

  if (data) {
    offeringsStore.addOffering(data.offering as Offering);
    offersStore.addOffer(data.offer);
  }

  if (!loaded) {
    offersStore.setCurrentOffer(offersStore.getOfferById(offerId));
    offeringsStore.setCurrentOffering(offeringsStore.getOfferingById(offeringId) as Offering);
    setLoaded(true);
  }

  return <>{component}</>;
};

export default FinancialBase;

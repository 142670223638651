import { observer } from 'mobx-react-lite';
import { Button, dateFormatISOString, DefaultModal, DefaultTable, OfferingsLayout, sortFieldsHelper, useToast } from 'oat-common-ui';
import React, { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import MainFooter from '../../components/MainFooter';
import MainHeader from '../../components/MainHeader';
import {
  Offering,
  OfferingSettingsInput,
  useCopyOfferingMutation,
  useCreateOfferingMutation,
  useDeleteOfferingMutation,
  useSaveOfferingSettingsMutation,
  useUpdateOfferingMutation,
} from '../../gql/generated';
import useStores from '../../stores/useStores';
import { isOutOfDate } from '../../utils/isOutOfDate';
import DashboardRow from './DashboardRow';
import OfferingModalWrapper from './OfferingModalWrapper';

const DashboardComponent = () => {
  const { offeringsStore, offersStore, userInfoStore } = useStores();
  const { error } = useToast();
  const [saveOffering] = useSaveOfferingSettingsMutation();
  const [createOffering] = useCreateOfferingMutation();
  const [copyOffering] = useCopyOfferingMutation();
  const [deleteOffering] = useDeleteOfferingMutation();
  const [updateOffering] = useUpdateOfferingMutation();
  const [modalOffering, setModalOffering] = useState<{
    open: boolean;
    copy?: boolean;
    offering?: Offering;
  }>({ open: false });
  const [deleteOfferingModal, setDeleteOfferingModal] = useState<Offering | undefined>();

  const [sortFieldActive, setSortFieldActive] = useState('startDate');
  const [sortAscActive, setSortAscActive] = useState(true);
  const [sortFieldArchived, setSortFieldArchived] = useState('endDate');
  const [sortAscArchived, setSortAscArchived] = useState(false);

  const columns = [
    {
      header: 'NAME',
      sortable: true,
      field: 'name',
    },
    {
      header: 'START DATE',
      sortable: true,
      field: 'startDate',
      descendingFirst: true,
    },
    {
      header: 'END DATE',
      sortable: true,
      field: 'endDate',
      descendingFirst: true,
    },
    {
      header: 'Status',
      sortable: true,
      field: 'status',
    },
    {
      header: '',
    },
  ];

  const renderRow = (offerings: Offering[], canEdit: boolean) => {
    return (
      <>
        {offerings.map(item => {
          return (
            <DashboardRow
              key={item.id}
              item={item}
              canEdit={canEdit}
              onClickEdit={() => {
                setModalOffering({ open: true, copy: false, offering: item });
              }}
              onClickDelete={() => {
                setDeleteOfferingModal(item);
              }}
              isStarted={isOutOfDate(item.startDate)}
              onClickCopy={() => setModalOffering({ open: true, copy: true, offering: item })}
            />
          );
        })}
      </>
    );
  };

  const onDeleteOffering = async () => {
    try {
      await trackPromise(
        deleteOffering({
          variables: {
            id: deleteOfferingModal?.id || '',
            rev: deleteOfferingModal?.rev || '',
          },
        }),
      );

      offeringsStore.removeOffering(deleteOfferingModal?.id || '');
      offersStore.removeOffersByOfferingId(deleteOfferingModal?.id || '');
    } catch (e) {
      if (e instanceof Error) {
        error(e.message);
      }
    } finally {
      setDeleteOfferingModal(undefined)
    }
  };

  const invokeCreateOffering = async (offeringName: string, startDate: Date, endDate: Date, offeringSettings: OfferingSettingsInput[]) => {
    const res = await trackPromise(
      createOffering({
        variables: {
          brand: userInfoStore.userInfo.brand,
          name: offeringName,
          startDate: dateFormatISOString(startDate),
          endDate: dateFormatISOString(endDate),
        },
      }),
    );

    // update store offering (in case offering settings fail)
    const newData = res?.data?.createOffering?.offering as Offering;
    offeringsStore.addOffering(newData);
    // need to update offering for modal
    setModalOffering({ ...modalOffering, open: true, offering: newData });

    await invokeSaveOffering(newData, offeringSettings, false);
  };

  const invokeSaveOffering = async (offeringData: Offering, offeringSettings: OfferingSettingsInput[], isUpdateOffering: boolean) => {
    const offering = offeringData;
    const resSave = await trackPromise(
      saveOffering({
        variables: {
          offeringId: offering.id,
          settings: offeringSettings,
        },
      }),
    );

    offering.rev = resSave?.data?.saveOfferingSettings?.offeringRev || '';
    offeringsStore.updateOffering(offering.id, offering);

    setModalOffering({ ...modalOffering, open: false, offering });
  };

  const invokeCopyOffering = async (offeringName: string, startDate: Date, endDate: Date) => {
    const res = await trackPromise(
      copyOffering({
        variables: {
          id: modalOffering.offering?.id || '',
          rev: modalOffering.offering?.rev || '',
          name: offeringName,
          startDate: dateFormatISOString(startDate),
          endDate: dateFormatISOString(endDate),
        },
      }),
    );

    const newData = res?.data?.copyOffering?.offering as Offering;
    offeringsStore.addOffering(newData);
    setModalOffering({ open: false });
  };

  const invokeUpdateOffering = async (offeringName: string, startDate: Date, endDate: Date, offeringSettings: OfferingSettingsInput[]) => {
    const res = await trackPromise(
      updateOffering({
        variables: {
          id: modalOffering.offering?.id || '',
          rev: modalOffering.offering?.rev || '',
          name: offeringName,
          startDate: dateFormatISOString(startDate),
          endDate: dateFormatISOString(endDate),
        },
      }),
    );

    // update store offering (in case offering settings fail)
    const newData = res?.data?.updateOffering?.offering as Offering;
    offeringsStore.updateOffering(newData.id, newData);
    // need to update offering for modal
    setModalOffering({ ...modalOffering, open: true, offering: newData });

    await invokeSaveOffering(newData, offeringSettings, true);
  };

  const onSortActive = (field: string, asc: boolean) => {
    setSortFieldActive(field);
    setSortAscActive(asc);
  };

  const onSortArchived = (field: string, asc: boolean) => {
    setSortFieldArchived(field);
    setSortAscArchived(asc);
  };

  const activeOfferings = offeringsStore.activeOfferings.slice().sort(sortFieldsHelper(sortFieldActive, sortAscActive));
  const archivedOfferings = offeringsStore.archivedOfferings.slice().sort(sortFieldsHelper(sortFieldArchived, sortAscArchived));

  return (
    <>
      <OfferingsLayout
        renderHeader={<MainHeader breadCrumbs={[{ name: userInfoStore.properties.abbrName }]} />}
        renderButton={
          <Button
            id="create-offering-btn"
            variant="primary"
            onClick={() => {
              setModalOffering({ open: true });
            }}
          >
            Create New Offering
          </Button>
        }
        renderActiveTable={
          <DefaultTable
            id="activeTbl"
            title="Active Incentive Periods"
            columns={columns}
            renderRows={renderRow(activeOfferings, true)}
            onSort={onSortActive}
            defaultSortField={'startDate'}
            defaultSortAscending={true}
          />
        }
        renderArchiveTable={
          <DefaultTable
            id="archiveTbl"
            title="Archived Incentive Periods"
            collapsable
            columns={columns}
            renderRows={renderRow(archivedOfferings, false)}
            onSort={onSortArchived}
            defaultSortField={'endDate'}
            defaultSortAscending={false}
          />
        }
      >
        <MainFooter />
      </OfferingsLayout>
      <DefaultModal
        open={!!deleteOfferingModal}
        title="Delete Offering"
        message={`Are you sure you want to delete ${deleteOfferingModal?.name}? This action cannot be undone.`}
        submitText="Delete"
        onClose={() => setDeleteOfferingModal(undefined)}
        onSubmit={onDeleteOffering}
      />
      {modalOffering.open && (
        <OfferingModalWrapper
          offeringId={modalOffering.offering?.id || ''}
          copy={modalOffering.copy}
          offering={modalOffering.offering}
          onClose={() => setModalOffering({ open: false })}
          onCreate={invokeCreateOffering}
          onEdit={invokeUpdateOffering}
          onCopy={invokeCopyOffering}
          activeOfferings={activeOfferings}
          archivedOfferings={archivedOfferings}
        />
      )}
    </>
  );
};

export default observer(DashboardComponent);

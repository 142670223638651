import { observer } from 'mobx-react-lite';
import { OATWrapper, OfferTabReturnCta, OfferTypes, assignStringValue, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MainFooter from '../../../../components/MainFooter';
import MainHeader from '../../../../components/MainHeader';
import OfferTabBreadCrumbs from '../../../../components/OfferTabComponents/OfferTabBreadCrumbs';
import OfferTabNavList from '../../../../components/OfferTabComponents/OfferTabNavList';
import { Offer, useUpdateOfferMutation } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import buildOfferUrl from '../../../../utils/buildOfferUrl';
import CashMiscForm from '../CashMiscForm';

const MiscFinancialTabComponent = () => {
  const {
    offeringsStore: { currentOffering },
    offersStore: { currentOffer, updateOffer, setCurrentOffer },
    efcStore,
    miscTypesStore,
    userInfoStore,
    seriesManagerStore,
  } = useStores();

  const [updateOfferMutation] = useUpdateOfferMutation();
  const { lang } = useUrlParams();
  const { success, error } = useToast();
  const [redirect, setRedirect] = useState('');
  const returnLink = `/offering/${currentOffering.id}`;

  const uniqueSeries = seriesManagerStore.uniqueSeries.concat([{ code: 'MULTI', title: 'MULTI' }]);
  const seriesMap = efcStore.uniqueYearSeries.find(efc => efc.code === currentOffer?.series) ? efcStore.uniqueYearSeries : uniqueSeries;

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const handleOnSubmit = async (
    cashOfferAmount: number,
    extraCashAmount: number | null,
    extraCashLabel: string,
    fundingSource: string,
    miscType: string,
    back: boolean,
    next: boolean,
  ) => {
    try {
      const res = await updateOfferMutation({
        variables: {
          id: assignStringValue(currentOffer?.id),
          rev: assignStringValue(currentOffer?.rev),
          cashOfferAmount: cashOfferAmount,
          extraCashAmount: extraCashAmount,
          extraCashLabel,
          fundingSource,
          miscType,
          isAdvertised: Boolean(currentOffer?.isAdvertised),
          ...(!userInfoStore.isLexus() && { certificationType: currentOffer?.certificationType }),
        },
      });

      const newOffer = res.data?.updateOffer.offer as Offer;
      updateOffer(assignStringValue(currentOffer?.id), newOffer);
      setCurrentOffer(newOffer);

      if (back) {
        setRedirect(buildOfferUrl(currentOffering?.id, 'misc', 'info', lang, currentOffer?.id));
      }

      if (next) {
        setRedirect(buildOfferUrl(currentOffering?.id, 'misc', 'marketing', lang, currentOffer?.id));
      }
      success('Financial saved successfully.');
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <>
      <MainHeader breadCrumbs={OfferTabBreadCrumbs(userInfoStore.properties.fullName, currentOffering, OfferTypes.MISCELLANEOUS)} />
      <OATWrapper withPadding>
        <Link to={returnLink} component={OfferTabReturnCta} />
        <CashMiscForm
          offering={currentOffering}
          offer={currentOffer}
          seriesMapData={seriesMap}
          isMisc
          miscTypes={miscTypesStore.miscTypes}
          navList={OfferTabNavList(currentOffering.id, OfferTypes.MISCELLANEOUS, lang, currentOffer?.id, currentOffer?.isAdvertised)}
          onSubmit={handleOnSubmit}
        />
      </OATWrapper>
      <MainFooter darkTheme />
    </>
  );
};

export default observer(MiscFinancialTabComponent);

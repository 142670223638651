import { assignStringValue, OfferTypes } from 'oat-common-ui';
import React, { useState } from 'react';
import OfferTabBreadCrumbs from '../../../components/OfferTabComponents/OfferTabBreadCrumbs';
import {
  Disclaimer,
  Offering,
  Snippet,
  useGetOfferByIdQuery,
  useGetOfferingByIdQuery,
  useGetOfferMarketingByOfferIdQuery,
  useGetTokensSnippetsDisclaimersQuery,
} from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import Error from '../../Error';
import Loading from '../../Loading';
import MarketingTabComponentBase from './MarketingTabComponentBase';
import useGetEfcSeriesData from '../../../hooks/useGetEfcSeriesData';

interface Props {
  offerType: OfferTypes;
}

const MarketingTabBase = ({ offerType }: Props) => {
  const {
    offeringsStore,
    offerMarketingStore,
    offersStore,
    userInfoStore: { userInfo },
    userInfoStore,
    tokenStore,
  } = useStores();
  const { offeringId, offerId } = useUrlParams();

  const { data, loading, error } = useGetOfferingByIdQuery({
    variables: { id: offeringId },
    fetchPolicy: 'no-cache',
    skip: offeringsStore.offeringExists(offeringId),
  });

  const {
    data: tokenData,
    loading: tokenLoading,
    error: tokenError,
  } = useGetTokensSnippetsDisclaimersQuery({ variables: { brand: userInfo.brand }, fetchPolicy: 'no-cache', skip: tokenStore.isLoaded });

  const {
    data: offerData,
    loading: offerLoading,
    error: offerError,
  } = useGetOfferByIdQuery({
    variables: { id: assignStringValue(offerId) },
    fetchPolicy: 'no-cache',
    skip: !offerId || offersStore.offerExists(offerId),
  });

  const {
    data: marketingData,
    loading: marketingLoading,
    error: marketingError,
  } = useGetOfferMarketingByOfferIdQuery({
    variables: {
      offerId: assignStringValue(offerId),
    },
    fetchPolicy: 'no-cache',
  });

  const [loaded, setLoaded] = useState(false);
  const breadCrumbs = OfferTabBreadCrumbs(userInfoStore.properties.fullName, offeringsStore.getOfferingById(offeringId), offerType);

  const { loading: seriesLoading, error: seriesError } = useGetEfcSeriesData();

  if (loading || tokenLoading || marketingLoading || offerLoading || seriesLoading) {
    return <Loading breadCrumbs={breadCrumbs} />;
  }

  if (error || tokenError || marketingError || offerError || seriesError) {
    return <Error breadCrumbs={breadCrumbs} />;
  }

  if (data) {
    offeringsStore.addOffering(data.offering as Offering);
  }

  if (offerData) {
    offersStore.addOffer(offerData.offer);
  }

  if (tokenData) {
    tokenStore.setTokensSnippetsDisclaimers(tokenData?.tokens, tokenData?.snippets as Snippet[], tokenData?.disclaimers as Disclaimer[]);
  }

  if (!loaded) {
    if (marketingData) {
      offerMarketingStore.setOfferMarketing(assignStringValue(offerId), marketingData.offerMarketingByOfferId);
    }

    offersStore.setCurrentOffer(offersStore.getOfferById(assignStringValue(offerId)));
    offeringsStore.setCurrentOffering(offeringsStore.getOfferingById(offeringId) as Offering);
    offerMarketingStore.setCurrentOfferMarketing(offerMarketingStore.getOfferMarketingById(assignStringValue(offerId)));
    setLoaded(true);
  }

  return <MarketingTabComponentBase offerType={offerType} />;
};

export default MarketingTabBase;

import { CustomDatePicker, DefaultMultiSelectPanes, IMultiItem, InputContainer, TierTable, TierTableDataType } from 'oat-common-ui';
import React, { useEffect, useState } from 'react';
import TableDataType from '../models/TableDataType';
import styles from './styles.module.scss';

interface EnhancedItemProps {
  uid?: string;
  type: string;
  startDate?: Date;
  endDate?: Date;
  tierTable: TableDataType;
  seriesSelectorData: any;
  removeEnhancedItem: (uid: string) => void;
  formatEnhancedList: (
    uid: string,
    tableData?: TierTableDataType,
    selectorData?: any,
    eStartDate?: Date,
    eEndDate?: Date,
    statesSelectorData?: { included: IMultiItem[]; excluded: IMultiItem[] },
  ) => void;
  statesData?: any;
  role?: string;
}

const EnhancedItem = ({ type, uid = '', startDate, endDate, tierTable, statesData, removeEnhancedItem, seriesSelectorData, formatEnhancedList, role }: EnhancedItemProps) => {
  const [enhancedStartDate, setEnhancedStartDate] = useState(startDate);
  const [enhancedEndDate, setEnhancedEndDate] = useState(endDate);
  const { defaultTierLength, maxTierLength, defaultMonthsLength, maxMonthsLength, tierTableData } = tierTable;
  const [tableData, setTableData] = useState<TierTableDataType | undefined>();
  const [selectorData, setSelectorData] = useState<any | undefined>();
  const [statesSelectorData, setStatesSelectorData] = useState<any | undefined>();

  const returnTableData = (rTableData: TierTableDataType) => {
    setTableData(rTableData);
  };

  const returnSelectorData = (excluded: IMultiItem[], included: IMultiItem[]) => {
    setSelectorData({ excluded, included });
  };

  const returnStatesSelectorData = (excluded: IMultiItem[], included: IMultiItem[]) => {
    setStatesSelectorData({ excluded, included });
  };

  useEffect(() => {
    formatEnhancedList(uid, tableData, selectorData, enhancedStartDate, enhancedEndDate, statesSelectorData);
    // eslint-disable-next-line
  }, [tableData, selectorData, statesSelectorData, enhancedStartDate, enhancedEndDate]);

  if (type === 'apr') {
    return (
      <div role={role}>
        <div className={styles.datePickerContainer}>
          <InputContainer label="Rate Start Date:">
            <CustomDatePicker
              id="rate-start-date-picker"
              selected={enhancedStartDate}
              onChange={date => {
                if (date) {
                  setEnhancedStartDate(date);
                }
              }}
            />
          </InputContainer>
          <InputContainer label="Rate End Date:">
            <CustomDatePicker
              id="rate-end-date-picker"
              selected={enhancedEndDate}
              onChange={date => {
                if (date) {
                  setEnhancedEndDate(date);
                }
              }}
            />
          </InputContainer>
        </div>
        <TierTable
          defaultTierLength={defaultTierLength}
          maxTierLength={maxTierLength}
          defaultMonthsLength={defaultMonthsLength}
          maxMonthsLength={maxMonthsLength}
          tierTableData={tierTableData}
          returnTableData={returnTableData}
          type="apr"
        />
        <DefaultMultiSelectPanes excludedList={seriesSelectorData.excluded} includedList={seriesSelectorData.included} returnSelectorData={returnSelectorData} />

        {statesData && <DefaultMultiSelectPanes includedList={statesData.included} excludedList={statesData.excluded} returnSelectorData={returnStatesSelectorData} />}

        <button type="button" onClick={() => removeEnhancedItem(uid)} className={styles.removeButton}>
          Remove
        </button>
      </div>
    );
  }

  return (
    <div role={role}>
      <TierTable
        defaultTierLength={defaultTierLength}
        maxTierLength={maxTierLength}
        defaultMonthsLength={defaultMonthsLength}
        maxMonthsLength={maxMonthsLength}
        tierTableData={tierTableData}
        returnTableData={returnTableData}
        type="lease"
      />
      {statesData && <DefaultMultiSelectPanes includedList={statesData.included} excludedList={statesData.excluded} returnSelectorData={returnStatesSelectorData} />}
      <button type="button" onClick={() => removeEnhancedItem(uid)} className={styles.removeButton}>
        Remove
      </button>
    </div>
  );
};

export default EnhancedItem;

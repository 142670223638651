import { dateStringToDate, ErrorMessages, IOffering } from 'oat-common-ui';
import OfferingsSettingsType from '../../pages/Dashboard/OfferingModal/models/OfferingSettingsType';
import compareStartEndDates from '../compareStartEndDates';

// validating offerings against each other
export const validateAllOfferings = <T extends IOffering>(offeringName: string, offerings: T[], startDateTime: number, endDateTime: number, offeringId?: string) => {
  let error = '';
  let dontCheckDate = false;

  offerings.forEach(offering => {
    const offeringStartDate = dateStringToDate(offering.startDate).getTime();
    const offeringEndDate = dateStringToDate(offering.endDate).getTime();

    // on create, offeringId = undefined and on copy, offeringId = ''. Check against all existing offerings
    // on edit, offeringId = 'someVal'. Checks against other offerings
    if (offering.id !== offeringId) {
      if (offering.name.trim().toLowerCase() === offeringName.trim().toLowerCase()) {
        error = ErrorMessages.OFFERING_DUPLICATE_NAME;
      }

      if ((startDateTime >= offeringStartDate && startDateTime <= offeringEndDate) || (endDateTime >= offeringStartDate && endDateTime <= offeringEndDate)) {
        error = ErrorMessages.OFFERING_INCENTIVE_PERIOD_OVERLAP;
      }

      // on edit, do special checks on applied offeringId.
    } else {
      // if dates are the same, that means user didn't edit dates. So don't do date check if name is only changed.
      dontCheckDate = startDateTime === offeringStartDate && endDateTime === offeringEndDate;
    }
  });

  return {
    error,
    dontCheckDate,
  };
};

// validate date overlap
const validateDateOverlap = (
  enhancedStartDate: string | null | undefined,
  enhancedEndDate: string | null | undefined,
  startDate: string | Date,
  endDate: string | Date,
  errorMsg: string,
) => {
  if (
    (enhancedStartDate && compareStartEndDates(enhancedStartDate, startDate)) ||
    (enhancedStartDate && compareStartEndDates(endDate, enhancedStartDate)) ||
    (enhancedEndDate && compareStartEndDates(endDate, enhancedEndDate)) ||
    (enhancedEndDate && compareStartEndDates(enhancedEndDate, startDate))
  ) {
    return ErrorMessages.OFFERING_ENHANCED_INCENTIVE_PERIOD_OVERLAP;
  }

  return errorMsg;
};

// validates offerings themselves
export const validateIndividualOfferings = (enhancedList: OfferingsSettingsType[], errorMsg: string, startDate: string | Date, endDate: string | Date) => {
  let offeringError = errorMsg;

  enhancedList.forEach(item => {
    const includedStates = item?.statesSelectorData?.included;

    if (includedStates && includedStates.length < 1) {
      offeringError = ErrorMessages.OFFERING_INCLUDED_STATES_REQUIRED;
    }

    if (item.type === 'apr') {
      const includedSeries = item?.seriesSelectorData?.included;
      const enhancedStartDate = item?.startDate;
      const enhancedEndDate = item?.endDate;

      if (includedSeries && includedSeries.length < 1) {
        offeringError = ErrorMessages.OFFERING_INCLUDED_SERIES_REQUIRED;
      }

      if (enhancedStartDate && enhancedEndDate && compareStartEndDates(enhancedEndDate, enhancedStartDate)) {
        offeringError = ErrorMessages.OFFERING_END_DATE_INVALID;
      }

      offeringError = validateDateOverlap(enhancedStartDate, enhancedEndDate, startDate, endDate, offeringError);
    }
  });

  return {
    offeringError,
  };
};

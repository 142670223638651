import { SettingsBreadCrumbs } from '../../components/SettingsComponents';
import { useGetSeriesManagerQuery } from '../../gql/generated';
import useStores from '../../stores/useStores';
import Error from '../Error';
import Loading from '../Loading';
import SeriesManagerSettingsComponent from './SeriesManagerSettingsComponent';

const SeriesManagerSettings = () => {
  const { seriesManagerStore, userInfoStore } = useStores();
  const { data, loading, error } = useGetSeriesManagerQuery({ variables: { brand: userInfoStore.userInfo.brand }, fetchPolicy: 'network-only' });

  if (loading) {
    return <Loading breadCrumbs={SettingsBreadCrumbs(userInfoStore.properties.fullName)} />;
  }

  if (error) {
    return <Error />;
  }

  if (data) {
    seriesManagerStore.setSeriesSettings(data?.seriesByBrand.series as string[]);
  }

  return <SeriesManagerSettingsComponent />;
};

export default SeriesManagerSettings;

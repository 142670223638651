import { BreadCrumbType } from 'oat-common-ui';
import { Link } from 'react-router-dom';
import { Offering } from '../../gql/generated';

const getSalesPeriodText = (offering: Offering) => {
  return `Sales Period: ${offering.name} (${offering.startDate} - ${offering.endDate})`;
};

const OfferTabBreadCrumbs = (fullName: string, offering?: Offering, offerType?: string) => {
  const breadCrumbs: BreadCrumbType[] = [
    {
      name: '',
      component: <Link to="/dashboard">{fullName}</Link>,
    },
  ];

  if (offering) {
    if (offerType) {
      breadCrumbs.push({ name: '', component: <Link to={`/offering/${offering.id}`}>{getSalesPeriodText(offering)}</Link> });
      breadCrumbs.push({ name: offerType });
    } else {
      breadCrumbs.push({ name: getSalesPeriodText(offering) });
    }
  }

  return breadCrumbs;
};

export default OfferTabBreadCrumbs;

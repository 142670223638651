import { observer } from 'mobx-react-lite';
import { SettingsLayout, TokenSettingsLayout, useToast } from 'oat-common-ui';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import MainHeader from '../../components/MainHeader';
import { SettingsBreadCrumbs, SettingsNav } from '../../components/SettingsComponents';
import {
  Disclaimer,
  Snippet,
  useCreateDisclaimerMutation,
  useCreateSnippetMutation,
  useDeleteDisclaimerMutation,
  useDeleteSnippetMutation,
  useUpdateDisclaimerMutation,
  useUpdateSnippetMutation,
} from '../../gql/generated';
import useStores from '../../stores/useStores';

const _SNIPPETS_HEADER = 'Snippets';
const _SNIPPETS_MODAL_HEADER = 'Snippet';
const _DISCLAIMERS_HEADER = 'Disclaimers';
const _DISCLAIMERS_MODAL_HEADER = 'Disclaimer';

const TokenSettingsComponent = () => {
  const {
    tokenStore,
    userInfoStore: {
      properties: { fullName },
      userInfo: { brand },
    },
  } = useStores();
  const [createSnippet] = useCreateSnippetMutation();
  const [createDisclaimer] = useCreateDisclaimerMutation();
  const [deleteSnippet] = useDeleteSnippetMutation();
  const [deleteDisclaimer] = useDeleteDisclaimerMutation();
  const [updateSnippet] = useUpdateSnippetMutation();
  const [updateDisclaimer] = useUpdateDisclaimerMutation();
  const { error } = useToast();

  const groups = [
    {
      name: _SNIPPETS_HEADER,
      modalName: _SNIPPETS_MODAL_HEADER,
      tokenData: { data: tokenStore.snippets, dataTokens: [] },
    },
    {
      name: _DISCLAIMERS_HEADER,
      modalName: _DISCLAIMERS_MODAL_HEADER,
      tokenData: { data: tokenStore.disclaimers, dataTokens: tokenStore.snippets },
    },
  ];

  const addToken = async (group: string, name: string, text: string, setSelectedCb: (token: any) => void) => {
    if (group === _SNIPPETS_HEADER) {
      const res = await trackPromise(
        createSnippet({
          variables: {
            brand,
            name,
            text,
          },
        }),
      );

      const newSnip = res.data?.createSnippet.snippet as Snippet;
      tokenStore.setSnippets([...tokenStore.snippets, newSnip]);
      setSelectedCb(newSnip);
    }

    if (group === _DISCLAIMERS_HEADER) {
      const res = await trackPromise(
        createDisclaimer({
          variables: {
            brand,
            name,
            text,
          },
        }),
      );

      const newDisc = res.data?.createDisclaimer.disclaimer as Disclaimer;
      tokenStore.setDisclaimers([...tokenStore.disclaimers, newDisc]);
      setSelectedCb(newDisc);
    }
  };

  const deleteToken = async (group: string, id: string) => {
    try {
      if (group === _SNIPPETS_HEADER) {
        const snippItem = tokenStore.snippets.find(item => item.id === id);
        await trackPromise(deleteSnippet({ variables: { id, rev: snippItem?.rev || '' } }));
        tokenStore.setSnippets(tokenStore.snippets.filter(item => item.id !== id));
      }

      if (group === _DISCLAIMERS_HEADER) {
        const discItem = tokenStore.disclaimers.find(item => item.id === id);
        await trackPromise(deleteDisclaimer({ variables: { id, rev: discItem?.rev || '' } }));
        tokenStore.setDisclaimers(tokenStore.disclaimers.filter(item => item.id !== id));
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const editToken = async (group: string, id: string, name: string, text: string) => {
    if (group === _SNIPPETS_HEADER) {
      const snippItem = tokenStore.snippets.find(item => item.id === id);
      const res = await trackPromise(updateSnippet({ variables: { id, rev: snippItem?.rev || '', name, text } }));
      tokenStore.setSnippets(tokenStore.snippets.map(item => (item.id === id ? { ...(res.data?.updateSnippet.snippet as Snippet) } : item)));
    }

    if (group === _DISCLAIMERS_HEADER) {
      const discItem = tokenStore.disclaimers.find(item => item.id === id);
      const res = await trackPromise(updateDisclaimer({ variables: { id, rev: discItem?.rev || '', name, text } }));
      tokenStore.setDisclaimers(tokenStore.disclaimers.map(item => (item.id === id ? { ...(res.data?.updateDisclaimer.disclaimer as Disclaimer) } : item)));
    }
  };

  return (
    <>
      <MainHeader showMiscLinks={false} breadCrumbs={SettingsBreadCrumbs(fullName)} />
      <SettingsLayout settings={SettingsNav}>
        <TokenSettingsLayout groups={groups} tokens={tokenStore.tokens} onCreate={addToken} onEdit={editToken} onDelete={deleteToken} />
      </SettingsLayout>
    </>
  );
};

export default observer(TokenSettingsComponent);

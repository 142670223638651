import { observer } from 'mobx-react-lite';
import { useGetOfferingsForDashboardQuery } from '../../gql/generated';
import useStores from '../../stores/useStores';
import Loading from '../Loading';
import DashboardComponent from './DashboardComponent';

const Dashboard = () => {
  const { userInfoStore, dashboardStore, offeringsStore } = useStores();
  const { data, loading } = useGetOfferingsForDashboardQuery({
    variables: { brand: userInfoStore.userInfo.brand },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <Loading breadCrumbs={[{ name: userInfoStore.properties.abbrName }]} />;
  }

  if (data) {
    offeringsStore.loadOfferings(data);
    dashboardStore.processQuery(data);
  }

  return <DashboardComponent />;
};

export default observer(Dashboard);

import { dateFormatISOString, IOfferingEnhancement, TierTableDataType } from 'oat-common-ui';
import { EnhancedRateRcfInput, OfferingSettingsInput } from '../../../../gql/generated';
import OfferingsSettingsType from '../models/OfferingSettingsType';

const processTierData = (tierTable: TierTableDataType[] | undefined, type: string): EnhancedRateRcfInput[] => {
  if (!tierTable) {
    return [];
  }

  const finalTierData: IOfferingEnhancement[] = [];

  tierTable.forEach(row => {
    row.tiers.forEach(col => {
      if (col.rate) {
        finalTierData.push({ term: row.term, tier: col.tier, [type === 'apr' ? 'rate' : 'rcf']: Number(col.rate) });
      }
    });
  });

  return finalTierData;
};

const processSettingsPayload = (enhancedList: OfferingsSettingsType[]): OfferingSettingsInput[] => {
  const aprPayload: OfferingSettingsInput[] = [];
  const leasePayload: OfferingSettingsInput[] = [];

  enhancedList.forEach(item => {
    if (item.type === 'apr') {
      aprPayload.push({
        type: item.type,
        startDate: item.startDate ? dateFormatISOString(item.startDate) : undefined,
        endDate: item.endDate ? dateFormatISOString(item.endDate) : undefined,
        includedSeries: item.seriesSelectorData?.included?.map(series => series.id),
        includedStates: item.statesSelectorData?.included?.map(state => state.id),
        excludedStates: item.statesSelectorData?.excluded?.map(state => state.id),
        enhancements: processTierData(item.tierTable.tierTableData, item.type),
        id: item.id,
        rev: item.rev,
      });
    }

    if (item.type === 'lease') {
      leasePayload.push({
        type: item.type,
        enhancements: processTierData(item.tierTable.tierTableData, item.type),
        includedStates: item.statesSelectorData?.included?.map(state => state.id),
        excludedStates: item.statesSelectorData?.excluded?.map(state => state.id),
        id: item.id,
        rev: item.rev,
      });
    }
  });

  return leasePayload.concat(aprPayload);
};

export default processSettingsPayload;

import { IOfferingEnhancement, tierData, TierTableDataType } from 'oat-common-ui';

const filterTierData = (tierTableData: IOfferingEnhancement[], isLease: boolean) => {
  let defaultMonthsLength: number = isLease ? 3 : 5;
  let defaultTierLength = 4;
  const maxTierLength = 9;
  const maxMonthsLength: number = isLease ? 3 : 6;

  const tierDataCopy = tierData.map(termItem => ({
    term: termItem.term,
    tiers: termItem.tiers.map(tierItem => ({
      tier: tierItem.tier,
      rate: '',
    })),
  }));

  const processRow = (row: TierTableDataType, item: IOfferingEnhancement) => {
    row.tiers.forEach(col => {
      if (col.tier === item.tier) {
        col.rate = (item.rcf || item.rate)?.toString() || '';
      }
    });
  };

  // enhancements
  tierTableData.forEach(item => {
    if (item.term > 72) {
      defaultMonthsLength = 6;
    }

    let currentTier = Number(item.tier) + 1;
    if (item.tier === '1+') {
      currentTier = defaultTierLength;
    } else if (item.tier === '7+') {
      currentTier = 9;
    }

    defaultTierLength = Math.max(defaultTierLength, currentTier);

    tierDataCopy.forEach(row => {
      if (Number(row.term) === item.term) {
        processRow(row, item);
      }
    });
  });

  return { defaultTierLength, maxTierLength, defaultMonthsLength, maxMonthsLength, tierTableData: tierDataCopy };
};

export default filterTierData;

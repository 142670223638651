import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BreadCrumbType, DefaultHeaderLayout, OATHeaderLink, OATHeaderLogo, OATHeaderTitle, OATIcon, OATLoadingBar, OATToastContainer } from 'oat-common-ui';
import { useMemo } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Link } from 'react-router-dom';
import useStores from '../../stores/useStores';

interface Props {
  breadCrumbs?: BreadCrumbType[];
  showProgressBar?: boolean;
  showMiscLinks?: boolean;
}

const MainHeader = ({ breadCrumbs = [], showProgressBar, showMiscLinks = true }: Props) => {
  const { promiseInProgress } = usePromiseTracker();
  const {
    userInfoStore: {
      isLoaded,
      properties: { abbrName },
      isLexus,
      isLdaLcertDropdownEnabled,
    },
  } = useStores();

  const isLowerEnv = useMemo(() => {
    return window.location.hostname.indexOf('nonprod') !== -1 || window.location.hostname.indexOf('localhost') !== -1;
  }, []);

  const renderMiscLinks = () => {
    return showMiscLinks ? <OATHeaderLink icon={<OATIcon icon="gear" size={14} />} name="Settings" component={<Link to="/settings/personal">Settings</Link>} /> : null;
  };

  const getTitle = () => {
    const appName = abbrName ? ` - ${abbrName}` : '';
    return `Offer Admin Tool${appName}`;
  };

  const lexusNav = isLdaLcertDropdownEnabled()
    ? [
        {
          name: '',
          icon: <FontAwesomeIcon icon={faShareSquare} />,
          component: <a href={`${process.env.REACT_APP_MARKETING_URL}`}>LDA</a>,
        },
      ]
    : [];

  lexusNav.push({
    name: '',
    icon: <OATIcon icon="gear" size={14} />,
    component: <Link to="/settings/personal">Settings</Link>,
  });

  return (
    <>
      <DefaultHeaderLayout
        isProd={!isLowerEnv}
        breadCrumbs={breadCrumbs}
        renderLogo={isLoaded && <OATHeaderLogo isLexus={isLexus()} nav={isLexus() ? lexusNav : undefined} />}
        renderTitle={<OATHeaderTitle>{getTitle()}</OATHeaderTitle>}
        renderMiscLinks={!isLexus() && renderMiscLinks()}
      />
      <OATToastContainer />
      {(promiseInProgress || showProgressBar) && <OATLoadingBar />}
    </>
  );
};

export default MainHeader;

const getOfferingTitle = (copy: boolean, offering: boolean) => {
  if (copy) {
    return 'Copy Offering';
  }

  if (offering) {
    return 'Edit Offering';
  }

  return 'Create Offering';
};

export default getOfferingTitle;

export const tiersOptions = [
  { value: '', label: 'Select Tier' },
  { value: '1+', label: '1+' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
];

export const termsOptions = [
  { value: '', label: 'Select Term' },
  { value: '24', label: '24' },
  { value: '36', label: '36' },
  { value: '48', label: '48' },
  { value: '60', label: '60' },
  { value: '72', label: '72' },
  { value: '84', label: '84' },
];

import { runInAction } from 'mobx';
import { EfcApi, SeriesV1Response, UniqueSeriesType } from 'oat-common-ui';
import processEfcData from '../utils/processEfcData';

class EFCStore {
  series: SeriesV1Response | undefined;
  uniqueYearSeries: UniqueSeriesType[] = [];

  setSeriesV1 = async (data: SeriesV1Response, brand: string) => {
    runInAction(() => {
      this.series = processEfcData(data, brand);
      this.uniqueYearSeries = EfcApi.getSeriesV1UniqueSeries(data);
    });
  };
}

export default EFCStore;

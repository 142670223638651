import { runInAction } from 'mobx';

class SeriesManagerStore {
  isLoaded = false;
  seriesSettings: string[] = [];
  uniqueSeries: { code: string; title: string }[] = [];

  setSeriesSettings = (data: string[]) => {
    runInAction(() => {
      this.seriesSettings = data;
      this.isLoaded = true;
    });
  };

  setUniqueSeries = (uniqueSeries: { code: string; title: string }[]) => {
    runInAction(() => {
      this.uniqueSeries = uniqueSeries;
    });
  };
}

export default SeriesManagerStore;

import { OfferTypes } from 'oat-common-ui';
import React from 'react';
import FinancialBase from '../FinancialBase';
import LeaseFinancialTabComponent from './LeaseFinancialTabComponent';

const LeaseFinancialTab = () => {
  return <FinancialBase offerType={OfferTypes.LEASE} component={<LeaseFinancialTabComponent />} />;
};

export default LeaseFinancialTab;

export const defaultProperties = {
  abbrName: '',
  fullName: '',
};

export const toyotaProperties = {
  abbrName: 'TCUV',
  fullName: 'Toyota Certified Used Vehicles',
};

export const lexusProperties = {
  abbrName: 'LCERT',
  fullName: 'Lexus Certified Used Vehicles',
};

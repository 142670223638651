import { useLocation, useParams } from 'react-router-dom';

type ParamsType = {
  offeringId: string;
};

const useUrlParams = () => {
  const { offeringId } = useParams<ParamsType>();
  const query = new URLSearchParams(useLocation().search);
  const offerId = query.get('offerId') ?? '';
  const lang = query.get('lang') ?? '';

  return {
    offeringId,
    offerId,
    lang,
  };
};

export default useUrlParams;

import { dateFormatISOString, IMultiItem, TierTableDataType } from 'oat-common-ui';
import OfferingSettingsType from '../models/OfferingSettingsType';
import formatTableData from './formatTableData';

const formatEnhancedListHelper = (
  uid: string,
  enhancedList: OfferingSettingsType[],
  tableData: TierTableDataType | undefined,
  selectorData?: { excluded?: IMultiItem[]; included?: IMultiItem[] },
  eStartDate?: Date,
  eEndDate?: Date,
  statesSelectorData?: { excluded?: IMultiItem[]; included?: IMultiItem[] },
) => {
  return enhancedList.map(listItem => {
    if (listItem.uid === uid) {
      return {
        ...listItem,
        startDate: listItem.type === 'apr' ? dateFormatISOString(eStartDate) : undefined,
        endDate: listItem.type === 'apr' ? dateFormatISOString(eEndDate) : undefined,
        seriesSelectorData: selectorData && listItem.type === 'apr' ? selectorData : listItem.seriesSelectorData,
        statesSelectorData: statesSelectorData && {
          included: statesSelectorData.included?.sort((a, b) => (a.id > b.id ? 1 : -1)),
          excluded: statesSelectorData.excluded?.sort((a, b) => (a.id > b.id ? 1 : -1)),
        },
        tierTable: tableData && listItem.type === 'apr' ? formatTableData(tableData, listItem.tierTable) : listItem.tierTable,
      } as OfferingSettingsType;
    }

    return listItem;
  });
};

export default formatEnhancedListHelper;

import { OATIcon, OATLink, TableCtas, Tooltip } from 'oat-common-ui';
import React from 'react';
import { Link } from 'react-router-dom';
import { Offering } from '../../gql/generated';
import styles from './styles.module.scss';

interface DashboardRowProps {
  item: Offering;
  onClickCopy: () => void;
  onClickDelete: () => void;
  onClickEdit: () => void;
  canEdit: boolean;
  isStarted?: boolean;
}

const DashboardRow = ({ item, onClickCopy, onClickDelete, onClickEdit, canEdit, isStarted }: DashboardRowProps) => {
  return (
    <tr className={styles.offeringRow}>
      <td>
        <Link component={OATLink} to={`/offering/${item.id}`}>
          {item.name}
        </Link>
      </td>
      <td>{item.startDate}</td>
      <td>{item.endDate}</td>
      <td>{item.status}</td>
      <td>
        <div className={styles.tableCtas}>
          <TableCtas>
            {isStarted && (
              <Tooltip id="delete" message="Delete">
                <OATIcon id={`offering-delete-${item.id}`} icon="trash" size={14} onClick={onClickDelete} />
              </Tooltip>
            )}
            {canEdit && (
              <Tooltip id="edit" message="Edit">
                <OATIcon id={`offering-edit-${item.id}`} icon="gear" size={14} onClick={onClickEdit} />
              </Tooltip>
            )}
            <Tooltip id="copy" message="Copy">
              <OATIcon id={`offering-copy-${item.id}`} icon="copy" onClick={onClickCopy} />
            </Tooltip>
          </TableCtas>
        </div>
      </td>
    </tr>
  );
};

export default DashboardRow;

import { APRCalcFns } from 'oat-admin-common';
import {
  APRHeaderDetails,
  assignNumberValue,
  Dropdown,
  DropdownItem,
  FinancialTabFooter,
  InlineInputLabel,
  Input,
  IOffer,
  IOffering,
  OfferTabBodyLayout,
  OfferTabError,
  OfferTabsHeader,
  OfferTabsNav,
  OfferTypes,
  stringToNumber,
  UniqueSeriesType,
  useInput,
  usePromiseLoading,
} from 'oat-common-ui';
import { ReactNode, useState } from 'react';
import { termsOptions, tiersOptions } from './options';
import styles from './styles.module.scss';

const { calculateDollarsPerThousand } = APRCalcFns;

interface Props {
  navList: ReactNode[];
  offering: IOffering;
  offer?: IOffer;
  seriesMapData: UniqueSeriesType[];
  onSubmit: (
    fundingSource: string,
    tier: string,
    term: string,
    rate: number,
    back: boolean,
    next: boolean,
    dollarPerThousand?: number | null,
    extraCashAmount?: number | null,
    subventionAmount?: number | null,
    extraCashLabel?: string,
  ) => Promise<void>;
}

const labelWidth = 275;

const APRFinancialForm = ({ offer, navList, seriesMapData, onSubmit }: Props) => {
  const [tiersFilter, setTiersFilter] = useState<DropdownItem | undefined>(tiersOptions.find(item => item.value === offer?.tier) || { value: '', label: 'Select Tier' });
  const [termsFilter, setTermsFilter] = useState<DropdownItem | undefined>(termsOptions.find(item => item.value === offer?.term) || { value: '', label: 'Select Term' });
  const [submitted, setSubmitted] = useState(false);

  const [fundingSource, fundingSourceBindFn, fundingSourceError] = useInput(offer?.fundingSource, { required: true });
  const [rateAmount, rateAmountBindFn, rateAmountError] = useInput(offer?.rate, { isDecimal: true, required: true, min: 0, max: 100 });
  const [amountPerThou, amountPerThouBindFn, amountPerThouError, setAmountPerThou] = useInput(offer?.dollarPerThousand);
  const [ecAmount, ecAmountBindFn, ecAmountError] = useInput(offer?.extraCashAmount, { isWhole: true });
  const [sAmount, sAmountBindFn, sAmountError] = useInput(offer?.subventionAmount, { isWhole: true });
  const [ecLabel, ecLabelBindFn, ecLabelError] = useInput(offer?.extraCashLabel);

  const { promiseLoading, loadPromise } = usePromiseLoading();

  const handleTiersChange = (newVal: DropdownItem) => {
    if(typeof newVal.label === "string"){
      setTiersFilter({value: newVal.value, label:newVal.label});
    }
  };

  const handleTermsChange = (newVal: DropdownItem) => {
    if(typeof newVal.label === "string"){
      setTermsFilter({ value: newVal.value, label: newVal.label });
    }
  };

  const hasError = !tiersFilter?.value || !termsFilter?.value || rateAmountError || fundingSourceError;

  const handleOnSubmit = async (back: boolean, next: boolean) => {
    setSubmitted(true);

    if (hasError) {
      return;
    }

    await loadPromise(
      onSubmit(
        fundingSource,
        tiersFilter?.value || '',
        termsFilter?.value || '',
        Number(rateAmount),
        back,
        next,
        stringToNumber(amountPerThou),
        stringToNumber(ecAmount),
        stringToNumber(sAmount),
        ecLabel,
      ),
    );
  };

  const handleReCalculation = (rate: string, term: string) => {
    if (!rate || !term) {
      setAmountPerThou('');
    } else if (assignNumberValue(rate) >= 0 && assignNumberValue(rate) <= 100) {
      setAmountPerThou(calculateDollarsPerThousand(Number(rate), Number(term)));
    }
  };

  return (
    <>
      <OfferTabsHeader
        offerType={OfferTypes.APR}
        endDate={offer?.endDate || ''}
        status={offer?.status || ''}
        seriesMap={seriesMapData}
        offerSeries={offer?.series || ''}
        renderHeaderContent={<APRHeaderDetails apr={rateAmount} terms={termsFilter?.value || ''} subVen={offer?.subventionAmount?.toString() || ''} />}
        renderNav={<OfferTabsNav navList={navList} />}
      />
      <OfferTabBodyLayout
        renderContent={
          <>
            <div className={styles.form}>
              <InlineInputLabel label="Offer Type" vertical width={labelWidth}>
                <Input id="apr-fin-offer-type-input" value="APR" disabled />
              </InlineInputLabel>
              <InlineInputLabel label="Funding Source" vertical width={labelWidth}>
                <Input id="apr-fin-funding-source-input" darkTheme value={fundingSource} {...fundingSourceBindFn} error={fundingSourceError} />
              </InlineInputLabel>
              <InlineInputLabel label="Tiers" vertical width={labelWidth}>
                <Dropdown id="apr-tiers-series" placeholder="Select Tier" options={tiersOptions} value={tiersFilter} onChange={handleTiersChange} darkTheme />
              </InlineInputLabel>
              <InlineInputLabel label="Terms" vertical width={labelWidth}>
                <Dropdown
                  id="apr-terms-series"
                  placeholder="Select Term"
                  options={termsOptions}
                  value={termsFilter}
                  onChange={term => {
                    handleTermsChange(term);
                    handleReCalculation(rateAmount, term.value);
                  }}
                  darkTheme
                />
              </InlineInputLabel>
              <InlineInputLabel label="Rate" vertical width={labelWidth}>
                <Input
                  type="number"
                  id="apr-rate-input"
                  {...rateAmountBindFn}
                  value={rateAmount}
                  error={rateAmountError}
                  onChange={e => {
                    rateAmountBindFn.onChange(e);
                    handleReCalculation(e.currentTarget.value, termsFilter?.value || '');
                  }}
                  darkTheme
                />
              </InlineInputLabel>
              <InlineInputLabel label="$ Per Thousand" vertical width={labelWidth}>
                <Input
                  id="apr-per-thousand-input"
                  darkTheme
                  {...amountPerThouBindFn}
                  onChange={e => rateAmountBindFn.onChange(e)}
                  dollarSign
                  className={styles.dollarInput}
                  value={amountPerThou}
                  error={amountPerThouError}
                  disabled
                />
              </InlineInputLabel>
              <InlineInputLabel label="Extra Cash Amount" vertical width={labelWidth}>
                <Input type="number" id="apr-extra-cash-input" darkTheme {...ecAmountBindFn} dollarSign className={styles.dollarInput} value={ecAmount} error={ecAmountError} />
              </InlineInputLabel>
              <InlineInputLabel label="Subvention Amount" vertical width={labelWidth}>
                <Input type="number" id="apr-subvention-amt-input" darkTheme {...sAmountBindFn} dollarSign className={styles.dollarInput} value={sAmount} error={sAmountError} />
              </InlineInputLabel>
              <InlineInputLabel label="Extra Cash Label" vertical width={labelWidth}>
                <Input id="apr-extra-cash-input" darkTheme {...ecLabelBindFn} value={ecLabel} error={ecLabelError} />
              </InlineInputLabel>
            </div>
            {submitted && hasError && <OfferTabError />}
          </>
        }
        renderFooter={<FinancialTabFooter onSubmit={handleOnSubmit} disabled={promiseLoading} isNextDisabled={!offer?.isAdvertised} />}
      />
    </>
  );
};

export default APRFinancialForm;

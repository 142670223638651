import { NavLink } from 'react-router-dom';
import buildOfferUrl from '../../utils/buildOfferUrl';
import styles from './styles.module.scss';
import clx from 'clsx';

const OfferTabNavList = (offeringId: string, offerType: string, lang: string, offerId?: string, isOfferAdvertised = true, isCreate = false) => {
  const offerTypeParam = offerType.toLowerCase();

  const navList = [<NavLink to={buildOfferUrl(offeringId, offerTypeParam, 'info', lang, offerId)}>Info</NavLink>];

  if (!isCreate && offerType !== 'Other') {
    navList.push(<NavLink to={buildOfferUrl(offeringId, offerTypeParam, 'financial', lang, offerId)}>Financial</NavLink>);
  }

  if (!isCreate) {
    navList.push(
      <NavLink
        to={buildOfferUrl(offeringId, offerTypeParam, 'marketing', lang, offerId)}
        onClick={e => !isOfferAdvertised && e.preventDefault()}
        className={clx(!isOfferAdvertised && styles.isDisabled)}
      >
        Marketing
      </NavLink>,
    );
  }

  return navList;
};

export default OfferTabNavList;

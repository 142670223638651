import { OfferTypes } from 'oat-common-ui';
import React from 'react';
import FinancialBase from '../FinancialBase';
import APRFinancialTabComponent from './APRFinancialTabComponent';

const APRFinancialTab = () => {
  return <FinancialBase offerType={OfferTypes.APR} component={<APRFinancialTabComponent />} />;
};

export default APRFinancialTab;

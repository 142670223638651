import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  ButtonGroup,
  CustomDatePicker,
  dateStringToDate,
  ErrorMessages,
  IMultiItem,
  Input,
  InputContainer,
  IOfferingSetting,
  Modal,
  ModalBody,
  ModalError,
  ModalFooter,
  ModalHeader,
  tierData,
  TierTableDataType,
  usePromiseLoading,
} from 'oat-common-ui';
import React, { useEffect, useState } from 'react';
import { OfferingSettingsInput } from '../../../gql/generated';
import compareStartEndDates from '../../../utils/compareStartEndDates';
import validateOfferings from '../../../utils/validateOfferings';
import validateOfferingSettings from '../../../utils/validateOfferingSettings';
import EnhancedItem from './EnhancedItem';
import { rateItem, rcfItem } from './enhancedListItem';
import OfferingsSettingsType from './models/OfferingSettingsType';
import OfferingType from './models/OfferingType';
import styles from './styles.module.scss';
import formatEnhancedListHelper from './utils/formatEnhancedListHelper';
import getOfferingTitle from './utils/getOfferingTitle';
import getTomorrowsDate from './utils/getTomorrowsDate';
import processOfferingSettings from './utils/processOfferingSettings';
import processSettingsPayload from './utils/processSettingsPayload';

interface OfferingModalProps {
  openModal: boolean;
  copy?: boolean;
  offering?: any;
  offeringSettings?: IOfferingSetting[];
  seriesList?: { id: string; data: string; selected?: boolean; index?: number }[];
  onClose: () => void;
  onCreate: (offeringName: string, startDate: Date, endDate: Date, offeringSettings: OfferingSettingsInput[]) => Promise<void>;
  onEdit: (offeringName: string, startDate: Date, endDate: Date, offeringSettings: OfferingSettingsInput[]) => Promise<void>;
  onCopy: (offeringName: string, startDate: Date, endDate: Date) => Promise<void>;
  activeOfferings: OfferingType[];
  archivedOfferings: OfferingType[];
  states: string[];
}

const OfferingModal = ({
  openModal,
  copy,
  offering,
  onClose,
  onCreate,
  onEdit,
  onCopy,
  activeOfferings,
  archivedOfferings,
  seriesList,
  offeringSettings,
  states,
}: OfferingModalProps) => {
  const [offeringName, setOfferingName] = useState('');
  const [error, setError] = useState('');
  const [startDate, setStartDate] = useState(getTomorrowsDate());
  const [endDate, setEndDate] = useState(getTomorrowsDate());
  const [enhancedList, setEnhancedList] = useState<OfferingsSettingsType[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { promiseLoading, loadPromise } = usePromiseLoading();

  useEffect(() => {
    if (!isLoaded && offering) {
      setOfferingName(offering.name);
      setStartDate(dateStringToDate(offering.startDate));
      setEndDate(dateStringToDate(offering.endDate));
    }

    setIsLoaded(true);
  }, [offering, isLoaded]);

  useEffect(() => {
    if (offering && !isLoaded && seriesList && offeringSettings && states) {
      setEnhancedList(processOfferingSettings(offeringSettings, seriesList, states));
    }
  }, [offering, isLoaded, seriesList, offeringSettings, states]);

  const clearAll = () => {
    setOfferingName('');
    setStartDate(new Date());
    setEndDate(new Date());
    setError('');
    setEnhancedList([]);
    onClose();
  };

  const endDateChecker = (startDateParam: Date, endDateParam: Date) => {
    if (!compareStartEndDates(startDateParam, endDateParam)) {
      setError(ErrorMessages.OFFERING_END_DATE_INVALID);
      return;
    }

    setError('');
  };

  const freshTierData = tierData.map(termItem => ({
    term: termItem.term,
    tiers: termItem.tiers.map(tierItem => ({
      tier: tierItem.tier,
      rate: '',
    })),
  }));

  const removeEnhancedItem = (uid: string) => {
    const newEnhancedList = enhancedList.filter(item => item.uid !== uid);
    validateOfferingSettings(newEnhancedList); // apply/clear error stylings in series selector
    setEnhancedList(newEnhancedList);
  };

  const formatEnhancedList = (
    uid: string,
    tableData?: TierTableDataType,
    selectorData?: any,
    eStartDate?: Date,
    eEndDate?: Date,
    statesSelectorData?: { included: IMultiItem[]; excluded: IMultiItem[] },
  ) => {
    const newEnhancedList = formatEnhancedListHelper(uid, enhancedList, tableData, selectorData, eStartDate, eEndDate, statesSelectorData);
    const errorMessage = validateOfferingSettings(newEnhancedList); // apply/clear error stylings in series selector
    setError(errorMessage);
    setEnhancedList(newEnhancedList);
  };

  const handleAddRcf = () => {
    const rcaList = enhancedList.filter(item => item.type !== 'lease');
    const rcfList = enhancedList.filter(item => item.type === 'lease');

    rcfList.push({ ...rcfItem(freshTierData, states) });
    setEnhancedList(rcfList.concat(rcaList));
  };

  const handleAddEsr = () => {
    const enhancedListCopy = [...enhancedList];

    if (seriesList) {
      enhancedListCopy.push({ ...rateItem(startDate, endDate, seriesList, freshTierData, states) });
      setEnhancedList(enhancedListCopy);
    }
  };

  const handleOnSubmit = async () => {
    try {
      const offeringIdForValidation = copy ? '' : offering?.id;
      const errorSettings = validateOfferingSettings(enhancedList);
      const errorMessage = validateOfferings(activeOfferings, archivedOfferings, offeringName, startDate, endDate, enhancedList, offeringIdForValidation, copy);
      setSubmitted(true);

      if (errorSettings) {
        setError(errorSettings);
        return;
      }

      if (errorMessage) {
        setError(errorMessage);
        return;
      }

      if (copy) {
        await loadPromise(onCopy(offeringName, startDate, endDate));
        return;
      }

      if (offering) {
        await loadPromise(onEdit(offeringName, startDate, endDate, processSettingsPayload(enhancedList)));
        return;
      }

      await loadPromise(onCreate(offeringName, startDate, endDate, processSettingsPayload(enhancedList)));
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  return (
    <Modal isOpen={openModal} onClose={() => clearAll()} size="lg">
      <ModalHeader title={getOfferingTitle(copy || false, !!offering)} onClose={() => clearAll()} />
      <ModalBody className={styles.modalBody}>
        <div className={styles.modalBodyContainer1}>
          <InputContainer label="Offering Name:">
            <Input
              id="offering-name-input"
              error={!offeringName}
              value={offeringName}
              onChange={e => {
                setOfferingName(e.target.value);
                setError('');
              }}
            />
          </InputContainer>
          <InputContainer label="Start Date:">
            <CustomDatePicker
              id="start-date-picker"
              selected={startDate}
              onChange={date => {
                if (date) {
                  setStartDate(date);
                  endDateChecker(date, endDate);
                }
              }}
            />
          </InputContainer>
          <InputContainer label="End Date:">
            <CustomDatePicker
              id="end-date-picker"
              selected={endDate}
              onChange={date => {
                if (date) {
                  setEndDate(date);
                  endDateChecker(startDate, date);
                }
              }}
            />
          </InputContainer>
        </div>
        {!copy && (
          <div className={styles.modalBodyContainer2}>
            <ButtonGroup>
              <button id="add-enhanced-rate" className={styles.addText} onClick={() => handleAddEsr()} type="button">
                <FontAwesomeIcon icon={faPlusCircle} className={styles.addRateIcon} />
                <span className={styles.buttonText}>Add Enhanced Standard Rate</span>
              </button>
              <span>|</span>
              <button id="add-enhanced-rcf" className={styles.addText} onClick={() => handleAddRcf()} type="button">
                <FontAwesomeIcon icon={faPlusCircle} className={styles.addRateIcon} />
                <span className={styles.buttonText}>Add Enhanced Standard RCF</span>
              </button>
            </ButtonGroup>
          </div>
        )}
        <div className={styles.enhancedListContainer} id="enhanced-list" role="list">
          {enhancedList.map(item => {
            return (
              <EnhancedItem
                uid={item.uid}
                type={item.type}
                startDate={item.startDate ? dateStringToDate(item.startDate) : new Date()}
                endDate={item.endDate ? dateStringToDate(item.endDate) : new Date()}
                tierTable={item.tierTable}
                key={`eItem-${item.uid}`}
                removeEnhancedItem={removeEnhancedItem}
                seriesSelectorData={item.seriesSelectorData}
                formatEnhancedList={formatEnhancedList}
                statesData={item.statesSelectorData}
                role="listitem"
              />
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        {submitted && error && <ModalError errorMessage={error} />}
        <ButtonGroup>
          <Button onClick={() => clearAll()} disabled={promiseLoading} variant="primary">
            Cancel
          </Button>
          <Button onClick={handleOnSubmit} disabled={promiseLoading} variant="primary">
            Submit
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default OfferingModal;

import { IMultiItem, IOfferingSetting, uuidv4 } from 'oat-common-ui';
import OfferingsSettingsType from '../models/OfferingSettingsType';
import filterTierData from './filterTierData';

const processOfferingSettings = (offeringSettingsArg: IOfferingSetting[], seriesListArg: IMultiItem[], states: string[]) => {
  const aprOfferingSettings: OfferingsSettingsType[] = [];
  const leaseOfferingSettings: OfferingsSettingsType[] = [];

  const getOfferingStatesData = (offeringSetting: IOfferingSetting) => {
    const included: IMultiItem[] = [];
    const excluded: IMultiItem[] = [];

    states.forEach(item => {
      const offeringStates = offeringSetting?.includedStates || [];
      const itemToPush = {
        id: item,
        data: item,
        selected: false,
      };

      if (!offeringStates.find(state => item === state)) {
        excluded.push(itemToPush);
      } else {
        included.push(itemToPush);
      }
    });

    return {
      included,
      excluded,
    };
  };

  offeringSettingsArg.forEach(offeringSetting => {
    if (offeringSetting.type === 'apr') {
      const includedSeries: IMultiItem[] = [];
      const excludedSeries: IMultiItem[] = [];

      seriesListArg.forEach(listItem => {
        if (offeringSetting?.includedSeries?.find(series => series === listItem.id)) {
          includedSeries.push({ ...listItem });
        } else {
          excludedSeries.push({ ...listItem });
        }
      });

      aprOfferingSettings.push({
        uid: uuidv4(),
        id: offeringSetting.id,
        rev: offeringSetting.rev,
        type: offeringSetting.type,
        startDate: offeringSetting.startDate,
        endDate: offeringSetting.endDate,
        tierTable: filterTierData(offeringSetting.enhancements || [], false),
        seriesSelectorData: {
          excluded: excludedSeries,
          included: includedSeries,
        },
        statesSelectorData: getOfferingStatesData(offeringSetting),
      });
    }

    if (offeringSetting.type === 'lease') {
      leaseOfferingSettings.push({
        uid: uuidv4(),
        id: offeringSetting.id,
        rev: offeringSetting.rev,
        type: offeringSetting.type,
        tierTable: filterTierData(offeringSetting.enhancements || [], true),
        statesSelectorData: getOfferingStatesData(offeringSetting),
      });
    }
  });

  return leaseOfferingSettings.concat(aprOfferingSettings);
};

export default processOfferingSettings;

import { observer } from 'mobx-react-lite';
import { assignStringValue, formatMarketingPayload, OATWrapper, OfferMarketingDisclaimers, OfferTabReturnCta, OfferTypes, useToast } from 'oat-common-ui';
import React, { useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import MainFooter from '../../../components/MainFooter';
import MainHeader from '../../../components/MainHeader';
import OfferTabBreadCrumbs from '../../../components/OfferTabComponents/OfferTabBreadCrumbs';
import OfferTabNavList from '../../../components/OfferTabComponents/OfferTabNavList';
import { Offer, OfferMarketing, useSaveOfferMarketingMutation } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import buildOfferUrl from '../../../utils/buildOfferUrl';
import RenderHeaderDetails from '../RenderHeaderDetails';
import MarketingFormContainer from './MarketingForm/MarketingFormContainer';

interface Props {
  offerType: OfferTypes;
}

const MarketingTabComponentBase = ({ offerType }: Props) => {
  const { lang, offerId } = useUrlParams();
  const {
    efcStore,
    offeringsStore: { currentOffering },
    offersStore: { currentOffer, setCurrentOffer, updateOffer },
    offerMarketingStore,
    userInfoStore: {
      properties: { fullName },
      isLexus,
    },
    tokenStore,
    seriesManagerStore,
  } = useStores();
  const [redirect, setRedirect] = useState('');
  const [saveOfferMarketing] = useSaveOfferMarketingMutation();
  const { success, error: toastError } = useToast();
  const returnLink = `/offering/${currentOffering?.id}`;
  const history = useHistory();

  const uniqueSeries = seriesManagerStore.uniqueSeries.concat([{ code: 'MULTI', title: 'MULTI' }]);
  const seriesMap = efcStore.uniqueYearSeries.find(efc => efc.code === currentOffer?.series) ? efcStore.uniqueYearSeries : uniqueSeries;

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const handleOnSubmit = async (
    offerCardTitle: string,
    offerDetailTitle: string,
    disclaimers: OfferMarketingDisclaimers[],
    language: string,
    back: boolean,
    miscType?: string,
    offerDetailAltText?: string,
    offerCardAltText?: string,
    imageDisclaimer?: string,
    description?: string,
    id?: string,
    rev?: string,
  ) => {
    try {
      const dataToSubmit = {
        offerCardTitle,
        offerDetailTitle,
        disclaimers,
        language,
        miscType,
        offerDetailAltText,
        offerCardAltText,
        imageDisclaimer,
        description,
        id,
        rev,
      } as OfferMarketing;

      const res = await saveOfferMarketing({
        variables: {
          offerId: assignStringValue(offerId),
          marketingDetails: formatMarketingPayload(dataToSubmit, offerMarketingStore.currentOfferMarketing),
        },
      });

      const newMarketingData = res.data?.saveOfferMarketing.marketingDetails as OfferMarketing[];
      offerMarketingStore.setOfferMarketing(assignStringValue(offerId), newMarketingData);
      offerMarketingStore.setCurrentOfferMarketing(newMarketingData);
      const newOfferData = res.data?.saveOfferMarketing.offer as Offer;
      updateOffer(newOfferData.id, newOfferData);
      setCurrentOffer(newOfferData);

      if (back) {
        const tab = offerType !== 'Other' ? 'financial' : 'info';

        setRedirect(buildOfferUrl(currentOffering?.id, offerType, tab, lang, assignStringValue(offerId)));
      }
      success('Marketing saved successfully.');
    } catch (e) {
      toastError((e as Error).message);
    }
  };

  const handleSetLangParam = (langParam: string) => {
    history.push(buildOfferUrl(currentOffering?.id, offerType, 'marketing', langParam, assignStringValue(offerId)));
  };

  return (
    <>
      <MainHeader breadCrumbs={OfferTabBreadCrumbs(fullName, currentOffering, offerType)} />
      <OATWrapper withPadding>
        <Link to={returnLink} component={OfferTabReturnCta} />
        <MarketingFormContainer
          isLexus={isLexus()}
          offer={currentOffer}
          offerType={offerType}
          seriesMapData={seriesMap}
          headerContent={<RenderHeaderDetails offerType={offerType} />}
          navList={OfferTabNavList(currentOffering.id, offerType, lang, currentOffer?.id, currentOffer?.isAdvertised)}
          onSubmit={handleOnSubmit}
          marketingDetails={offerMarketingStore.currentOfferMarketing}
          defaultLanguage={lang}
          setLangParam={handleSetLangParam}
          disclaimers={tokenStore.disclaimers}
          snippets={tokenStore.snippets}
          tokens={tokenStore.tokens}
        />
      </OATWrapper>
      <MainFooter darkTheme />
    </>
  );
};

export default observer(MarketingTabComponentBase);

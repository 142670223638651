import { makeObservable, observable, runInAction } from 'mobx';
import { Disclaimer, Snippet } from '../gql/generated';

class TokenStore {
  isLoaded = false;
  tokens: string[] = [];
  snippets: Snippet[] = [];
  disclaimers: Disclaimer[] = [];

  constructor() {
    makeObservable(this, {
      snippets: observable,
      disclaimers: observable,
    });
  }

  setTokensSnippetsDisclaimers = (tokens: string[] = [], snippets: Snippet[] = [], disclaimers: Disclaimer[] = []) => {
    runInAction(() => {
      this.tokens = tokens;
      this.snippets = snippets;
      this.disclaimers = disclaimers;
      this.isLoaded = true;
    });
  };

  setSnippets = (snippets: Snippet[] = []) => {
    runInAction(() => {
      this.snippets = snippets;
    });
  };

  setDisclaimers = (disclaimers: Disclaimer[] = []) => {
    runInAction(() => {
      this.disclaimers = disclaimers;
    });
  };
}

export default TokenStore;

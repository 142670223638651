import { AdminConstants } from 'oat-admin-common';
import {
  Button,
  ButtonGroup,
  Checkbox,
  CustomDatePicker,
  DefaultMultiSelectPanes,
  Dropdown,
  DropdownItem,
  ErrorMessages,
  IMultiItem,
  IOffering,
  InlineInputLabel,
  LabelRow,
  MultiSelectPanes,
  OfferTabBodyLayout,
  OfferTabError,
  OfferTabsHeader,
  OfferTabsNav,
  OfferTypes,
  RadioInput,
  UniqueSeriesType,
  assignStringValue,
  dateFormatISOString,
  dateStringToDate,
  getExcludedIncludedList,
  getSeriesYearList,
  sortFieldsHelper,
  useDates,
  usePromiseLoading,
} from 'oat-common-ui';
import { ReactNode, useState } from 'react';
import OfferTabNavList from '../../../components/OfferTabComponents/OfferTabNavList';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import styles from './styles.module.scss';
import { multiSeriesOptions, sortStates } from './util';

interface Props {
  enableSeriesOptions: boolean;
  regionOptions: string[];
  yearsOptions?: string[];
  seriesOptions?: UniqueSeriesType[];
  statesOptions?: string[];
  statesWithRegions?: { [state: string]: string };
  offering: IOffering;
  headerContent: ReactNode;
  offerType?: string;
  onSubmit: (
    regions: string[],
    years: string[],
    series: string,
    startDate: string,
    endDate: string,
    next: boolean,
    isAdvertised: boolean,
    certificationType?: string[],
    includedStates?: string[],
    excludedStates?: string[],
  ) => Promise<void>;
}

const getSelectedSeries = (series: string, seriesOptions: { value: string; label: string }[] = []) => {
  const found = seriesOptions.find(item => item.value === series);
  return found || { value: '', label: 'Select Series' };
};

const InfoTabForm = ({
  regionOptions,
  seriesOptions,
  yearsOptions,
  statesOptions,
  statesWithRegions,
  enableSeriesOptions,
  headerContent,
  offering,
  offerType,
  onSubmit,
}: Props) => {
  const { offeringId, offerId, lang } = useUrlParams();

  const {
    efcStore,
    offersStore: { currentOffer },
    userInfoStore,
  } = useStores();

  const [startDate, endDate, setStartDate, setEndDate, dateError] = useDates(currentOffer?.startDate || offering.startDate, currentOffer?.endDate || offering.endDate, {
    minDate: offering.startDate,
    maxDate: offering.endDate,
  });

  const seriesList = seriesOptions?.map(item => ({ value: item.code, label: item.title })) || [];
  seriesList.sort(sortFieldsHelper('label', true));
  const isMulti = currentOffer?.series === 'MULTI';
  const seriesMap = efcStore.uniqueYearSeries.find(efc => efc.code === currentOffer?.series) ? efcStore.uniqueYearSeries : seriesOptions;

  const [selectedRegions, setSelectedRegions] = useState<string[]>(currentOffer?.regionCodes || []);
  const [selectedMultiYears, setSelectedMultiYears] = useState<string[]>(isMulti && currentOffer?.seriesYears ? currentOffer?.seriesYears : []);
  const [selectedYears, setSelectedYears] = useState<string[]>(!isMulti && currentOffer?.seriesYears ? currentOffer?.seriesYears : []);
  const [selectedSeries, setSelectedSeries] = useState(getSelectedSeries(assignStringValue(currentOffer?.series), seriesList));
  const [showMultiSection, setShowMultiSection] = useState(isMulti);
  const [submitted, setSubmitted] = useState(false);
  const { promiseLoading, loadPromise } = usePromiseLoading();
  const [isAdvertised, setIsAdvertised] = useState(offerId ? Boolean(currentOffer?.isAdvertised) : true); // if there is no OfferId then is createOffer and isAdvertised is true by default
  const [certificationType, setCertificationType] = useState<string[] | undefined>(!userInfoStore.isLexus() ? currentOffer?.certificationType || [] : undefined);

  const [regionsExList, regionsInList] = getExcludedIncludedList(
    regionOptions.map((item, index) => ({ id: item, data: item, selected: false, index })),
    currentOffer?.regionCodes || [],
  );

  const [stateExList, stateInList] = getExcludedIncludedList(
    (statesOptions || []).map((item, index) => ({ id: item, data: item, selected: false, index })),
    currentOffer?.includedStates || [],
  );
  const [includedStateList, setIncludedStateList] = useState(stateInList);
  const [excludedStateList, setExcludedStateList] = useState(stateExList);
  const initialSeriesYears = userInfoStore.isLexus() ? 8 : 10;
  const [seriesYearsExList, seriesYearsInList] = getExcludedIncludedList(
    (yearsOptions || getSeriesYearList(initialSeriesYears)).map((item, index) => ({ id: item, data: item, selected: false, index })),
    currentOffer?.seriesYears || [],
  );

  const [multiExList, multiInList] = getExcludedIncludedList(seriesOptions ? multiSeriesOptions(seriesOptions, initialSeriesYears) : [], currentOffer?.seriesYears || []);

  const hasError =
    !!dateError || !startDate || !endDate || (showMultiSection ? !selectedMultiYears.length : !selectedYears.length) || !selectedRegions.length || !selectedSeries.value;

  const handleOnStatesChange = (excList: IMultiItem[], incList: IMultiItem[]) => {
    setExcludedStateList(excList);
    setIncludedStateList(incList);
  };

  const handleOnRegionsChange = (includedRegions: IMultiItem[], chosenRegions: IMultiItem[], toExcluded: boolean) => {
    let sourceStates = toExcluded ? [...includedStateList] : [...excludedStateList];
    let targetStates = toExcluded ? [...excludedStateList] : [...includedStateList];

    if (chosenRegions && statesWithRegions) {
      const filteredList = sourceStates.filter(state => {
        let hasSelected = false;
        let hasSource = false;

        // With each state from sourceStates, get an array of region codes associated with that state using statesWithRegions
        statesWithRegions[state.id].split(',').forEach(regionCode => {
          // Use that array to check against the regions that have been selected
          chosenRegions.forEach(region => {
            if (region.id === regionCode) {
              hasSelected = true;
            }
          });

          // Use that array to check against the regions that have not been selected and are still included
          if (toExcluded) {
            includedRegions.forEach(region => {
              if (region.id === regionCode) {
                hasSource = true;
              }
            });
          }
        });

        return hasSelected && !hasSource;
      });

      targetStates = targetStates.concat(filteredList);
      sourceStates = sourceStates.filter(state => filteredList.findIndex(excState => excState.id === state.id) === -1);
    }

    setExcludedStateList(sortStates(toExcluded ? targetStates : sourceStates));
    setIncludedStateList(sortStates(toExcluded ? sourceStates : targetStates));
  };

  const handleOnRegionChange = (included: IMultiItem[]) => {
    setSelectedRegions(included.map(item => item.id));
  };

  const handleOnMultiYearsChange = (included: IMultiItem[]) => {
    setSelectedMultiYears(included.map(item => item.id));
  };

  const handleOnYearsChange = (included: IMultiItem[]) => {
    setSelectedYears(included.map(item => item.id));
  };

  const handleOnSubmit = async (next = false) => {
    setSubmitted(true);
    if (!hasError) {
      await loadPromise(
        onSubmit(
          selectedRegions,
          showMultiSection ? selectedMultiYears : selectedYears,
          selectedSeries.value,
          dateFormatISOString(startDate),
          dateFormatISOString(endDate),
          next,
          isAdvertised,
          certificationType,
          includedStateList.map(item => item.id),
          excludedStateList.map(item => item.id),
        ),
      );
    }
  };

  const handleSelectedSeriesOnChange = (series: DropdownItem) => {
    if (typeof series.label === 'string') {
      setSelectedSeries({ label: series.label, value: series.value });
    }
    if (series.value === 'MULTI') {
      return setShowMultiSection(true);
    }

    setShowMultiSection(false);
    return null;
  };

  const handleCertificationTypeOnChange = (certType: string) => {
    if (!userInfoStore.isLexus() && certificationType) {
      const certIndex = certificationType.indexOf(certType);

      if (certIndex !== -1) {
        let types = [...certificationType];
        types.splice(certIndex, 1);
        setCertificationType(types);
      } else {
        setCertificationType([...certificationType, certType]);
      }
    }
  };

  const errorMessage = dateError?.dateOrderError ? ErrorMessages.DATE_ORDER_INVALID : ErrorMessages.OFFER_TABS_REQUIRED_FIELDS;

  return (
    <>
      <OfferTabsHeader
        endDate={endDate ? dateFormatISOString(endDate) : ''}
        status={assignStringValue(currentOffer?.status)}
        seriesMap={seriesMap || []}
        offerType={assignStringValue(offerType)}
        offerSeries={assignStringValue(currentOffer?.series)}
        renderHeaderContent={headerContent}
        renderNav={<OfferTabsNav navList={OfferTabNavList(offeringId, assignStringValue(offerType), lang, assignStringValue(offerId), currentOffer?.isAdvertised, !offerId)} />}
      />
      <OfferTabBodyLayout
        renderContent={
          <>
            <div className={styles.advertisedContainer}>
              <InlineInputLabel width={200} label="Advertised" contentClass={styles.advertisedContent}>
                <div className={styles.optionsContainer}>
                  <span className={styles.radioBtnText}>Yes</span>
                  <RadioInput
                    id="advertised-input-yes"
                    checked={isAdvertised}
                    onChange={() => {
                      setIsAdvertised(true);
                    }}
                  />
                </div>
                <div className={styles.optionsContainer}>
                  <span className={styles.radioBtnText}>No</span>
                  <RadioInput
                    id="advertised-input-no"
                    checked={!isAdvertised}
                    onChange={() => {
                      setIsAdvertised(false);
                    }}
                  />
                </div>
              </InlineInputLabel>
            </div>
            {/* Certification Type */}
            {!userInfoStore.isLexus() && (
              <div className={styles.advertisedContainer}>
                <InlineInputLabel width={200} label="Certification Level" contentClass={styles.advertisedContent}>
                  <div className={styles.optionsContainer}>
                    <span className={styles.radioBtnText}>Gold</span>
                    <Checkbox
                      id="certification-input-gold"
                      isChecked={certificationType?.includes(AdminConstants.CERTIFICATION_TYPE.GOLD)}
                      onChange={() => {
                        handleCertificationTypeOnChange(AdminConstants.CERTIFICATION_TYPE.GOLD);
                      }}
                      isNational
                    />
                  </div>
                  <div className={styles.optionsContainer}>
                    <span className={styles.radioBtnText}>Silver</span>
                    <Checkbox
                      id="certification-input-silver"
                      isChecked={certificationType?.includes(AdminConstants.CERTIFICATION_TYPE.SILVER)}
                      onChange={() => {
                        handleCertificationTypeOnChange(AdminConstants.CERTIFICATION_TYPE.SILVER);
                      }}
                      isNational
                    />
                  </div>
                </InlineInputLabel>
              </div>
            )}
            {/* Dates */}
            <div className={styles.datesContainer}>
              <InlineInputLabel width={200} label="Start Date">
                <CustomDatePicker
                  id="info-start-date"
                  selected={startDate}
                  onChange={setStartDate}
                  darkTheme
                  minDate={dateStringToDate(offering.startDate)}
                  maxDate={dateStringToDate(offering.endDate)}
                  error={!!dateError || !startDate}
                />
              </InlineInputLabel>
              <InlineInputLabel width={200} label="End Date">
                <CustomDatePicker
                  id="info-end-date"
                  selected={endDate}
                  onChange={setEndDate}
                  darkTheme
                  minDate={dateStringToDate(offering.startDate)}
                  maxDate={dateStringToDate(offering.endDate)}
                  error={!!dateError || !endDate}
                />
              </InlineInputLabel>
            </div>
            {/* Series Selector */}
            <div className={styles.seriesSelector}>
              <InlineInputLabel width={200} label="Series Name">
                <Dropdown
                  id="info-series-dd"
                  value={selectedSeries}
                  options={seriesList}
                  onChange={handleSelectedSeriesOnChange}
                  darkTheme
                  disabled={!enableSeriesOptions}
                  error={!selectedSeries.value}
                />
              </InlineInputLabel>
            </div>
            {/* Series Manager Selector */}
            <LabelRow label="Series Manager" />
            <div className={styles.selectorPanels}>
              {showMultiSection && (
                <DefaultMultiSelectPanes
                  excludedList={multiExList}
                  includedList={multiInList}
                  error={!selectedMultiYears.length}
                  returnSelectorData={(_ex, inc) => {
                    handleOnMultiYearsChange(inc);
                  }}
                />
              )}
              {!showMultiSection && (
                <DefaultMultiSelectPanes
                  excludedList={seriesYearsExList}
                  includedList={seriesYearsInList}
                  error={!selectedYears.length}
                  returnSelectorData={(_ex, inc) => {
                    handleOnYearsChange(inc);
                  }}
                />
              )}
            </div>
            {/* Regions Selector */}
            <LabelRow label="Region" />
            <div className={styles.selectorPanels}>
              <DefaultMultiSelectPanes
                excludedList={regionsExList}
                includedList={regionsInList}
                error={!selectedRegions.length}
                onMove={(_exc, inc, selList, toExcluded) => {
                  handleOnRegionsChange(inc, selList, toExcluded);
                }}
                returnSelectorData={(_ex, inc) => {
                  handleOnRegionChange(inc);
                }}
              />
            </div>
            {/* State Restriction Selector */}
            {(offerType === OfferTypes.LEASE || offerType === OfferTypes.APR) && (
              <>
                <LabelRow label="State Restrictions" />
                <div className={styles.selectorPanels}>
                  <MultiSelectPanes excludedList={excludedStateList} includedList={includedStateList} error={false} onMove={handleOnStatesChange} onSelect={handleOnStatesChange} />
                </div>
              </>
            )}
            {submitted && hasError && <OfferTabError message={errorMessage} />}
          </>
        }
        renderFooter={
          <ButtonGroup>
            <Button id="info-submit-btn" variant="primary" disabled={promiseLoading} onClick={() => handleOnSubmit()}>
              Save
            </Button>
            <Button id="info-submit-btn" variant="primary" disabled={promiseLoading} onClick={() => handleOnSubmit(true)}>
              Next
            </Button>
          </ButtonGroup>
        }
      />
    </>
  );
};

export default InfoTabForm;

import { AdminConstants } from 'oat-admin-common';
import { SeriesCodeToNameMap, SeriesV1Response } from 'oat-common-ui';

const { BRAND_TOYOTA } = AdminConstants;

const processEfcData = (efcData: SeriesV1Response, brand: string): SeriesV1Response => {
  const series_data = efcData.series_data.map(item => {
    return {
      ...item,
      series_list: item.series_list.map(ser => {
        const title = SeriesCodeToNameMap[ser.code] || ser.title;
        const code = brand === BRAND_TOYOTA ? title.split(' ').join('').toLowerCase() : ser.code;
        return {
          ...ser,
          code,
          title,
        };
      }),
    };
  });

  return {
    ...efcData,
    series_data,
  };
};

export default processEfcData;

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type CopyOfferInput = {
  id: Scalars['ID'];
};

export type CopyOfferResult = {
  __typename?: 'CopyOfferResult';
  offer: Offer;
  success: Scalars['Boolean'];
};

export type CopyOfferingInput = {
  endDate: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  rev: Scalars['ID'];
  startDate: Scalars['String'];
};

export type CopyOfferingResult = {
  __typename?: 'CopyOfferingResult';
  offering: Offering;
  success: Scalars['Boolean'];
};

export type CreateDisclaimerInput = {
  brand: Scalars['String'];
  name: Scalars['String'];
  text: Scalars['String'];
};

export type CreateDisclaimerResult = {
  __typename?: 'CreateDisclaimerResult';
  disclaimer: Disclaimer;
  success: Scalars['Boolean'];
};

export type CreateOfferInput = {
  certificationType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endDate: Scalars['String'];
  excludedStates?: InputMaybe<Array<Scalars['String']>>;
  includedStates?: InputMaybe<Array<Scalars['String']>>;
  isAdvertised: Scalars['Boolean'];
  offerType: Scalars['String'];
  offeringId: Scalars['String'];
  regionCodes: Array<Scalars['String']>;
  series: Scalars['String'];
  seriesYears: Array<Scalars['String']>;
  startDate: Scalars['String'];
};

export type CreateOfferResult = {
  __typename?: 'CreateOfferResult';
  offer: Offer;
  success: Scalars['Boolean'];
};

export type CreateOfferingInput = {
  brand: Scalars['String'];
  endDate: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
};

export type CreateOfferingResult = {
  __typename?: 'CreateOfferingResult';
  offering: Offering;
  success: Scalars['Boolean'];
};

export type CreateSnippetInput = {
  brand: Scalars['String'];
  name: Scalars['String'];
  text: Scalars['String'];
};

export type CreateSnippetResult = {
  __typename?: 'CreateSnippetResult';
  snippet: Snippet;
  success: Scalars['Boolean'];
};

export type DecorateEntityInput = {
  entityType: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type DecorateEntityResult = {
  __typename?: 'DecorateEntityResult';
  success: Scalars['Boolean'];
};

export type DeleteDisclaimerInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};

export type DeleteDisclaimerResult = {
  __typename?: 'DeleteDisclaimerResult';
  success: Scalars['Boolean'];
};

export type DeleteOfferInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};

export type DeleteOfferResult = {
  __typename?: 'DeleteOfferResult';
  success: Scalars['Boolean'];
};

export type DeleteOfferingInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};

export type DeleteOfferingResult = {
  __typename?: 'DeleteOfferingResult';
  success: Scalars['Boolean'];
};

export type DeleteSnippetInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};

export type DeleteSnippetResult = {
  __typename?: 'DeleteSnippetResult';
  success: Scalars['Boolean'];
};

export type Disclaimer = {
  __typename?: 'Disclaimer';
  brand: Scalars['String'];
  created: Scalars['String'];
  createdBy: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  rev: Scalars['String'];
  text: Scalars['String'];
  updated: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type EnhancedRateRcf = {
  __typename?: 'EnhancedRateRcf';
  rate?: Maybe<Scalars['Float']>;
  rcf?: Maybe<Scalars['Float']>;
  term: Scalars['Int'];
  tier: Scalars['String'];
};

export type EnhancedRateRcfInput = {
  rate?: InputMaybe<Scalars['Float']>;
  rcf?: InputMaybe<Scalars['Float']>;
  term: Scalars['Int'];
  tier: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  copyOffer: CopyOfferResult;
  copyOffering: CopyOfferingResult;
  createDisclaimer: CreateDisclaimerResult;
  createOffer: CreateOfferResult;
  createOffering: CreateOfferingResult;
  createSnippet: CreateSnippetResult;
  decorateEntity: DecorateEntityResult;
  deleteDisclaimer: DeleteDisclaimerResult;
  deleteOffer: DeleteOfferResult;
  deleteOffering: DeleteOfferingResult;
  deleteSnippet: DeleteSnippetResult;
  previewOffering: PreviewOfferingResult;
  publishOffering: PublishOfferingResult;
  saveOfferMarketing: SaveOfferMarketingResult;
  saveOfferingSettings: SaveOfferingSettingsResult;
  saveSeries: SaveSeriesResult;
  updateDisclaimer: UpdateDisclaimerResult;
  updateOffer: UpdateOfferResult;
  updateOfferStatus: UpdateOfferStatusResult;
  updateOffering: UpdateOfferingResult;
  updateOfferingStatus: UpdateOfferingStatusResult;
  updateSnippet: UpdateSnippetResult;
};


export type MutationCopyOfferArgs = {
  input: CopyOfferInput;
};


export type MutationCopyOfferingArgs = {
  input: CopyOfferingInput;
};


export type MutationCreateDisclaimerArgs = {
  input: CreateDisclaimerInput;
};


export type MutationCreateOfferArgs = {
  input: CreateOfferInput;
};


export type MutationCreateOfferingArgs = {
  input: CreateOfferingInput;
};


export type MutationCreateSnippetArgs = {
  input: CreateSnippetInput;
};


export type MutationDecorateEntityArgs = {
  input: DecorateEntityInput;
};


export type MutationDeleteDisclaimerArgs = {
  input: DeleteDisclaimerInput;
};


export type MutationDeleteOfferArgs = {
  input: DeleteOfferInput;
};


export type MutationDeleteOfferingArgs = {
  input: DeleteOfferingInput;
};


export type MutationDeleteSnippetArgs = {
  input: DeleteSnippetInput;
};


export type MutationPreviewOfferingArgs = {
  input: PreviewOfferingInput;
};


export type MutationPublishOfferingArgs = {
  input: PublishOfferingInput;
};


export type MutationSaveOfferMarketingArgs = {
  input: SaveOfferMarketingInput;
};


export type MutationSaveOfferingSettingsArgs = {
  input: SaveOfferingSettingsInput;
};


export type MutationSaveSeriesArgs = {
  input: SaveSeriesInput;
};


export type MutationUpdateDisclaimerArgs = {
  input: UpdateDisclaimerInput;
};


export type MutationUpdateOfferArgs = {
  input: UpdateOfferInput;
};


export type MutationUpdateOfferStatusArgs = {
  input: UpdateOfferStatusInput;
};


export type MutationUpdateOfferingArgs = {
  input: UpdateOfferingInput;
};


export type MutationUpdateOfferingStatusArgs = {
  input: UpdateOfferingStatusInput;
};


export type MutationUpdateSnippetArgs = {
  input: UpdateSnippetInput;
};

export type Offer = {
  __typename?: 'Offer';
  cashOfferAmount?: Maybe<Scalars['Int']>;
  certificationType?: Maybe<Array<Scalars['String']>>;
  created: Scalars['String'];
  createdBy: Scalars['String'];
  dollarPerThousand?: Maybe<Scalars['Float']>;
  endDate: Scalars['String'];
  excludedStates?: Maybe<Array<Scalars['String']>>;
  extraCashAmount?: Maybe<Scalars['Int']>;
  extraCashLabel?: Maybe<Scalars['String']>;
  fundingSource?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  includedStates?: Maybe<Array<Scalars['String']>>;
  isAdvertised: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  miscType?: Maybe<Scalars['String']>;
  offerType: Scalars['String'];
  offeringId: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  rates?: Maybe<Array<OfferRate>>;
  rcf?: Maybe<Scalars['Float']>;
  regionCodes: Array<Scalars['String']>;
  rev: Scalars['String'];
  series: Scalars['String'];
  seriesYears: Array<Scalars['String']>;
  startDate: Scalars['String'];
  status: Scalars['String'];
  subventionAmount?: Maybe<Scalars['Int']>;
  term?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
  updated: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type OfferMarketing = {
  __typename?: 'OfferMarketing';
  description?: Maybe<Scalars['String']>;
  disclaimers: Array<OfferMarketingDisclaimers>;
  id: Scalars['ID'];
  imageDisclaimer?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  miscType?: Maybe<Scalars['String']>;
  offerCardAltText?: Maybe<Scalars['String']>;
  offerCardTitle?: Maybe<Scalars['String']>;
  offerDetailAltText?: Maybe<Scalars['String']>;
  offerDetailTitle?: Maybe<Scalars['String']>;
  rev: Scalars['ID'];
};

export type OfferMarketingDisclaimers = {
  __typename?: 'OfferMarketingDisclaimers';
  sortOrder: Scalars['Int'];
  tcuvDisclaimerFileId?: Maybe<Scalars['String']>;
  tcuvDisclaimerFileName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type OfferMarketingDisclaimersInput = {
  sortOrder: Scalars['Int'];
  tcuvDisclaimerFileId?: InputMaybe<Scalars['ID']>;
  tcuvDisclaimerFileName?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type OfferMarketingInput = {
  description?: InputMaybe<Scalars['String']>;
  disclaimers: Array<OfferMarketingDisclaimersInput>;
  id?: InputMaybe<Scalars['ID']>;
  imageDisclaimer?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  miscType?: InputMaybe<Scalars['String']>;
  offerCardAltText?: InputMaybe<Scalars['String']>;
  offerCardTitle?: InputMaybe<Scalars['String']>;
  offerDetailAltText?: InputMaybe<Scalars['String']>;
  offerDetailTitle?: InputMaybe<Scalars['String']>;
  rev?: InputMaybe<Scalars['ID']>;
};

export type OfferRate = {
  __typename?: 'OfferRate';
  dollarPerThousand?: Maybe<Scalars['Float']>;
  isAdvertised?: Maybe<Scalars['Boolean']>;
  rate?: Maybe<Scalars['Int']>;
  rcf?: Maybe<Scalars['Float']>;
  subventionCash?: Maybe<Scalars['Int']>;
  term?: Maybe<Scalars['String']>;
  tier: Scalars['String'];
};

export type OfferStatus = {
  __typename?: 'OfferStatus';
  error?: Maybe<Scalars['String']>;
  offerId: Scalars['ID'];
  rev: Scalars['ID'];
  status: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Offering = {
  __typename?: 'Offering';
  brand: Scalars['String'];
  created: Scalars['String'];
  createdBy: Scalars['String'];
  endDate: Scalars['String'];
  id: Scalars['String'];
  isDirty: Scalars['Boolean'];
  isDownstream: Scalars['String'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  rev: Scalars['String'];
  startDate: Scalars['String'];
  status: Scalars['String'];
  updated: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type OfferingSettings = {
  __typename?: 'OfferingSettings';
  endDate?: Maybe<Scalars['String']>;
  enhancements: Array<EnhancedRateRcf>;
  excludedStates?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  includedSeries?: Maybe<Array<Scalars['String']>>;
  includedStates?: Maybe<Array<Scalars['String']>>;
  rev: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type OfferingSettingsInput = {
  endDate?: InputMaybe<Scalars['String']>;
  enhancements: Array<EnhancedRateRcfInput>;
  excludedStates?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['ID']>;
  includedSeries?: InputMaybe<Array<Scalars['String']>>;
  includedStates?: InputMaybe<Array<Scalars['String']>>;
  rev?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type PreviewOfferingInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};

export type PreviewOfferingResult = {
  __typename?: 'PreviewOfferingResult';
  offering: Offering;
  success: Scalars['Boolean'];
};

export type PublishOfferingInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};

export type PublishOfferingResult = {
  __typename?: 'PublishOfferingResult';
  offering: Offering;
  success: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  disclaimer: Disclaimer;
  disclaimersByBrand: Array<Maybe<Disclaimer>>;
  miscTypes: Array<Scalars['String']>;
  offer: Offer;
  offerMarketingByOfferId: Array<OfferMarketing>;
  offering: Offering;
  offeringSettingsByOfferingId: Array<Maybe<OfferingSettings>>;
  offeringsByBrandActive: Array<Maybe<Offering>>;
  offersByOfferingId: Array<Maybe<Offer>>;
  region?: Maybe<Region>;
  regions?: Maybe<Array<Maybe<Region>>>;
  seriesByBrand: Series;
  snippet: Snippet;
  snippetsByBrand: Array<Maybe<Snippet>>;
  tokens: Array<Scalars['String']>;
  userInfo?: Maybe<User>;
};


export type QueryDisclaimerArgs = {
  id: Scalars['String'];
};


export type QueryDisclaimersByBrandArgs = {
  brand: Scalars['String'];
};


export type QueryOfferArgs = {
  id: Scalars['String'];
};


export type QueryOfferMarketingByOfferIdArgs = {
  offerId: Scalars['String'];
};


export type QueryOfferingArgs = {
  id: Scalars['String'];
};


export type QueryOfferingSettingsByOfferingIdArgs = {
  offeringId: Scalars['String'];
};


export type QueryOfferingsByBrandActiveArgs = {
  active: Scalars['Boolean'];
  brand: Scalars['String'];
};


export type QueryOffersByOfferingIdArgs = {
  offeringId: Scalars['String'];
};


export type QueryRegionArgs = {
  code: Scalars['String'];
};


export type QueryRegionsArgs = {
  brand: Scalars['String'];
};


export type QuerySeriesByBrandArgs = {
  brand: Scalars['String'];
};


export type QuerySnippetArgs = {
  id: Scalars['String'];
};


export type QuerySnippetsByBrandArgs = {
  brand: Scalars['String'];
};

export type RateInput = {
  dollarPerThousand?: InputMaybe<Scalars['Float']>;
  isAdvertised?: InputMaybe<Scalars['Boolean']>;
  rate?: InputMaybe<Scalars['Float']>;
  rcf?: InputMaybe<Scalars['Float']>;
  subventionCash?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
  tier: Scalars['String'];
};

export type Region = {
  __typename?: 'Region';
  brand: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  oatLabel: Scalars['String'];
  statesList: Scalars['String'];
};

export type SaveOfferMarketingInput = {
  marketingDetails: Array<InputMaybe<OfferMarketingInput>>;
  offerId: Scalars['ID'];
};

export type SaveOfferMarketingResult = {
  __typename?: 'SaveOfferMarketingResult';
  marketingDetails: Array<Maybe<OfferMarketing>>;
  offer: Offer;
  success: Scalars['Boolean'];
};

export type SaveOfferingSettingsInput = {
  offeringId: Scalars['ID'];
  settings: Array<InputMaybe<OfferingSettingsInput>>;
};

export type SaveOfferingSettingsResult = {
  __typename?: 'SaveOfferingSettingsResult';
  offeringId: Scalars['ID'];
  offeringRev: Scalars['ID'];
  settings: Array<Maybe<OfferingSettings>>;
  success: Scalars['Boolean'];
};

export type SaveSeriesInput = {
  brand: Scalars['String'];
  series: Array<InputMaybe<Scalars['String']>>;
};

export type SaveSeriesResult = {
  __typename?: 'SaveSeriesResult';
  brand: Scalars['String'];
  series: Array<Maybe<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type Series = {
  __typename?: 'Series';
  brand: Scalars['String'];
  created: Scalars['String'];
  createdBy: Scalars['String'];
  id: Scalars['String'];
  series: Array<Maybe<Scalars['String']>>;
  updated: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type Snippet = {
  __typename?: 'Snippet';
  brand: Scalars['String'];
  created: Scalars['String'];
  createdBy: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  rev: Scalars['String'];
  text: Scalars['String'];
  updated: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type UpdateDisclaimerInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  rev: Scalars['ID'];
  text: Scalars['String'];
};

export type UpdateDisclaimerResult = {
  __typename?: 'UpdateDisclaimerResult';
  disclaimer: Disclaimer;
  success: Scalars['Boolean'];
};

export type UpdateOfferInput = {
  cashOfferAmount?: InputMaybe<Scalars['Int']>;
  certificationType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dollarPerThousand?: InputMaybe<Scalars['Float']>;
  endDate?: InputMaybe<Scalars['String']>;
  excludedStates?: InputMaybe<Array<Scalars['String']>>;
  extraCashAmount?: InputMaybe<Scalars['Int']>;
  extraCashLabel?: InputMaybe<Scalars['String']>;
  fundingSource?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  includedStates?: InputMaybe<Array<Scalars['String']>>;
  isAdvertised: Scalars['Boolean'];
  miscType?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Float']>;
  rates?: InputMaybe<Array<InputMaybe<RateInput>>>;
  rcf?: InputMaybe<Scalars['Float']>;
  regionCodes?: InputMaybe<Array<Scalars['String']>>;
  rev: Scalars['ID'];
  series?: InputMaybe<Scalars['String']>;
  seriesYears?: InputMaybe<Array<Scalars['String']>>;
  startDate?: InputMaybe<Scalars['String']>;
  subventionAmount?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
  tier?: InputMaybe<Scalars['String']>;
};

export type UpdateOfferResult = {
  __typename?: 'UpdateOfferResult';
  offer: Offer;
  success: Scalars['Boolean'];
};

export type UpdateOfferStatusInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
  status: Scalars['String'];
};

export type UpdateOfferStatusResult = {
  __typename?: 'UpdateOfferStatusResult';
  offer: Offer;
  success: Scalars['Boolean'];
};

export type UpdateOfferingInput = {
  endDate: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  rev: Scalars['ID'];
  startDate: Scalars['String'];
};

export type UpdateOfferingResult = {
  __typename?: 'UpdateOfferingResult';
  offering: Offering;
  success: Scalars['Boolean'];
};

export type UpdateOfferingStatusInput = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
  status: Scalars['String'];
};

export type UpdateOfferingStatusResult = {
  __typename?: 'UpdateOfferingStatusResult';
  offeringId: Scalars['ID'];
  offers: Array<OfferStatus>;
  rev: Scalars['ID'];
  status: Scalars['String'];
  success: Scalars['Boolean'];
};

export type UpdateSnippetInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  rev: Scalars['ID'];
  text: Scalars['String'];
};

export type UpdateSnippetResult = {
  __typename?: 'UpdateSnippetResult';
  snippet: Snippet;
  success: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  azureGroups: Scalars['String'];
  brand: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  objectId: Scalars['String'];
  regionCodes: Scalars['String'];
  roles: Scalars['String'];
  tdaCodes: Scalars['String'];
  userTypes: Scalars['String'];
};

export type GetMiscTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMiscTypesQuery = { __typename?: 'Query', miscTypes: Array<string> };

export type CopyOfferMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CopyOfferMutation = { __typename?: 'Mutation', copyOffer: { __typename?: 'CopyOfferResult', success: boolean, offer: { __typename?: 'Offer', id: string, rev: string, offeringId: string, parentId?: string | null, offerType: string, startDate: string, endDate: string, series: string, seriesYears: Array<string>, regionCodes: Array<string>, fundingSource?: string | null, tier?: string | null, term?: string | null, rate?: number | null, rcf?: number | null, dollarPerThousand?: number | null, extraCashAmount?: number | null, extraCashLabel?: string | null, subventionAmount?: number | null, cashOfferAmount?: number | null, miscType?: string | null, status: string, isPublished: boolean, created: string, createdBy: string, updated: string, updatedBy: string, includedStates?: Array<string> | null, excludedStates?: Array<string> | null, isAdvertised: boolean, certificationType?: Array<string> | null } } };

export type CreateOfferMutationVariables = Exact<{
  offeringId: Scalars['String'];
  offerType: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  series: Scalars['String'];
  seriesYears: Array<Scalars['String']> | Scalars['String'];
  regionCodes: Array<Scalars['String']> | Scalars['String'];
  includedStates?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  excludedStates?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  isAdvertised: Scalars['Boolean'];
  certificationType?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type CreateOfferMutation = { __typename?: 'Mutation', createOffer: { __typename?: 'CreateOfferResult', success: boolean, offer: { __typename?: 'Offer', id: string, rev: string, offeringId: string, parentId?: string | null, offerType: string, startDate: string, endDate: string, series: string, seriesYears: Array<string>, regionCodes: Array<string>, fundingSource?: string | null, tier?: string | null, term?: string | null, rate?: number | null, rcf?: number | null, dollarPerThousand?: number | null, extraCashAmount?: number | null, extraCashLabel?: string | null, subventionAmount?: number | null, cashOfferAmount?: number | null, miscType?: string | null, status: string, isPublished: boolean, created: string, createdBy: string, updated: string, updatedBy: string, includedStates?: Array<string> | null, excludedStates?: Array<string> | null, isAdvertised: boolean, certificationType?: Array<string> | null } } };

export type DeleteOfferMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
}>;


export type DeleteOfferMutation = { __typename?: 'Mutation', deleteOffer: { __typename?: 'DeleteOfferResult', success: boolean } };

export type OfferFieldsFragment = { __typename?: 'Offer', id: string, rev: string, offeringId: string, parentId?: string | null, offerType: string, startDate: string, endDate: string, series: string, seriesYears: Array<string>, regionCodes: Array<string>, fundingSource?: string | null, tier?: string | null, term?: string | null, rate?: number | null, rcf?: number | null, dollarPerThousand?: number | null, extraCashAmount?: number | null, extraCashLabel?: string | null, subventionAmount?: number | null, cashOfferAmount?: number | null, miscType?: string | null, status: string, isPublished: boolean, created: string, createdBy: string, updated: string, updatedBy: string, includedStates?: Array<string> | null, excludedStates?: Array<string> | null, isAdvertised: boolean, certificationType?: Array<string> | null };

export type MarketingFieldsFragment = { __typename?: 'OfferMarketing', description?: string | null, id: string, rev: string, imageDisclaimer?: string | null, language: string, miscType?: string | null, offerCardAltText?: string | null, offerCardTitle?: string | null, offerDetailAltText?: string | null, offerDetailTitle?: string | null, disclaimers: Array<{ __typename?: 'OfferMarketingDisclaimers', sortOrder: number, tcuvDisclaimerFileId?: string | null, tcuvDisclaimerFileName?: string | null, text?: string | null }> };

export type GetOfferByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetOfferByIdQuery = { __typename?: 'Query', offer: { __typename?: 'Offer', id: string, rev: string, offeringId: string, parentId?: string | null, offerType: string, startDate: string, endDate: string, series: string, seriesYears: Array<string>, regionCodes: Array<string>, fundingSource?: string | null, tier?: string | null, term?: string | null, rate?: number | null, rcf?: number | null, dollarPerThousand?: number | null, extraCashAmount?: number | null, extraCashLabel?: string | null, subventionAmount?: number | null, cashOfferAmount?: number | null, miscType?: string | null, status: string, isPublished: boolean, created: string, createdBy: string, updated: string, updatedBy: string, includedStates?: Array<string> | null, excludedStates?: Array<string> | null, isAdvertised: boolean, certificationType?: Array<string> | null } };

export type GetOfferMarketingByOfferIdQueryVariables = Exact<{
  offerId: Scalars['String'];
}>;


export type GetOfferMarketingByOfferIdQuery = { __typename?: 'Query', offerMarketingByOfferId: Array<{ __typename?: 'OfferMarketing', description?: string | null, id: string, rev: string, imageDisclaimer?: string | null, language: string, miscType?: string | null, offerCardAltText?: string | null, offerCardTitle?: string | null, offerDetailAltText?: string | null, offerDetailTitle?: string | null, disclaimers: Array<{ __typename?: 'OfferMarketingDisclaimers', sortOrder: number, tcuvDisclaimerFileId?: string | null, tcuvDisclaimerFileName?: string | null, text?: string | null }> }> };

export type GetOffersByOfferingIdQueryVariables = Exact<{
  offeringId: Scalars['String'];
}>;


export type GetOffersByOfferingIdQuery = { __typename?: 'Query', offersByOfferingId: Array<{ __typename?: 'Offer', id: string, rev: string, offeringId: string, parentId?: string | null, offerType: string, startDate: string, endDate: string, series: string, seriesYears: Array<string>, regionCodes: Array<string>, fundingSource?: string | null, tier?: string | null, term?: string | null, rate?: number | null, rcf?: number | null, dollarPerThousand?: number | null, extraCashAmount?: number | null, extraCashLabel?: string | null, subventionAmount?: number | null, cashOfferAmount?: number | null, miscType?: string | null, status: string, isPublished: boolean, created: string, createdBy: string, updated: string, updatedBy: string, includedStates?: Array<string> | null, excludedStates?: Array<string> | null, isAdvertised: boolean, certificationType?: Array<string> | null } | null> };

export type SaveOfferMarketingMutationVariables = Exact<{
  offerId: Scalars['ID'];
  marketingDetails: Array<InputMaybe<OfferMarketingInput>> | InputMaybe<OfferMarketingInput>;
}>;


export type SaveOfferMarketingMutation = { __typename?: 'Mutation', saveOfferMarketing: { __typename?: 'SaveOfferMarketingResult', success: boolean, offer: { __typename?: 'Offer', id: string, rev: string, offeringId: string, parentId?: string | null, offerType: string, startDate: string, endDate: string, series: string, seriesYears: Array<string>, regionCodes: Array<string>, fundingSource?: string | null, tier?: string | null, term?: string | null, rate?: number | null, rcf?: number | null, dollarPerThousand?: number | null, extraCashAmount?: number | null, extraCashLabel?: string | null, subventionAmount?: number | null, cashOfferAmount?: number | null, miscType?: string | null, status: string, isPublished: boolean, created: string, createdBy: string, updated: string, updatedBy: string, includedStates?: Array<string> | null, excludedStates?: Array<string> | null, isAdvertised: boolean, certificationType?: Array<string> | null }, marketingDetails: Array<{ __typename?: 'OfferMarketing', description?: string | null, id: string, rev: string, imageDisclaimer?: string | null, language: string, miscType?: string | null, offerCardAltText?: string | null, offerCardTitle?: string | null, offerDetailAltText?: string | null, offerDetailTitle?: string | null, disclaimers: Array<{ __typename?: 'OfferMarketingDisclaimers', sortOrder: number, tcuvDisclaimerFileId?: string | null, tcuvDisclaimerFileName?: string | null, text?: string | null }> } | null> } };

export type UpdateOfferMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  series?: InputMaybe<Scalars['String']>;
  seriesYears?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  regionCodes?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  fundingSource?: InputMaybe<Scalars['String']>;
  tier?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Float']>;
  rcf?: InputMaybe<Scalars['Float']>;
  dollarPerThousand?: InputMaybe<Scalars['Float']>;
  extraCashAmount?: InputMaybe<Scalars['Int']>;
  extraCashLabel?: InputMaybe<Scalars['String']>;
  subventionAmount?: InputMaybe<Scalars['Int']>;
  cashOfferAmount?: InputMaybe<Scalars['Int']>;
  miscType?: InputMaybe<Scalars['String']>;
  includedStates?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  excludedStates?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  isAdvertised: Scalars['Boolean'];
  certificationType?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type UpdateOfferMutation = { __typename?: 'Mutation', updateOffer: { __typename?: 'UpdateOfferResult', success: boolean, offer: { __typename?: 'Offer', id: string, rev: string, offeringId: string, parentId?: string | null, offerType: string, startDate: string, endDate: string, series: string, seriesYears: Array<string>, regionCodes: Array<string>, fundingSource?: string | null, tier?: string | null, term?: string | null, rate?: number | null, rcf?: number | null, dollarPerThousand?: number | null, extraCashAmount?: number | null, extraCashLabel?: string | null, subventionAmount?: number | null, cashOfferAmount?: number | null, miscType?: string | null, status: string, isPublished: boolean, created: string, createdBy: string, updated: string, updatedBy: string, includedStates?: Array<string> | null, excludedStates?: Array<string> | null, isAdvertised: boolean, certificationType?: Array<string> | null } } };

export type UpdateOfferStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
  status: Scalars['String'];
}>;


export type UpdateOfferStatusMutation = { __typename?: 'Mutation', updateOfferStatus: { __typename?: 'UpdateOfferStatusResult', success: boolean, offer: { __typename?: 'Offer', id: string, rev: string, offeringId: string, parentId?: string | null, offerType: string, startDate: string, endDate: string, series: string, seriesYears: Array<string>, regionCodes: Array<string>, fundingSource?: string | null, tier?: string | null, term?: string | null, rate?: number | null, rcf?: number | null, dollarPerThousand?: number | null, extraCashAmount?: number | null, extraCashLabel?: string | null, subventionAmount?: number | null, cashOfferAmount?: number | null, miscType?: string | null, status: string, isPublished: boolean, created: string, createdBy: string, updated: string, updatedBy: string, includedStates?: Array<string> | null, excludedStates?: Array<string> | null, isAdvertised: boolean, certificationType?: Array<string> | null } } };

export type CopyOfferingMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type CopyOfferingMutation = { __typename?: 'Mutation', copyOffering: { __typename?: 'CopyOfferingResult', success: boolean, offering: { __typename?: 'Offering', id: string, rev: string, name: string, brand: string, startDate: string, endDate: string, status: string, created: string, createdBy: string, updated: string, updatedBy: string, isDirty: boolean } } };

export type CreateOfferingMutationVariables = Exact<{
  name: Scalars['String'];
  brand: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type CreateOfferingMutation = { __typename?: 'Mutation', createOffering: { __typename?: 'CreateOfferingResult', success: boolean, offering: { __typename?: 'Offering', id: string, rev: string, name: string, brand: string, startDate: string, endDate: string, status: string, created: string, createdBy: string, updated: string, updatedBy: string, isDirty: boolean } } };

export type DeleteOfferingMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
}>;


export type DeleteOfferingMutation = { __typename?: 'Mutation', deleteOffering: { __typename?: 'DeleteOfferingResult', success: boolean } };

export type OfferingFieldsFragment = { __typename?: 'Offering', id: string, rev: string, name: string, brand: string, startDate: string, endDate: string, status: string, created: string, createdBy: string, updated: string, updatedBy: string, isDirty: boolean };

export type OfferingSettingsFieldsFragment = { __typename?: 'OfferingSettings', id: string, rev: string, type: string, startDate?: string | null, endDate?: string | null, includedSeries?: Array<string> | null, includedStates?: Array<string> | null, excludedStates?: Array<string> | null, enhancements: Array<{ __typename?: 'EnhancedRateRcf', tier: string, term: number, rate?: number | null, rcf?: number | null }> };

export type GetOfferingByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetOfferingByIdQuery = { __typename?: 'Query', offering: { __typename?: 'Offering', id: string, rev: string, name: string, brand: string, startDate: string, endDate: string, status: string, created: string, createdBy: string, updated: string, updatedBy: string, isDirty: boolean } };

export type GetOfferingSettingsByOfferingIdQueryVariables = Exact<{
  offeringId: Scalars['String'];
}>;


export type GetOfferingSettingsByOfferingIdQuery = { __typename?: 'Query', offeringSettingsByOfferingId: Array<{ __typename?: 'OfferingSettings', id: string, rev: string, type: string, startDate?: string | null, endDate?: string | null, includedSeries?: Array<string> | null, includedStates?: Array<string> | null, excludedStates?: Array<string> | null, enhancements: Array<{ __typename?: 'EnhancedRateRcf', tier: string, term: number, rate?: number | null, rcf?: number | null }> } | null> };

export type GetOfferingWithOfferQueryVariables = Exact<{
  offeringId: Scalars['String'];
  offerId: Scalars['String'];
}>;


export type GetOfferingWithOfferQuery = { __typename?: 'Query', offering: { __typename?: 'Offering', id: string, rev: string, name: string, brand: string, startDate: string, endDate: string, status: string, created: string, createdBy: string, updated: string, updatedBy: string, isDirty: boolean }, offer: { __typename?: 'Offer', id: string, rev: string, offeringId: string, parentId?: string | null, offerType: string, startDate: string, endDate: string, series: string, seriesYears: Array<string>, regionCodes: Array<string>, fundingSource?: string | null, tier?: string | null, term?: string | null, rate?: number | null, rcf?: number | null, dollarPerThousand?: number | null, extraCashAmount?: number | null, extraCashLabel?: string | null, subventionAmount?: number | null, cashOfferAmount?: number | null, miscType?: string | null, status: string, isPublished: boolean, created: string, createdBy: string, updated: string, updatedBy: string, includedStates?: Array<string> | null, excludedStates?: Array<string> | null, isAdvertised: boolean, certificationType?: Array<string> | null } };

export type GetOfferingWithOffersQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetOfferingWithOffersQuery = { __typename?: 'Query', offering: { __typename?: 'Offering', id: string, rev: string, name: string, brand: string, startDate: string, endDate: string, status: string, created: string, createdBy: string, updated: string, updatedBy: string, isDirty: boolean }, offers: Array<{ __typename?: 'Offer', id: string, rev: string, offeringId: string, parentId?: string | null, offerType: string, startDate: string, endDate: string, series: string, seriesYears: Array<string>, regionCodes: Array<string>, fundingSource?: string | null, tier?: string | null, term?: string | null, rate?: number | null, rcf?: number | null, dollarPerThousand?: number | null, extraCashAmount?: number | null, extraCashLabel?: string | null, subventionAmount?: number | null, cashOfferAmount?: number | null, miscType?: string | null, status: string, isPublished: boolean, created: string, createdBy: string, updated: string, updatedBy: string, includedStates?: Array<string> | null, excludedStates?: Array<string> | null, isAdvertised: boolean, certificationType?: Array<string> | null } | null> };

export type GetOfferingsForDashboardQueryVariables = Exact<{
  brand: Scalars['String'];
}>;


export type GetOfferingsForDashboardQuery = { __typename?: 'Query', active: Array<{ __typename?: 'Offering', id: string, rev: string, name: string, brand: string, startDate: string, endDate: string, status: string, created: string, createdBy: string, updated: string, updatedBy: string, isDirty: boolean } | null>, archived: Array<{ __typename?: 'Offering', id: string, rev: string, name: string, brand: string, startDate: string, endDate: string, status: string, created: string, createdBy: string, updated: string, updatedBy: string, isDirty: boolean } | null> };

export type PreviewOfferingMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
}>;


export type PreviewOfferingMutation = { __typename?: 'Mutation', previewOffering: { __typename?: 'PreviewOfferingResult', success: boolean } };

export type PublishOfferingMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
}>;


export type PublishOfferingMutation = { __typename?: 'Mutation', publishOffering: { __typename?: 'PublishOfferingResult', success: boolean, offering: { __typename?: 'Offering', id: string, rev: string, status: string } } };

export type SaveOfferingSettingsMutationVariables = Exact<{
  offeringId: Scalars['ID'];
  settings: Array<InputMaybe<OfferingSettingsInput>> | InputMaybe<OfferingSettingsInput>;
}>;


export type SaveOfferingSettingsMutation = { __typename?: 'Mutation', saveOfferingSettings: { __typename?: 'SaveOfferingSettingsResult', success: boolean, offeringId: string, offeringRev: string, settings: Array<{ __typename?: 'OfferingSettings', id: string, rev: string, type: string, startDate?: string | null, endDate?: string | null, includedSeries?: Array<string> | null, includedStates?: Array<string> | null, excludedStates?: Array<string> | null, enhancements: Array<{ __typename?: 'EnhancedRateRcf', tier: string, term: number, rate?: number | null, rcf?: number | null }> } | null> } };

export type UpdateOfferingMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type UpdateOfferingMutation = { __typename?: 'Mutation', updateOffering: { __typename?: 'UpdateOfferingResult', success: boolean, offering: { __typename?: 'Offering', id: string, rev: string, name: string, brand: string, startDate: string, endDate: string, status: string, created: string, createdBy: string, updated: string, updatedBy: string, isDirty: boolean } } };

export type UpdateOfferingStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
  status: Scalars['String'];
}>;


export type UpdateOfferingStatusMutation = { __typename?: 'Mutation', updateOfferingStatus: { __typename?: 'UpdateOfferingStatusResult', success: boolean, offeringId: string, rev: string, status: string, offers: Array<{ __typename?: 'OfferStatus', offerId: string, rev: string, success: boolean, status: string, error?: string | null }> } };

export type GetSeriesManagerQueryVariables = Exact<{
  brand: Scalars['String'];
}>;


export type GetSeriesManagerQuery = { __typename?: 'Query', seriesByBrand: { __typename?: 'Series', brand: string, series: Array<string | null> } };

export type SaveSeriesManagerMutationVariables = Exact<{
  brand: Scalars['String'];
  series: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type SaveSeriesManagerMutation = { __typename?: 'Mutation', saveSeries: { __typename?: 'SaveSeriesResult', success: boolean, brand: string, series: Array<string | null> } };

export type CreateDisclaimerMutationVariables = Exact<{
  brand: Scalars['String'];
  name: Scalars['String'];
  text: Scalars['String'];
}>;


export type CreateDisclaimerMutation = { __typename?: 'Mutation', createDisclaimer: { __typename?: 'CreateDisclaimerResult', success: boolean, disclaimer: { __typename?: 'Disclaimer', id: string, rev: string, brand: string, name: string, text: string } } };

export type CreateSnippetMutationVariables = Exact<{
  brand: Scalars['String'];
  name: Scalars['String'];
  text: Scalars['String'];
}>;


export type CreateSnippetMutation = { __typename?: 'Mutation', createSnippet: { __typename?: 'CreateSnippetResult', success: boolean, snippet: { __typename?: 'Snippet', id: string, rev: string, brand: string, name: string, text: string } } };

export type DeleteDisclaimerMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
}>;


export type DeleteDisclaimerMutation = { __typename?: 'Mutation', deleteDisclaimer: { __typename?: 'DeleteDisclaimerResult', success: boolean } };

export type DeleteSnippetMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
}>;


export type DeleteSnippetMutation = { __typename?: 'Mutation', deleteSnippet: { __typename?: 'DeleteSnippetResult', success: boolean } };

export type GetTokensSnippetsDisclaimersQueryVariables = Exact<{
  brand: Scalars['String'];
}>;


export type GetTokensSnippetsDisclaimersQuery = { __typename?: 'Query', tokens: Array<string>, snippets: Array<{ __typename?: 'Snippet', id: string, rev: string, brand: string, name: string, text: string } | null>, disclaimers: Array<{ __typename?: 'Disclaimer', id: string, rev: string, brand: string, name: string, text: string } | null> };

export type UpdateDisclaimerMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
  name: Scalars['String'];
  text: Scalars['String'];
}>;


export type UpdateDisclaimerMutation = { __typename?: 'Mutation', updateDisclaimer: { __typename?: 'UpdateDisclaimerResult', success: boolean, disclaimer: { __typename?: 'Disclaimer', id: string, rev: string, brand: string, name: string, text: string } } };

export type UpdateSnippetMutationVariables = Exact<{
  id: Scalars['ID'];
  rev: Scalars['ID'];
  name: Scalars['String'];
  text: Scalars['String'];
}>;


export type UpdateSnippetMutation = { __typename?: 'Mutation', updateSnippet: { __typename?: 'UpdateSnippetResult', success: boolean, snippet: { __typename?: 'Snippet', id: string, rev: string, brand: string, name: string, text: string } } };

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserInfoQuery = { __typename?: 'Query', userInfo?: { __typename?: 'User', objectId: string, name: string, email: string, roles: string, brand: string, userTypes: string, regionCodes: string, tdaCodes: string, azureGroups: string } | null };

export const OfferFieldsFragmentDoc = gql`
    fragment OfferFields on Offer {
  id
  rev
  offeringId
  parentId
  offerType
  startDate
  endDate
  series
  seriesYears
  regionCodes
  fundingSource
  tier
  term
  rate
  rcf
  dollarPerThousand
  extraCashAmount
  extraCashLabel
  subventionAmount
  cashOfferAmount
  miscType
  status
  isPublished
  created
  createdBy
  updated
  updatedBy
  includedStates
  excludedStates
  isPublished
  isAdvertised
  certificationType
}
    `;
export const MarketingFieldsFragmentDoc = gql`
    fragment MarketingFields on OfferMarketing {
  description
  disclaimers {
    sortOrder
    tcuvDisclaimerFileId
    tcuvDisclaimerFileName
    text
  }
  id
  rev
  imageDisclaimer
  language
  miscType
  offerCardAltText
  offerCardTitle
  offerDetailAltText
  offerDetailTitle
}
    `;
export const OfferingFieldsFragmentDoc = gql`
    fragment OfferingFields on Offering {
  id
  rev
  name
  brand
  startDate
  endDate
  status
  created
  createdBy
  updated
  updatedBy
  isDirty
}
    `;
export const OfferingSettingsFieldsFragmentDoc = gql`
    fragment OfferingSettingsFields on OfferingSettings {
  id
  rev
  type
  startDate
  endDate
  includedSeries
  includedStates
  excludedStates
  enhancements {
    tier
    term
    rate
    rcf
  }
}
    `;
export const GetMiscTypesDocument = gql`
    query getMiscTypes {
  miscTypes
}
    `;

/**
 * __useGetMiscTypesQuery__
 *
 * To run a query within a React component, call `useGetMiscTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMiscTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMiscTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMiscTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetMiscTypesQuery, GetMiscTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMiscTypesQuery, GetMiscTypesQueryVariables>(GetMiscTypesDocument, options);
      }
export function useGetMiscTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMiscTypesQuery, GetMiscTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMiscTypesQuery, GetMiscTypesQueryVariables>(GetMiscTypesDocument, options);
        }
export type GetMiscTypesQueryHookResult = ReturnType<typeof useGetMiscTypesQuery>;
export type GetMiscTypesLazyQueryHookResult = ReturnType<typeof useGetMiscTypesLazyQuery>;
export type GetMiscTypesQueryResult = Apollo.QueryResult<GetMiscTypesQuery, GetMiscTypesQueryVariables>;
export const CopyOfferDocument = gql`
    mutation copyOffer($id: ID!) {
  copyOffer(input: {id: $id}) {
    success
    offer {
      ...OfferFields
    }
  }
}
    ${OfferFieldsFragmentDoc}`;
export type CopyOfferMutationFn = Apollo.MutationFunction<CopyOfferMutation, CopyOfferMutationVariables>;

/**
 * __useCopyOfferMutation__
 *
 * To run a mutation, you first call `useCopyOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyOfferMutation, { data, loading, error }] = useCopyOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCopyOfferMutation(baseOptions?: Apollo.MutationHookOptions<CopyOfferMutation, CopyOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyOfferMutation, CopyOfferMutationVariables>(CopyOfferDocument, options);
      }
export type CopyOfferMutationHookResult = ReturnType<typeof useCopyOfferMutation>;
export type CopyOfferMutationResult = Apollo.MutationResult<CopyOfferMutation>;
export type CopyOfferMutationOptions = Apollo.BaseMutationOptions<CopyOfferMutation, CopyOfferMutationVariables>;
export const CreateOfferDocument = gql`
    mutation createOffer($offeringId: String!, $offerType: String!, $startDate: String!, $endDate: String!, $series: String!, $seriesYears: [String!]!, $regionCodes: [String!]!, $includedStates: [String!], $excludedStates: [String!], $isAdvertised: Boolean!, $certificationType: [String]) {
  createOffer(
    input: {offeringId: $offeringId, offerType: $offerType, startDate: $startDate, endDate: $endDate, series: $series, seriesYears: $seriesYears, regionCodes: $regionCodes, includedStates: $includedStates, excludedStates: $excludedStates, isAdvertised: $isAdvertised, certificationType: $certificationType}
  ) {
    success
    offer {
      ...OfferFields
    }
  }
}
    ${OfferFieldsFragmentDoc}`;
export type CreateOfferMutationFn = Apollo.MutationFunction<CreateOfferMutation, CreateOfferMutationVariables>;

/**
 * __useCreateOfferMutation__
 *
 * To run a mutation, you first call `useCreateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfferMutation, { data, loading, error }] = useCreateOfferMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      offerType: // value for 'offerType'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      series: // value for 'series'
 *      seriesYears: // value for 'seriesYears'
 *      regionCodes: // value for 'regionCodes'
 *      includedStates: // value for 'includedStates'
 *      excludedStates: // value for 'excludedStates'
 *      isAdvertised: // value for 'isAdvertised'
 *      certificationType: // value for 'certificationType'
 *   },
 * });
 */
export function useCreateOfferMutation(baseOptions?: Apollo.MutationHookOptions<CreateOfferMutation, CreateOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOfferMutation, CreateOfferMutationVariables>(CreateOfferDocument, options);
      }
export type CreateOfferMutationHookResult = ReturnType<typeof useCreateOfferMutation>;
export type CreateOfferMutationResult = Apollo.MutationResult<CreateOfferMutation>;
export type CreateOfferMutationOptions = Apollo.BaseMutationOptions<CreateOfferMutation, CreateOfferMutationVariables>;
export const DeleteOfferDocument = gql`
    mutation deleteOffer($id: ID!, $rev: ID!) {
  deleteOffer(input: {id: $id, rev: $rev}) {
    success
  }
}
    `;
export type DeleteOfferMutationFn = Apollo.MutationFunction<DeleteOfferMutation, DeleteOfferMutationVariables>;

/**
 * __useDeleteOfferMutation__
 *
 * To run a mutation, you first call `useDeleteOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferMutation, { data, loading, error }] = useDeleteOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *   },
 * });
 */
export function useDeleteOfferMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOfferMutation, DeleteOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOfferMutation, DeleteOfferMutationVariables>(DeleteOfferDocument, options);
      }
export type DeleteOfferMutationHookResult = ReturnType<typeof useDeleteOfferMutation>;
export type DeleteOfferMutationResult = Apollo.MutationResult<DeleteOfferMutation>;
export type DeleteOfferMutationOptions = Apollo.BaseMutationOptions<DeleteOfferMutation, DeleteOfferMutationVariables>;
export const GetOfferByIdDocument = gql`
    query getOfferById($id: String!) {
  offer(id: $id) {
    ...OfferFields
  }
}
    ${OfferFieldsFragmentDoc}`;

/**
 * __useGetOfferByIdQuery__
 *
 * To run a query within a React component, call `useGetOfferByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfferByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOfferByIdQuery, GetOfferByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOfferByIdQuery, GetOfferByIdQueryVariables>(GetOfferByIdDocument, options);
      }
export function useGetOfferByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOfferByIdQuery, GetOfferByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOfferByIdQuery, GetOfferByIdQueryVariables>(GetOfferByIdDocument, options);
        }
export type GetOfferByIdQueryHookResult = ReturnType<typeof useGetOfferByIdQuery>;
export type GetOfferByIdLazyQueryHookResult = ReturnType<typeof useGetOfferByIdLazyQuery>;
export type GetOfferByIdQueryResult = Apollo.QueryResult<GetOfferByIdQuery, GetOfferByIdQueryVariables>;
export const GetOfferMarketingByOfferIdDocument = gql`
    query getOfferMarketingByOfferId($offerId: String!) {
  offerMarketingByOfferId(offerId: $offerId) {
    ...MarketingFields
  }
}
    ${MarketingFieldsFragmentDoc}`;

/**
 * __useGetOfferMarketingByOfferIdQuery__
 *
 * To run a query within a React component, call `useGetOfferMarketingByOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferMarketingByOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferMarketingByOfferIdQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useGetOfferMarketingByOfferIdQuery(baseOptions: Apollo.QueryHookOptions<GetOfferMarketingByOfferIdQuery, GetOfferMarketingByOfferIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOfferMarketingByOfferIdQuery, GetOfferMarketingByOfferIdQueryVariables>(GetOfferMarketingByOfferIdDocument, options);
      }
export function useGetOfferMarketingByOfferIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOfferMarketingByOfferIdQuery, GetOfferMarketingByOfferIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOfferMarketingByOfferIdQuery, GetOfferMarketingByOfferIdQueryVariables>(GetOfferMarketingByOfferIdDocument, options);
        }
export type GetOfferMarketingByOfferIdQueryHookResult = ReturnType<typeof useGetOfferMarketingByOfferIdQuery>;
export type GetOfferMarketingByOfferIdLazyQueryHookResult = ReturnType<typeof useGetOfferMarketingByOfferIdLazyQuery>;
export type GetOfferMarketingByOfferIdQueryResult = Apollo.QueryResult<GetOfferMarketingByOfferIdQuery, GetOfferMarketingByOfferIdQueryVariables>;
export const GetOffersByOfferingIdDocument = gql`
    query getOffersByOfferingId($offeringId: String!) {
  offersByOfferingId(offeringId: $offeringId) {
    ...OfferFields
  }
}
    ${OfferFieldsFragmentDoc}`;

/**
 * __useGetOffersByOfferingIdQuery__
 *
 * To run a query within a React component, call `useGetOffersByOfferingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOffersByOfferingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOffersByOfferingIdQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useGetOffersByOfferingIdQuery(baseOptions: Apollo.QueryHookOptions<GetOffersByOfferingIdQuery, GetOffersByOfferingIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOffersByOfferingIdQuery, GetOffersByOfferingIdQueryVariables>(GetOffersByOfferingIdDocument, options);
      }
export function useGetOffersByOfferingIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOffersByOfferingIdQuery, GetOffersByOfferingIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOffersByOfferingIdQuery, GetOffersByOfferingIdQueryVariables>(GetOffersByOfferingIdDocument, options);
        }
export type GetOffersByOfferingIdQueryHookResult = ReturnType<typeof useGetOffersByOfferingIdQuery>;
export type GetOffersByOfferingIdLazyQueryHookResult = ReturnType<typeof useGetOffersByOfferingIdLazyQuery>;
export type GetOffersByOfferingIdQueryResult = Apollo.QueryResult<GetOffersByOfferingIdQuery, GetOffersByOfferingIdQueryVariables>;
export const SaveOfferMarketingDocument = gql`
    mutation saveOfferMarketing($offerId: ID!, $marketingDetails: [OfferMarketingInput]!) {
  saveOfferMarketing(
    input: {offerId: $offerId, marketingDetails: $marketingDetails}
  ) {
    success
    offer {
      ...OfferFields
    }
    marketingDetails {
      ...MarketingFields
    }
  }
}
    ${OfferFieldsFragmentDoc}
${MarketingFieldsFragmentDoc}`;
export type SaveOfferMarketingMutationFn = Apollo.MutationFunction<SaveOfferMarketingMutation, SaveOfferMarketingMutationVariables>;

/**
 * __useSaveOfferMarketingMutation__
 *
 * To run a mutation, you first call `useSaveOfferMarketingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOfferMarketingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOfferMarketingMutation, { data, loading, error }] = useSaveOfferMarketingMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      marketingDetails: // value for 'marketingDetails'
 *   },
 * });
 */
export function useSaveOfferMarketingMutation(baseOptions?: Apollo.MutationHookOptions<SaveOfferMarketingMutation, SaveOfferMarketingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveOfferMarketingMutation, SaveOfferMarketingMutationVariables>(SaveOfferMarketingDocument, options);
      }
export type SaveOfferMarketingMutationHookResult = ReturnType<typeof useSaveOfferMarketingMutation>;
export type SaveOfferMarketingMutationResult = Apollo.MutationResult<SaveOfferMarketingMutation>;
export type SaveOfferMarketingMutationOptions = Apollo.BaseMutationOptions<SaveOfferMarketingMutation, SaveOfferMarketingMutationVariables>;
export const UpdateOfferDocument = gql`
    mutation updateOffer($id: ID!, $rev: ID!, $startDate: String, $endDate: String, $series: String, $seriesYears: [String!], $regionCodes: [String!], $fundingSource: String, $tier: String, $term: String, $rate: Float, $rcf: Float, $dollarPerThousand: Float, $extraCashAmount: Int, $extraCashLabel: String, $subventionAmount: Int, $cashOfferAmount: Int, $miscType: String, $includedStates: [String!], $excludedStates: [String!], $isAdvertised: Boolean!, $certificationType: [String]) {
  updateOffer(
    input: {id: $id, rev: $rev, startDate: $startDate, endDate: $endDate, series: $series, seriesYears: $seriesYears, regionCodes: $regionCodes, fundingSource: $fundingSource, tier: $tier, term: $term, rate: $rate, rcf: $rcf, dollarPerThousand: $dollarPerThousand, extraCashAmount: $extraCashAmount, extraCashLabel: $extraCashLabel, subventionAmount: $subventionAmount, cashOfferAmount: $cashOfferAmount, miscType: $miscType, includedStates: $includedStates, excludedStates: $excludedStates, isAdvertised: $isAdvertised, certificationType: $certificationType}
  ) {
    success
    offer {
      ...OfferFields
    }
  }
}
    ${OfferFieldsFragmentDoc}`;
export type UpdateOfferMutationFn = Apollo.MutationFunction<UpdateOfferMutation, UpdateOfferMutationVariables>;

/**
 * __useUpdateOfferMutation__
 *
 * To run a mutation, you first call `useUpdateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferMutation, { data, loading, error }] = useUpdateOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      series: // value for 'series'
 *      seriesYears: // value for 'seriesYears'
 *      regionCodes: // value for 'regionCodes'
 *      fundingSource: // value for 'fundingSource'
 *      tier: // value for 'tier'
 *      term: // value for 'term'
 *      rate: // value for 'rate'
 *      rcf: // value for 'rcf'
 *      dollarPerThousand: // value for 'dollarPerThousand'
 *      extraCashAmount: // value for 'extraCashAmount'
 *      extraCashLabel: // value for 'extraCashLabel'
 *      subventionAmount: // value for 'subventionAmount'
 *      cashOfferAmount: // value for 'cashOfferAmount'
 *      miscType: // value for 'miscType'
 *      includedStates: // value for 'includedStates'
 *      excludedStates: // value for 'excludedStates'
 *      isAdvertised: // value for 'isAdvertised'
 *      certificationType: // value for 'certificationType'
 *   },
 * });
 */
export function useUpdateOfferMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOfferMutation, UpdateOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOfferMutation, UpdateOfferMutationVariables>(UpdateOfferDocument, options);
      }
export type UpdateOfferMutationHookResult = ReturnType<typeof useUpdateOfferMutation>;
export type UpdateOfferMutationResult = Apollo.MutationResult<UpdateOfferMutation>;
export type UpdateOfferMutationOptions = Apollo.BaseMutationOptions<UpdateOfferMutation, UpdateOfferMutationVariables>;
export const UpdateOfferStatusDocument = gql`
    mutation updateOfferStatus($id: ID!, $rev: ID!, $status: String!) {
  updateOfferStatus(input: {id: $id, rev: $rev, status: $status}) {
    success
    offer {
      ...OfferFields
    }
  }
}
    ${OfferFieldsFragmentDoc}`;
export type UpdateOfferStatusMutationFn = Apollo.MutationFunction<UpdateOfferStatusMutation, UpdateOfferStatusMutationVariables>;

/**
 * __useUpdateOfferStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOfferStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferStatusMutation, { data, loading, error }] = useUpdateOfferStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateOfferStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOfferStatusMutation, UpdateOfferStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOfferStatusMutation, UpdateOfferStatusMutationVariables>(UpdateOfferStatusDocument, options);
      }
export type UpdateOfferStatusMutationHookResult = ReturnType<typeof useUpdateOfferStatusMutation>;
export type UpdateOfferStatusMutationResult = Apollo.MutationResult<UpdateOfferStatusMutation>;
export type UpdateOfferStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOfferStatusMutation, UpdateOfferStatusMutationVariables>;
export const CopyOfferingDocument = gql`
    mutation copyOffering($id: ID!, $rev: ID!, $name: String!, $startDate: String!, $endDate: String!) {
  copyOffering(
    input: {id: $id, rev: $rev, name: $name, startDate: $startDate, endDate: $endDate}
  ) {
    success
    offering {
      ...OfferingFields
    }
  }
}
    ${OfferingFieldsFragmentDoc}`;
export type CopyOfferingMutationFn = Apollo.MutationFunction<CopyOfferingMutation, CopyOfferingMutationVariables>;

/**
 * __useCopyOfferingMutation__
 *
 * To run a mutation, you first call `useCopyOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyOfferingMutation, { data, loading, error }] = useCopyOfferingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCopyOfferingMutation(baseOptions?: Apollo.MutationHookOptions<CopyOfferingMutation, CopyOfferingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyOfferingMutation, CopyOfferingMutationVariables>(CopyOfferingDocument, options);
      }
export type CopyOfferingMutationHookResult = ReturnType<typeof useCopyOfferingMutation>;
export type CopyOfferingMutationResult = Apollo.MutationResult<CopyOfferingMutation>;
export type CopyOfferingMutationOptions = Apollo.BaseMutationOptions<CopyOfferingMutation, CopyOfferingMutationVariables>;
export const CreateOfferingDocument = gql`
    mutation createOffering($name: String!, $brand: String!, $startDate: String!, $endDate: String!) {
  createOffering(
    input: {name: $name, brand: $brand, startDate: $startDate, endDate: $endDate}
  ) {
    success
    offering {
      ...OfferingFields
    }
  }
}
    ${OfferingFieldsFragmentDoc}`;
export type CreateOfferingMutationFn = Apollo.MutationFunction<CreateOfferingMutation, CreateOfferingMutationVariables>;

/**
 * __useCreateOfferingMutation__
 *
 * To run a mutation, you first call `useCreateOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfferingMutation, { data, loading, error }] = useCreateOfferingMutation({
 *   variables: {
 *      name: // value for 'name'
 *      brand: // value for 'brand'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCreateOfferingMutation(baseOptions?: Apollo.MutationHookOptions<CreateOfferingMutation, CreateOfferingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOfferingMutation, CreateOfferingMutationVariables>(CreateOfferingDocument, options);
      }
export type CreateOfferingMutationHookResult = ReturnType<typeof useCreateOfferingMutation>;
export type CreateOfferingMutationResult = Apollo.MutationResult<CreateOfferingMutation>;
export type CreateOfferingMutationOptions = Apollo.BaseMutationOptions<CreateOfferingMutation, CreateOfferingMutationVariables>;
export const DeleteOfferingDocument = gql`
    mutation deleteOffering($id: ID!, $rev: ID!) {
  deleteOffering(input: {id: $id, rev: $rev}) {
    success
  }
}
    `;
export type DeleteOfferingMutationFn = Apollo.MutationFunction<DeleteOfferingMutation, DeleteOfferingMutationVariables>;

/**
 * __useDeleteOfferingMutation__
 *
 * To run a mutation, you first call `useDeleteOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferingMutation, { data, loading, error }] = useDeleteOfferingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *   },
 * });
 */
export function useDeleteOfferingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOfferingMutation, DeleteOfferingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOfferingMutation, DeleteOfferingMutationVariables>(DeleteOfferingDocument, options);
      }
export type DeleteOfferingMutationHookResult = ReturnType<typeof useDeleteOfferingMutation>;
export type DeleteOfferingMutationResult = Apollo.MutationResult<DeleteOfferingMutation>;
export type DeleteOfferingMutationOptions = Apollo.BaseMutationOptions<DeleteOfferingMutation, DeleteOfferingMutationVariables>;
export const GetOfferingByIdDocument = gql`
    query getOfferingById($id: String!) {
  offering(id: $id) {
    ...OfferingFields
  }
}
    ${OfferingFieldsFragmentDoc}`;

/**
 * __useGetOfferingByIdQuery__
 *
 * To run a query within a React component, call `useGetOfferingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfferingByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOfferingByIdQuery, GetOfferingByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOfferingByIdQuery, GetOfferingByIdQueryVariables>(GetOfferingByIdDocument, options);
      }
export function useGetOfferingByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOfferingByIdQuery, GetOfferingByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOfferingByIdQuery, GetOfferingByIdQueryVariables>(GetOfferingByIdDocument, options);
        }
export type GetOfferingByIdQueryHookResult = ReturnType<typeof useGetOfferingByIdQuery>;
export type GetOfferingByIdLazyQueryHookResult = ReturnType<typeof useGetOfferingByIdLazyQuery>;
export type GetOfferingByIdQueryResult = Apollo.QueryResult<GetOfferingByIdQuery, GetOfferingByIdQueryVariables>;
export const GetOfferingSettingsByOfferingIdDocument = gql`
    query getOfferingSettingsByOfferingId($offeringId: String!) {
  offeringSettingsByOfferingId(offeringId: $offeringId) {
    ...OfferingSettingsFields
  }
}
    ${OfferingSettingsFieldsFragmentDoc}`;

/**
 * __useGetOfferingSettingsByOfferingIdQuery__
 *
 * To run a query within a React component, call `useGetOfferingSettingsByOfferingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferingSettingsByOfferingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferingSettingsByOfferingIdQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *   },
 * });
 */
export function useGetOfferingSettingsByOfferingIdQuery(baseOptions: Apollo.QueryHookOptions<GetOfferingSettingsByOfferingIdQuery, GetOfferingSettingsByOfferingIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOfferingSettingsByOfferingIdQuery, GetOfferingSettingsByOfferingIdQueryVariables>(GetOfferingSettingsByOfferingIdDocument, options);
      }
export function useGetOfferingSettingsByOfferingIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOfferingSettingsByOfferingIdQuery, GetOfferingSettingsByOfferingIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOfferingSettingsByOfferingIdQuery, GetOfferingSettingsByOfferingIdQueryVariables>(GetOfferingSettingsByOfferingIdDocument, options);
        }
export type GetOfferingSettingsByOfferingIdQueryHookResult = ReturnType<typeof useGetOfferingSettingsByOfferingIdQuery>;
export type GetOfferingSettingsByOfferingIdLazyQueryHookResult = ReturnType<typeof useGetOfferingSettingsByOfferingIdLazyQuery>;
export type GetOfferingSettingsByOfferingIdQueryResult = Apollo.QueryResult<GetOfferingSettingsByOfferingIdQuery, GetOfferingSettingsByOfferingIdQueryVariables>;
export const GetOfferingWithOfferDocument = gql`
    query getOfferingWithOffer($offeringId: String!, $offerId: String!) {
  offering: offering(id: $offeringId) {
    ...OfferingFields
  }
  offer: offer(id: $offerId) {
    ...OfferFields
  }
}
    ${OfferingFieldsFragmentDoc}
${OfferFieldsFragmentDoc}`;

/**
 * __useGetOfferingWithOfferQuery__
 *
 * To run a query within a React component, call `useGetOfferingWithOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferingWithOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferingWithOfferQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useGetOfferingWithOfferQuery(baseOptions: Apollo.QueryHookOptions<GetOfferingWithOfferQuery, GetOfferingWithOfferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOfferingWithOfferQuery, GetOfferingWithOfferQueryVariables>(GetOfferingWithOfferDocument, options);
      }
export function useGetOfferingWithOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOfferingWithOfferQuery, GetOfferingWithOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOfferingWithOfferQuery, GetOfferingWithOfferQueryVariables>(GetOfferingWithOfferDocument, options);
        }
export type GetOfferingWithOfferQueryHookResult = ReturnType<typeof useGetOfferingWithOfferQuery>;
export type GetOfferingWithOfferLazyQueryHookResult = ReturnType<typeof useGetOfferingWithOfferLazyQuery>;
export type GetOfferingWithOfferQueryResult = Apollo.QueryResult<GetOfferingWithOfferQuery, GetOfferingWithOfferQueryVariables>;
export const GetOfferingWithOffersDocument = gql`
    query getOfferingWithOffers($id: String!) {
  offering: offering(id: $id) {
    ...OfferingFields
  }
  offers: offersByOfferingId(offeringId: $id) {
    ...OfferFields
  }
}
    ${OfferingFieldsFragmentDoc}
${OfferFieldsFragmentDoc}`;

/**
 * __useGetOfferingWithOffersQuery__
 *
 * To run a query within a React component, call `useGetOfferingWithOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferingWithOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferingWithOffersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfferingWithOffersQuery(baseOptions: Apollo.QueryHookOptions<GetOfferingWithOffersQuery, GetOfferingWithOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOfferingWithOffersQuery, GetOfferingWithOffersQueryVariables>(GetOfferingWithOffersDocument, options);
      }
export function useGetOfferingWithOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOfferingWithOffersQuery, GetOfferingWithOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOfferingWithOffersQuery, GetOfferingWithOffersQueryVariables>(GetOfferingWithOffersDocument, options);
        }
export type GetOfferingWithOffersQueryHookResult = ReturnType<typeof useGetOfferingWithOffersQuery>;
export type GetOfferingWithOffersLazyQueryHookResult = ReturnType<typeof useGetOfferingWithOffersLazyQuery>;
export type GetOfferingWithOffersQueryResult = Apollo.QueryResult<GetOfferingWithOffersQuery, GetOfferingWithOffersQueryVariables>;
export const GetOfferingsForDashboardDocument = gql`
    query getOfferingsForDashboard($brand: String!) {
  active: offeringsByBrandActive(brand: $brand, active: true) {
    ...OfferingFields
  }
  archived: offeringsByBrandActive(brand: $brand, active: false) {
    ...OfferingFields
  }
}
    ${OfferingFieldsFragmentDoc}`;

/**
 * __useGetOfferingsForDashboardQuery__
 *
 * To run a query within a React component, call `useGetOfferingsForDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferingsForDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferingsForDashboardQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *   },
 * });
 */
export function useGetOfferingsForDashboardQuery(baseOptions: Apollo.QueryHookOptions<GetOfferingsForDashboardQuery, GetOfferingsForDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOfferingsForDashboardQuery, GetOfferingsForDashboardQueryVariables>(GetOfferingsForDashboardDocument, options);
      }
export function useGetOfferingsForDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOfferingsForDashboardQuery, GetOfferingsForDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOfferingsForDashboardQuery, GetOfferingsForDashboardQueryVariables>(GetOfferingsForDashboardDocument, options);
        }
export type GetOfferingsForDashboardQueryHookResult = ReturnType<typeof useGetOfferingsForDashboardQuery>;
export type GetOfferingsForDashboardLazyQueryHookResult = ReturnType<typeof useGetOfferingsForDashboardLazyQuery>;
export type GetOfferingsForDashboardQueryResult = Apollo.QueryResult<GetOfferingsForDashboardQuery, GetOfferingsForDashboardQueryVariables>;
export const PreviewOfferingDocument = gql`
    mutation previewOffering($id: ID!, $rev: ID!) {
  previewOffering(input: {id: $id, rev: $rev}) {
    success
  }
}
    `;
export type PreviewOfferingMutationFn = Apollo.MutationFunction<PreviewOfferingMutation, PreviewOfferingMutationVariables>;

/**
 * __usePreviewOfferingMutation__
 *
 * To run a mutation, you first call `usePreviewOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewOfferingMutation, { data, loading, error }] = usePreviewOfferingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *   },
 * });
 */
export function usePreviewOfferingMutation(baseOptions?: Apollo.MutationHookOptions<PreviewOfferingMutation, PreviewOfferingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PreviewOfferingMutation, PreviewOfferingMutationVariables>(PreviewOfferingDocument, options);
      }
export type PreviewOfferingMutationHookResult = ReturnType<typeof usePreviewOfferingMutation>;
export type PreviewOfferingMutationResult = Apollo.MutationResult<PreviewOfferingMutation>;
export type PreviewOfferingMutationOptions = Apollo.BaseMutationOptions<PreviewOfferingMutation, PreviewOfferingMutationVariables>;
export const PublishOfferingDocument = gql`
    mutation publishOffering($id: ID!, $rev: ID!) {
  publishOffering(input: {id: $id, rev: $rev}) {
    success
    offering {
      id
      rev
      status
    }
  }
}
    `;
export type PublishOfferingMutationFn = Apollo.MutationFunction<PublishOfferingMutation, PublishOfferingMutationVariables>;

/**
 * __usePublishOfferingMutation__
 *
 * To run a mutation, you first call `usePublishOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishOfferingMutation, { data, loading, error }] = usePublishOfferingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *   },
 * });
 */
export function usePublishOfferingMutation(baseOptions?: Apollo.MutationHookOptions<PublishOfferingMutation, PublishOfferingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishOfferingMutation, PublishOfferingMutationVariables>(PublishOfferingDocument, options);
      }
export type PublishOfferingMutationHookResult = ReturnType<typeof usePublishOfferingMutation>;
export type PublishOfferingMutationResult = Apollo.MutationResult<PublishOfferingMutation>;
export type PublishOfferingMutationOptions = Apollo.BaseMutationOptions<PublishOfferingMutation, PublishOfferingMutationVariables>;
export const SaveOfferingSettingsDocument = gql`
    mutation saveOfferingSettings($offeringId: ID!, $settings: [OfferingSettingsInput]!) {
  saveOfferingSettings(input: {offeringId: $offeringId, settings: $settings}) {
    success
    offeringId
    offeringRev
    settings {
      ...OfferingSettingsFields
    }
  }
}
    ${OfferingSettingsFieldsFragmentDoc}`;
export type SaveOfferingSettingsMutationFn = Apollo.MutationFunction<SaveOfferingSettingsMutation, SaveOfferingSettingsMutationVariables>;

/**
 * __useSaveOfferingSettingsMutation__
 *
 * To run a mutation, you first call `useSaveOfferingSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOfferingSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOfferingSettingsMutation, { data, loading, error }] = useSaveOfferingSettingsMutation({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useSaveOfferingSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveOfferingSettingsMutation, SaveOfferingSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveOfferingSettingsMutation, SaveOfferingSettingsMutationVariables>(SaveOfferingSettingsDocument, options);
      }
export type SaveOfferingSettingsMutationHookResult = ReturnType<typeof useSaveOfferingSettingsMutation>;
export type SaveOfferingSettingsMutationResult = Apollo.MutationResult<SaveOfferingSettingsMutation>;
export type SaveOfferingSettingsMutationOptions = Apollo.BaseMutationOptions<SaveOfferingSettingsMutation, SaveOfferingSettingsMutationVariables>;
export const UpdateOfferingDocument = gql`
    mutation updateOffering($id: ID!, $rev: ID!, $name: String!, $startDate: String!, $endDate: String!) {
  updateOffering(
    input: {id: $id, rev: $rev, name: $name, startDate: $startDate, endDate: $endDate}
  ) {
    success
    offering {
      ...OfferingFields
    }
  }
}
    ${OfferingFieldsFragmentDoc}`;
export type UpdateOfferingMutationFn = Apollo.MutationFunction<UpdateOfferingMutation, UpdateOfferingMutationVariables>;

/**
 * __useUpdateOfferingMutation__
 *
 * To run a mutation, you first call `useUpdateOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferingMutation, { data, loading, error }] = useUpdateOfferingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUpdateOfferingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOfferingMutation, UpdateOfferingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOfferingMutation, UpdateOfferingMutationVariables>(UpdateOfferingDocument, options);
      }
export type UpdateOfferingMutationHookResult = ReturnType<typeof useUpdateOfferingMutation>;
export type UpdateOfferingMutationResult = Apollo.MutationResult<UpdateOfferingMutation>;
export type UpdateOfferingMutationOptions = Apollo.BaseMutationOptions<UpdateOfferingMutation, UpdateOfferingMutationVariables>;
export const UpdateOfferingStatusDocument = gql`
    mutation updateOfferingStatus($id: ID!, $rev: ID!, $status: String!) {
  updateOfferingStatus(input: {id: $id, rev: $rev, status: $status}) {
    success
    offeringId
    rev
    status
    offers {
      offerId
      rev
      success
      status
      error
    }
  }
}
    `;
export type UpdateOfferingStatusMutationFn = Apollo.MutationFunction<UpdateOfferingStatusMutation, UpdateOfferingStatusMutationVariables>;

/**
 * __useUpdateOfferingStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOfferingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferingStatusMutation, { data, loading, error }] = useUpdateOfferingStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateOfferingStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOfferingStatusMutation, UpdateOfferingStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOfferingStatusMutation, UpdateOfferingStatusMutationVariables>(UpdateOfferingStatusDocument, options);
      }
export type UpdateOfferingStatusMutationHookResult = ReturnType<typeof useUpdateOfferingStatusMutation>;
export type UpdateOfferingStatusMutationResult = Apollo.MutationResult<UpdateOfferingStatusMutation>;
export type UpdateOfferingStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOfferingStatusMutation, UpdateOfferingStatusMutationVariables>;
export const GetSeriesManagerDocument = gql`
    query getSeriesManager($brand: String!) {
  seriesByBrand(brand: $brand) {
    brand
    series
  }
}
    `;

/**
 * __useGetSeriesManagerQuery__
 *
 * To run a query within a React component, call `useGetSeriesManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeriesManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeriesManagerQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *   },
 * });
 */
export function useGetSeriesManagerQuery(baseOptions: Apollo.QueryHookOptions<GetSeriesManagerQuery, GetSeriesManagerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeriesManagerQuery, GetSeriesManagerQueryVariables>(GetSeriesManagerDocument, options);
      }
export function useGetSeriesManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeriesManagerQuery, GetSeriesManagerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeriesManagerQuery, GetSeriesManagerQueryVariables>(GetSeriesManagerDocument, options);
        }
export type GetSeriesManagerQueryHookResult = ReturnType<typeof useGetSeriesManagerQuery>;
export type GetSeriesManagerLazyQueryHookResult = ReturnType<typeof useGetSeriesManagerLazyQuery>;
export type GetSeriesManagerQueryResult = Apollo.QueryResult<GetSeriesManagerQuery, GetSeriesManagerQueryVariables>;
export const SaveSeriesManagerDocument = gql`
    mutation saveSeriesManager($brand: String!, $series: [String]!) {
  saveSeries(input: {brand: $brand, series: $series}) {
    success
    brand
    series
  }
}
    `;
export type SaveSeriesManagerMutationFn = Apollo.MutationFunction<SaveSeriesManagerMutation, SaveSeriesManagerMutationVariables>;

/**
 * __useSaveSeriesManagerMutation__
 *
 * To run a mutation, you first call `useSaveSeriesManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSeriesManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSeriesManagerMutation, { data, loading, error }] = useSaveSeriesManagerMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      series: // value for 'series'
 *   },
 * });
 */
export function useSaveSeriesManagerMutation(baseOptions?: Apollo.MutationHookOptions<SaveSeriesManagerMutation, SaveSeriesManagerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSeriesManagerMutation, SaveSeriesManagerMutationVariables>(SaveSeriesManagerDocument, options);
      }
export type SaveSeriesManagerMutationHookResult = ReturnType<typeof useSaveSeriesManagerMutation>;
export type SaveSeriesManagerMutationResult = Apollo.MutationResult<SaveSeriesManagerMutation>;
export type SaveSeriesManagerMutationOptions = Apollo.BaseMutationOptions<SaveSeriesManagerMutation, SaveSeriesManagerMutationVariables>;
export const CreateDisclaimerDocument = gql`
    mutation createDisclaimer($brand: String!, $name: String!, $text: String!) {
  createDisclaimer(input: {brand: $brand, name: $name, text: $text}) {
    success
    disclaimer {
      id
      rev
      brand
      name
      text
    }
  }
}
    `;
export type CreateDisclaimerMutationFn = Apollo.MutationFunction<CreateDisclaimerMutation, CreateDisclaimerMutationVariables>;

/**
 * __useCreateDisclaimerMutation__
 *
 * To run a mutation, you first call `useCreateDisclaimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisclaimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisclaimerMutation, { data, loading, error }] = useCreateDisclaimerMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      name: // value for 'name'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateDisclaimerMutation(baseOptions?: Apollo.MutationHookOptions<CreateDisclaimerMutation, CreateDisclaimerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDisclaimerMutation, CreateDisclaimerMutationVariables>(CreateDisclaimerDocument, options);
      }
export type CreateDisclaimerMutationHookResult = ReturnType<typeof useCreateDisclaimerMutation>;
export type CreateDisclaimerMutationResult = Apollo.MutationResult<CreateDisclaimerMutation>;
export type CreateDisclaimerMutationOptions = Apollo.BaseMutationOptions<CreateDisclaimerMutation, CreateDisclaimerMutationVariables>;
export const CreateSnippetDocument = gql`
    mutation createSnippet($brand: String!, $name: String!, $text: String!) {
  createSnippet(input: {brand: $brand, name: $name, text: $text}) {
    success
    snippet {
      id
      rev
      brand
      name
      text
    }
  }
}
    `;
export type CreateSnippetMutationFn = Apollo.MutationFunction<CreateSnippetMutation, CreateSnippetMutationVariables>;

/**
 * __useCreateSnippetMutation__
 *
 * To run a mutation, you first call `useCreateSnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSnippetMutation, { data, loading, error }] = useCreateSnippetMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      name: // value for 'name'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateSnippetMutation(baseOptions?: Apollo.MutationHookOptions<CreateSnippetMutation, CreateSnippetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSnippetMutation, CreateSnippetMutationVariables>(CreateSnippetDocument, options);
      }
export type CreateSnippetMutationHookResult = ReturnType<typeof useCreateSnippetMutation>;
export type CreateSnippetMutationResult = Apollo.MutationResult<CreateSnippetMutation>;
export type CreateSnippetMutationOptions = Apollo.BaseMutationOptions<CreateSnippetMutation, CreateSnippetMutationVariables>;
export const DeleteDisclaimerDocument = gql`
    mutation deleteDisclaimer($id: ID!, $rev: ID!) {
  deleteDisclaimer(input: {id: $id, rev: $rev}) {
    success
  }
}
    `;
export type DeleteDisclaimerMutationFn = Apollo.MutationFunction<DeleteDisclaimerMutation, DeleteDisclaimerMutationVariables>;

/**
 * __useDeleteDisclaimerMutation__
 *
 * To run a mutation, you first call `useDeleteDisclaimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDisclaimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDisclaimerMutation, { data, loading, error }] = useDeleteDisclaimerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *   },
 * });
 */
export function useDeleteDisclaimerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDisclaimerMutation, DeleteDisclaimerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDisclaimerMutation, DeleteDisclaimerMutationVariables>(DeleteDisclaimerDocument, options);
      }
export type DeleteDisclaimerMutationHookResult = ReturnType<typeof useDeleteDisclaimerMutation>;
export type DeleteDisclaimerMutationResult = Apollo.MutationResult<DeleteDisclaimerMutation>;
export type DeleteDisclaimerMutationOptions = Apollo.BaseMutationOptions<DeleteDisclaimerMutation, DeleteDisclaimerMutationVariables>;
export const DeleteSnippetDocument = gql`
    mutation deleteSnippet($id: ID!, $rev: ID!) {
  deleteSnippet(input: {id: $id, rev: $rev}) {
    success
  }
}
    `;
export type DeleteSnippetMutationFn = Apollo.MutationFunction<DeleteSnippetMutation, DeleteSnippetMutationVariables>;

/**
 * __useDeleteSnippetMutation__
 *
 * To run a mutation, you first call `useDeleteSnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSnippetMutation, { data, loading, error }] = useDeleteSnippetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *   },
 * });
 */
export function useDeleteSnippetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSnippetMutation, DeleteSnippetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSnippetMutation, DeleteSnippetMutationVariables>(DeleteSnippetDocument, options);
      }
export type DeleteSnippetMutationHookResult = ReturnType<typeof useDeleteSnippetMutation>;
export type DeleteSnippetMutationResult = Apollo.MutationResult<DeleteSnippetMutation>;
export type DeleteSnippetMutationOptions = Apollo.BaseMutationOptions<DeleteSnippetMutation, DeleteSnippetMutationVariables>;
export const GetTokensSnippetsDisclaimersDocument = gql`
    query getTokensSnippetsDisclaimers($brand: String!) {
  tokens: tokens
  snippets: snippetsByBrand(brand: $brand) {
    id
    rev
    brand
    name
    text
  }
  disclaimers: disclaimersByBrand(brand: $brand) {
    id
    rev
    brand
    name
    text
  }
}
    `;

/**
 * __useGetTokensSnippetsDisclaimersQuery__
 *
 * To run a query within a React component, call `useGetTokensSnippetsDisclaimersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokensSnippetsDisclaimersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokensSnippetsDisclaimersQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *   },
 * });
 */
export function useGetTokensSnippetsDisclaimersQuery(baseOptions: Apollo.QueryHookOptions<GetTokensSnippetsDisclaimersQuery, GetTokensSnippetsDisclaimersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTokensSnippetsDisclaimersQuery, GetTokensSnippetsDisclaimersQueryVariables>(GetTokensSnippetsDisclaimersDocument, options);
      }
export function useGetTokensSnippetsDisclaimersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTokensSnippetsDisclaimersQuery, GetTokensSnippetsDisclaimersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTokensSnippetsDisclaimersQuery, GetTokensSnippetsDisclaimersQueryVariables>(GetTokensSnippetsDisclaimersDocument, options);
        }
export type GetTokensSnippetsDisclaimersQueryHookResult = ReturnType<typeof useGetTokensSnippetsDisclaimersQuery>;
export type GetTokensSnippetsDisclaimersLazyQueryHookResult = ReturnType<typeof useGetTokensSnippetsDisclaimersLazyQuery>;
export type GetTokensSnippetsDisclaimersQueryResult = Apollo.QueryResult<GetTokensSnippetsDisclaimersQuery, GetTokensSnippetsDisclaimersQueryVariables>;
export const UpdateDisclaimerDocument = gql`
    mutation updateDisclaimer($id: ID!, $rev: ID!, $name: String!, $text: String!) {
  updateDisclaimer(input: {id: $id, rev: $rev, name: $name, text: $text}) {
    success
    disclaimer {
      id
      rev
      brand
      name
      text
    }
  }
}
    `;
export type UpdateDisclaimerMutationFn = Apollo.MutationFunction<UpdateDisclaimerMutation, UpdateDisclaimerMutationVariables>;

/**
 * __useUpdateDisclaimerMutation__
 *
 * To run a mutation, you first call `useUpdateDisclaimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisclaimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisclaimerMutation, { data, loading, error }] = useUpdateDisclaimerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *      name: // value for 'name'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateDisclaimerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDisclaimerMutation, UpdateDisclaimerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDisclaimerMutation, UpdateDisclaimerMutationVariables>(UpdateDisclaimerDocument, options);
      }
export type UpdateDisclaimerMutationHookResult = ReturnType<typeof useUpdateDisclaimerMutation>;
export type UpdateDisclaimerMutationResult = Apollo.MutationResult<UpdateDisclaimerMutation>;
export type UpdateDisclaimerMutationOptions = Apollo.BaseMutationOptions<UpdateDisclaimerMutation, UpdateDisclaimerMutationVariables>;
export const UpdateSnippetDocument = gql`
    mutation updateSnippet($id: ID!, $rev: ID!, $name: String!, $text: String!) {
  updateSnippet(input: {id: $id, rev: $rev, name: $name, text: $text}) {
    success
    snippet {
      id
      rev
      brand
      name
      text
    }
  }
}
    `;
export type UpdateSnippetMutationFn = Apollo.MutationFunction<UpdateSnippetMutation, UpdateSnippetMutationVariables>;

/**
 * __useUpdateSnippetMutation__
 *
 * To run a mutation, you first call `useUpdateSnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSnippetMutation, { data, loading, error }] = useUpdateSnippetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rev: // value for 'rev'
 *      name: // value for 'name'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateSnippetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSnippetMutation, UpdateSnippetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSnippetMutation, UpdateSnippetMutationVariables>(UpdateSnippetDocument, options);
      }
export type UpdateSnippetMutationHookResult = ReturnType<typeof useUpdateSnippetMutation>;
export type UpdateSnippetMutationResult = Apollo.MutationResult<UpdateSnippetMutation>;
export type UpdateSnippetMutationOptions = Apollo.BaseMutationOptions<UpdateSnippetMutation, UpdateSnippetMutationVariables>;
export const GetUserInfoDocument = gql`
    query getUserInfo {
  userInfo {
    objectId
    name
    email
    roles
    brand
    userTypes
    regionCodes
    tdaCodes
    azureGroups
  }
}
    `;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
      }
export function useGetUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
        }
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = Apollo.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;
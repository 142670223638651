import { TierTableDataType } from 'oat-common-ui';
import TableDataType from '../models/TableDataType';

const formatTableData = (tableData: TierTableDataType, tierTable: TableDataType): TableDataType => {
  return {
    ...tierTable,
    tierTableData: tierTable.tierTableData?.map(item => (item.term === tableData.term ? { ...tableData } : item)),
  };
};

export default formatTableData;

export const TiersLeaseOptions = [
  { value: '1+', label: '1+' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '7+', label: '7+' },
];

export const TermsLeaseOptions = [
  { value: '24', label: '24' },
  { value: '27', label: '27' },
  { value: '30', label: '30' },
  { value: '33', label: '33' },
  { value: '36', label: '36' },
  { value: '39', label: '39' },
  { value: '42', label: '42' },
  { value: '45', label: '45' },
  { value: '48', label: '48' },
  { value: '51', label: '51' },
  { value: '54', label: '54' },
  { value: '57', label: '57' },
  { value: '60', label: '60' },
];

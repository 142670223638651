import { makeObservable, observable, runInAction } from 'mobx';
import { OfferingSettings } from '../gql/generated';

interface IOfferingSettings {
  [offeringId: string]: OfferingSettings[];
}

class OfferingSettingsStore {
  offeringSettings = {} as IOfferingSettings;

  constructor() {
    makeObservable(this, {
      offeringSettings: observable,
    });
  }

  setOfferingSettings = (offeringId: string, offeringSettings: OfferingSettings[]) => {
    runInAction(() => {
      this.offeringSettings = { ...this.offeringSettings, [offeringId]: offeringSettings };
    });
  };
}

export default OfferingSettingsStore;

import clsx from 'clsx';
import { Button, ButtonGroup, IOffer, OfferMarketingDisclaimers, OfferTabBodyLayout, OfferTabsHeader, OfferTabsNav, OfferTypes, UniqueSeriesType, uuidv4 } from 'oat-common-ui';
import React, { ReactNode, useState } from 'react';
import MarketingForm from '.';
import { OfferMarketing } from '../../../../gql/generated';
import styles from './styles.module.scss';

interface Props {
  isLexus: boolean;
  offerType: OfferTypes;
  navList: ReactNode[];
  headerContent: ReactNode;
  seriesMapData: UniqueSeriesType[];
  onSubmit: (
    offerCardTitle: string,
    offerDetailTitle: string,
    disclaimers: OfferMarketingDisclaimers[],
    language: string,
    back: boolean,
    miscType?: string,
    offerDetailAltText?: string,
    offerCardAltText?: string,
    imageDisclaimer?: string,
    description?: string,
    id?: string,
    rev?: string,
  ) => void;
  disclaimers: { brand: string; id: string; name: string; rev: string; text: string; selected?: boolean }[];
  snippets: { brand: string; id: string; name: string; rev: string; text: string }[];
  tokens: string[];
  offer?: IOffer;
  marketingDetails?: OfferMarketing[];
  defaultLanguage?: string;
  setLangParam?: (param: string) => void;
}

const MarketingFormContainer = ({
  isLexus,
  offer,
  offerType,
  navList,
  disclaimers,
  snippets,
  tokens,
  headerContent,
  seriesMapData,
  onSubmit,
  marketingDetails,
  defaultLanguage,
  setLangParam,
}: Props) => {
  const [showSpanish, setShowSpanish] = useState(defaultLanguage === 'es');
  let englishData;
  let spanishData;

  if (marketingDetails) {
    marketingDetails.forEach(detail => {
      if (detail.language === 'en') {
        englishData = {
          ...detail,
          disclaimers: detail.disclaimers?.map((item, i) => ({ ...item, sortOrder: i + 1, uid: uuidv4() })),
        };
      }

      if (detail.language === 'es') {
        spanishData = {
          ...detail,
          disclaimers: detail.disclaimers?.map((item, i) => ({ ...item, sortOrder: i + 1, uid: uuidv4() })),
        };
      }
    });
  }

  const handleShowSpanish = (lang: string, showBoolean: boolean) => {
    if (setLangParam) {
      setLangParam(lang);
    }

    setShowSpanish(showBoolean);
  };

  return (
    <>
      <OfferTabsHeader
        offerType={offerType}
        endDate={offer?.endDate || ''}
        status={offer?.status || ''}
        seriesMap={seriesMapData}
        offerSeries={offer?.series || ''}
        renderHeaderContent={headerContent}
        renderNav={<OfferTabsNav navList={navList} />}
      />
      <OfferTabBodyLayout
        renderContent={
          <>
            {!isLexus && (
              <div className={styles.languageButtons}>
                <ButtonGroup>
                  <Button className={clsx(styles.languageButton, !showSpanish && styles.bgGray)} onClick={() => handleShowSpanish('en', false)} id="english-btn">
                    English
                  </Button>
                  <Button className={clsx(styles.languageButton, showSpanish && styles.bgGray)} onClick={() => handleShowSpanish('es', true)} id="spanish-btn">
                    Spanish
                  </Button>
                </ButtonGroup>
              </div>
            )}
            {!showSpanish && <MarketingForm isLexus={isLexus} disclaimers={disclaimers} snippets={snippets} tokens={tokens} data={englishData} onSubmit={onSubmit} offerType={offerType} />}
            {showSpanish && !isLexus && (
              <MarketingForm
                disclaimers={disclaimers}
                snippets={snippets}
                tokens={tokens}
                data={spanishData}
                copyData={englishData}
                onSubmit={onSubmit}
                showSpanish={showSpanish}
                offerType={offerType}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default MarketingFormContainer;

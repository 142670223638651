import { ErrorMessages, IMultiItem, TierTableDataType } from 'oat-common-ui';
import OfferingsSettingsType from '../../pages/Dashboard/OfferingModal/models/OfferingSettingsType';

const resetIMultiItemError = (items: IMultiItem[]) => {
  items.forEach(item => {
    item.error = false;
  });
};

const isRateOverlap = (tierTable1: TierTableDataType[], tierTable2: TierTableDataType[]) => {
  let rateOverlap = false;
  tierTable1.forEach(item1Enh => {
    tierTable2.forEach(item2Enh => {
      if (item1Enh.term === item2Enh.term) {
        item1Enh.tiers.forEach(tier1 => {
          item2Enh.tiers.forEach(tier2 => {
            if (tier1.tier === tier2.tier && tier1.rate !== null && tier1.rate !== '' && tier2.rate !== null && tier2.rate !== '') {
              rateOverlap = true;
            }
          });
        });
      }
    });
  });

  return rateOverlap;
};

const isMultiItemOverlap = (included1: IMultiItem[], included2: IMultiItem[], markError = false) => {
  let hasOverlap = false;

  included1.forEach(inc1 => {
    const found2 = included2.find(inc2 => {
      return inc2.id === inc1.id;
    });

    if (found2) {
      hasOverlap = true;
      if (markError) {
        inc1.error = true;
        found2.error = true;
      }
    }
  });

  return hasOverlap;
};

type DateOverlapType = string | null | undefined;

const isDateOverlap = (startDate1: DateOverlapType, endDate1: DateOverlapType, startDate2: DateOverlapType, endDate2: DateOverlapType) => {
  // check if all are strings
  if (typeof startDate1 === 'string' && typeof endDate1 === 'string' && typeof startDate2 === 'string' && typeof endDate2 === 'string') {
    return (startDate1 <= endDate2 && endDate1 >= startDate2) || (startDate2 <= endDate1 && endDate2 >= startDate1);
  }

  return false;
};

const validateOfferingSettings = (enhancedList: OfferingsSettingsType[]) => {
  let errorMsg = '';

  // reset error states
  enhancedList.forEach(item => {
    resetIMultiItemError(item.statesSelectorData?.included || []);
  });

  // validate offerings
  enhancedList.forEach((item1, i1) => {
    enhancedList.forEach((item2, i2) => {
      if (i2 > i1 && i1 !== i2 && item1.type === 'apr' && item2.type === 'apr') {
        if (
          isDateOverlap(item1.startDate, item1.endDate, item2.startDate, item2.endDate) &&
          isRateOverlap(item1.tierTable.tierTableData || [], item2.tierTable.tierTableData || []) &&
          isMultiItemOverlap(item1.seriesSelectorData?.included || [], item2.seriesSelectorData?.included || []) &&
          isMultiItemOverlap(item1.statesSelectorData?.included || [], item2.statesSelectorData?.included || [], true)
        ) {
          errorMsg = ErrorMessages.OFFERING_SETTINGS_OVERLAP_APR;
        }
      } else if (i2 > i1 && i1 !== i2 && item1.type === 'lease' && item2.type === 'lease') {
        if (
          isRateOverlap(item1.tierTable.tierTableData || [], item2.tierTable.tierTableData || []) &&
          isMultiItemOverlap(item1.statesSelectorData?.included || [], item2.statesSelectorData?.included || [], true)
        ) {
          errorMsg = ErrorMessages.OFFERING_SETTINGS_OVERLAP_LEASE;
        }
      }
    });
  });

  return errorMsg;
};

export default validateOfferingSettings;

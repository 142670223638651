import OfferTabBreadCrumbs from '../../components/OfferTabComponents/OfferTabBreadCrumbs';
import { Offer, Offering, useGetOfferingWithOffersQuery } from '../../gql/generated';
import useGetEfcData from '../../hooks/useGetEfcData';
import useGetEfcSeriesData from '../../hooks/useGetEfcSeriesData';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import Error from '../Error';
import Loading from '../Loading';
import OfferingComponent from './OfferingComponent';

const OfferingPage = () => {
  const { userInfoStore, offeringsStore, offersStore } = useStores();
  const { offeringId } = useUrlParams();
  const { data, loading, error } = useGetOfferingWithOffersQuery({ variables: { id: offeringId }, fetchPolicy: 'no-cache' });

  const { loading: efcLoading, error: efcError } = useGetEfcData();

  const { loading: seriesLoading, error: seriesError } = useGetEfcSeriesData();
  const breadCrumbs = OfferTabBreadCrumbs(userInfoStore.properties.fullName, offeringsStore.getOfferingById(offeringId));

  if (loading || efcLoading || seriesLoading) {
    return <Loading breadCrumbs={breadCrumbs} />;
  }

  if (error || efcError || seriesError) {
    return <Error breadCrumbs={breadCrumbs} />;
  }

  if (data) {
    offeringsStore.addOffering(data.offering as Offering);
    offeringsStore.updateOffering(data.offering.id, data.offering);
    offersStore.loadOffers(offeringId, data.offers as Offer[]);
  }

  const offering = offeringsStore.getOfferingById(offeringId);
  offeringsStore.setCurrentOffering(offering as Offering);

  return <OfferingComponent />;
};

export default OfferingPage;

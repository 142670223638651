import { observer } from 'mobx-react-lite';
import { OATWrapper, OfferTabReturnCta, OfferTypes, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import MainFooter from '../../../components/MainFooter';
import MainHeader from '../../../components/MainHeader';
import OfferTabBreadCrumbs from '../../../components/OfferTabComponents/OfferTabBreadCrumbs';
import { Offer, useCreateOfferMutation, useUpdateOfferMutation } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import buildOfferUrl from '../../../utils/buildOfferUrl';
import RenderHeaderDetails from '../RenderHeaderDetails';
import InfoTabForm from './InfoTabForm';

interface Props {
  offerType: OfferTypes;
}

const InfoTabComponentBase = ({ offerType }: Props) => {
  const {
    offeringsStore: { currentOffering },
    offersStore: { currentOffer, addOffer, setCurrentOffer, updateOffer },
    userInfoStore,
    regionsStore,
    seriesManagerStore,
  } = useStores();

  const { offerId, lang } = useUrlParams();
  const history = useHistory();
  const [createOffer] = useCreateOfferMutation();
  const [updateOfferMutation] = useUpdateOfferMutation();
  const { success, error } = useToast();
  const [redirectNext, setRedirectNext] = useState('');

  const uniqueSeries = seriesManagerStore.uniqueSeries.concat([{ code: 'MULTI', title: 'MULTI' }]);
  const returnLink = `/offering/${currentOffering?.id}`;

  const handleOnSubmit = async (
    regionCodes: string[],
    seriesYears: string[],
    series: string,
    startDate: string,
    endDate: string,
    next: boolean,
    isAdvertised: boolean,
    certificationType?: string[],
    includedStates?: string[],
    excludedStates?: string[],
  ) => {
    try {
      if (offerId) {
        const res = await updateOfferMutation({
          variables: {
            id: currentOffer?.id || '',
            rev: currentOffer?.rev || '',
            regionCodes,
            seriesYears,
            series,
            startDate,
            endDate,
            includedStates,
            excludedStates,
            isAdvertised,
            certificationType
          },
        });
        const resOffer = res.data?.updateOffer.offer as Offer;
        updateOffer(resOffer.id, resOffer);
        setCurrentOffer(resOffer);
        if (next) {
          setRedirectNext(resOffer.id);
        }
      } else {
        const res = await createOffer({
          variables: {
            regionCodes,
            seriesYears,
            series,
            startDate,
            endDate,
            offeringId: currentOffering.id,
            offerType,
            includedStates,
            excludedStates,
            isAdvertised,
            certificationType,
          },
        });
        const resOffer = res.data?.createOffer.offer as Offer;
        addOffer(resOffer);
        setCurrentOffer(resOffer);
        if (next) {
          setRedirectNext(resOffer.id);
        } else {
          history.push(buildOfferUrl(currentOffering.id, offerType, 'info', lang, resOffer.id));
        }
      }
      success('Info saved successfully.');
    } catch (e) {
      error((e as Error).message);
    }
  };

  if (redirectNext) {
    const tab = offerType !== 'Other' ? 'financial' : 'marketing';
    return <Redirect to={buildOfferUrl(currentOffering.id, offerType, tab, lang, redirectNext)} />;
  }

  return (
    <>
      <MainHeader breadCrumbs={OfferTabBreadCrumbs(userInfoStore.properties.fullName, currentOffering, offerType)} />
      <OATWrapper withPadding>
        <Link to={returnLink} component={OfferTabReturnCta} />
        <InfoTabForm
          enableSeriesOptions
          seriesOptions={uniqueSeries}
          regionOptions={regionsStore.getRegions().map(item => item.code)}
          statesOptions={regionsStore.states}
          statesWithRegions={regionsStore.statesWithRegions}
          offering={currentOffering}
          offerType={offerType}
          headerContent={<RenderHeaderDetails offerType={offerType} />}
          onSubmit={handleOnSubmit}
        />
      </OATWrapper>
      <MainFooter darkTheme />
    </>
  );
};

export default observer(InfoTabComponentBase);

import {
  CashHeaderDetails,
  Dropdown,
  DropdownItem,
  FinancialTabFooter,
  InlineInputLabel,
  Input,
  IOffer,
  IOffering,
  OfferTabBodyLayout,
  OfferTabError,
  OfferTabsHeader,
  OfferTabsNav,
  OfferTypes,
  stringToNumber,
  UniqueSeriesType,
  useInput,
  usePromiseLoading,
} from 'oat-common-ui';
import { ReactNode, useState } from 'react';
import styles from './styles.module.scss';

interface Props {
  isMisc: boolean;
  navList: ReactNode[];
  offering: IOffering;
  offer?: IOffer;
  seriesMapData: UniqueSeriesType[];
  miscTypes?: { value: string; label: string }[];
  onSubmit: (
    cashOfferAmount: number,
    extraCashOfferAmount: number | null,
    extraCashOfferAmountLabel: string,
    fundingSource: string,
    miscType: string,
    back: boolean,
    next: boolean,
  ) => Promise<void>;
}

const labelWidth = 275;

const CashMiscForm = ({ isMisc, navList, offer, seriesMapData, miscTypes, onSubmit }: Props) => {
  const [cashOfferAmount, cashOfferAmountBindFn, cashOfferAmountError] = useInput(offer?.cashOfferAmount, { required: true, isWhole: true, min: 0 });
  const [extraCashOfferAmount, extraCashOfferBindFn] = useInput(offer?.extraCashAmount, { isWhole: true, min: 0 });
  const [extraCashOfferAmountLabel, extraCashOfferAmountLabelBindFn] = useInput(offer?.extraCashLabel);
  const [fundingSource, fundingSourceBindFn, fundingSourceError] = useInput(offer?.fundingSource, { required: true });
  const [miscType, setMiscType] = useState(miscTypes?.find(item => item.value === offer?.miscType) || { value: '', label: 'Select Option Type' });
  const [submitted, setSubmitted] = useState(false);

  const { promiseLoading, loadPromise } = usePromiseLoading();

  const offerType = isMisc ? OfferTypes.MISCELLANEOUS : OfferTypes.CUSTOMER_CASH;
  const hasError = cashOfferAmountError || fundingSourceError || (isMisc && !miscType);

  const handleOnSubmit = async (back: boolean, next: boolean) => {
    setSubmitted(true);

    if (hasError) {
      return;
    }

    await loadPromise(onSubmit(Number(cashOfferAmount), stringToNumber(extraCashOfferAmount), extraCashOfferAmountLabel, fundingSource, miscType.value, back, next));
  };

  const handleMiscType = (newVal: DropdownItem) => {
    if (typeof newVal.label === 'string') {
      setMiscType({ value: newVal.value, label: newVal.label });
    }
  };

  return (
    <>
      <OfferTabsHeader
        offerType={offerType}
        endDate={offer?.endDate || ''}
        status={offer?.status || ''}
        seriesMap={seriesMapData}
        offerSeries={offer?.series || ''}
        renderHeaderContent={<CashHeaderDetails cashAmount={cashOfferAmount} isMisc={isMisc} miscType={miscType.label} />}
        renderNav={<OfferTabsNav navList={navList} />}
      />
      <OfferTabBodyLayout
        renderContent={
          <>
            <div className={styles.form}>
              <InlineInputLabel vertical width={labelWidth} label="Offer Type">
                <Dropdown id="fin-offer-type-dd" darkTheme value={{ value: offerType, label: offerType }} disabled onChange={() => {}} options={[]} />
              </InlineInputLabel>
              <InlineInputLabel vertical width={labelWidth} label="Cash Offer Amount">
                <Input id="fin-cash-offer-amount" darkTheme value={cashOfferAmount} {...cashOfferAmountBindFn} error={cashOfferAmountError} />
              </InlineInputLabel>
              <InlineInputLabel vertical width={labelWidth} label="Extra Cash Offer Amount">
                <Input id="fin-extra-cash-offer-amount" darkTheme value={extraCashOfferAmount} {...extraCashOfferBindFn} />
              </InlineInputLabel>
              <InlineInputLabel vertical width={labelWidth} label="Extra Cash Offer Amount Label">
                <Input id="fin-extra-cash-offer-amount-label" darkTheme value={extraCashOfferAmountLabel} {...extraCashOfferAmountLabelBindFn} />
              </InlineInputLabel>
              {miscTypes && (
                <InlineInputLabel vertical width={labelWidth} label="Miscellaneous Type">
                  <Dropdown id="fin-misc-type-dd" darkTheme value={miscType} onChange={handleMiscType} options={miscTypes} error={!miscType.value} />
                </InlineInputLabel>
              )}
              <InlineInputLabel vertical width={labelWidth} label="Funding Source">
                <Input id="fin-funding-source" darkTheme value={fundingSource} {...fundingSourceBindFn} error={fundingSourceError} />
              </InlineInputLabel>
            </div>
            {submitted && hasError && <OfferTabError />}
          </>
        }
        renderFooter={<FinancialTabFooter onSubmit={handleOnSubmit} disabled={promiseLoading} isNextDisabled={!offer?.isAdvertised} />}
      />
    </>
  );
};

export default CashMiscForm;

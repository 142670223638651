import { dateFormatISOString, uuidv4 } from 'oat-common-ui';
import FreshTierDataType from './models/FreshTierDataType';
import getTomorrowsDate from './utils/getTomorrowsDate';

export const rcfItem = (freshTierData: FreshTierDataType[], stateList: string[]) => ({
  uid: uuidv4(),
  type: 'lease',
  tierTable: { defaultTierLength: 4, maxTierLength: 9, defaultMonthsLength: 3, maxMonthsLength: 3, tierTableData: freshTierData },
  statesSelectorData: {
    excluded: [],
    included: stateList?.map(state => {
      return { id: state, data: state, selected: false };
    }),
  },
});

export const rateItem = (
  startDate: Date,
  endDate: Date,
  seriesList: { id: string; data: string; selected?: boolean }[],
  freshTierData: FreshTierDataType[],
  stateList: string[],
) => ({
  uid: uuidv4(),
  type: 'apr',
  startDate: dateFormatISOString(startDate || getTomorrowsDate()),
  endDate: dateFormatISOString(endDate || getTomorrowsDate()),
  tierTable: { defaultTierLength: 4, maxTierLength: 9, defaultMonthsLength: 5, maxMonthsLength: 6, tierTableData: freshTierData },
  seriesSelectorData: {
    excluded: seriesList?.map(series => {
      return { id: series.id, data: series.data, selected: false };
    }),
    included: [],
  },
  statesSelectorData: {
    excluded: [],
    included: stateList?.map(state => {
      return { id: state, data: state, selected: false };
    }),
  },
});

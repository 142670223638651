import { OfferTypes } from 'oat-common-ui';
import React from 'react';
import { useGetMiscTypesQuery } from '../../../../gql/generated';
import useStores from '../../../../stores/useStores';
import Error from '../../../Error';
import Loading from '../../../Loading';
import FinancialBase from '../FinancialBase';
import MiscFinancialTabComponent from './MiscFinancialTabComponent';

const MiscFinancialTab = () => {
  const { miscTypesStore } = useStores();
  const { data, loading, error } = useGetMiscTypesQuery({ fetchPolicy: 'no-cache', skip: miscTypesStore.isLoaded });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (data) {
    miscTypesStore.setMiscTypes(data.miscTypes);
  }

  return <FinancialBase offerType={OfferTypes.MISCELLANEOUS} component={<MiscFinancialTabComponent />} />;
};

export default MiscFinancialTab;

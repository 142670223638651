import { OfferTypes } from 'oat-common-ui';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './pages/Dashboard';
import Error from './pages/Error';
import Offering from './pages/Offering';
import APRFinancialTab from './pages/offerTabs/FinancialTab/APRFinancialTab';
import CashFinancialTab from './pages/offerTabs/FinancialTab/CashFinancialTab';
import LeaseFinancialTab from './pages/offerTabs/FinancialTab/LeaseFinancialTab';
import MiscFinancialTab from './pages/offerTabs/FinancialTab/MiscFinancialTab';
import InfoTabBase from './pages/offerTabs/InfoTab/InfoTabBase';
import MarketingTabBase from './pages/offerTabs/MarketingTab/MarketingTabBase';
import PersonalSettings from './pages/PersonalSettings';
import SeriesManagerSettings from './pages/SeriesManagerSettings';
import TokenSettings from './pages/TokenSettings';

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/dashboard">
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId">
        <Offering />
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId/apr/info">
        <InfoTabBase offerType={OfferTypes.APR} />;
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId/apr/financial">
        <APRFinancialTab />
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId/apr/marketing">
        <MarketingTabBase offerType={OfferTypes.APR} />
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId/cash/info">
        <InfoTabBase offerType={OfferTypes.CUSTOMER_CASH} />;
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId/cash/financial">
        <CashFinancialTab />
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId/cash/marketing">
        <MarketingTabBase offerType={OfferTypes.CUSTOMER_CASH} />
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId/lease/info">
        <InfoTabBase offerType={OfferTypes.LEASE} />;
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId/lease/financial">
        <LeaseFinancialTab />
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId/lease/marketing">
        <MarketingTabBase offerType={OfferTypes.LEASE} />
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId/misc/info">
        <InfoTabBase offerType={OfferTypes.MISCELLANEOUS} />;
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId/misc/financial">
        <MiscFinancialTab />
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId/misc/marketing">
        <MarketingTabBase offerType={OfferTypes.MISCELLANEOUS} />
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId/other/info">
        <InfoTabBase offerType={OfferTypes.OTHER} />;
      </PrivateRoute>
      <PrivateRoute exact path="/offering/:offeringId/other/marketing">
        <MarketingTabBase offerType={OfferTypes.OTHER} />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/personal">
        <PersonalSettings />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/tokens">
        <TokenSettings />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/series-manager">
        <SeriesManagerSettings />
      </PrivateRoute>
      <Route exact path="/error">
        <Error />
      </Route>
      <Redirect from="/" to="/dashboard" />
    </Switch>
  );
};

export default Routes;

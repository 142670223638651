import { runInAction } from 'mobx';
import { AdminConstants } from 'oat-admin-common';
import { Region } from '../gql/generated';

const { REGIONS } = AdminConstants;

class RegionsStore {
  isLoaded = false;
  regions: Region[] = [];
  states: string[] = [];
  statesWithRegions = {} as { [state: string]: string };

  setStates = () => {
    const regionsCopy = this.regions
      .map(region => {
        return { code: region.code, statesList: region.statesList };
      })
      .filter(item => item.code !== '190' && item.code !== '390');
    const statesWithRegions = {} as { [state: string]: string };

    regionsCopy.forEach(region => {
      if (region.statesList.includes(',')) {
        region.statesList.split(',').forEach(state => {
          if (statesWithRegions[state]) {
            statesWithRegions[state] = statesWithRegions[state] + `,${region.code}`;
          } else {
            statesWithRegions[state] = region.code;
          }
        });
      } else {
        if (statesWithRegions[region.statesList]) {
          statesWithRegions[region.statesList] = statesWithRegions[region.statesList] + `,${region.code}`;
        } else {
          statesWithRegions[region.statesList] = region.code;
        }
      }
    });

    this.statesWithRegions = statesWithRegions;
    this.states = Object.keys(statesWithRegions).sort();
  };

  setRegions = (brand: string) => {
    runInAction(() => {
      this.regions = REGIONS.filter(item => item.brand === brand);
      this.isLoaded = true;
    });

    this.setStates();
  };

  // gets regions without national
  getRegions = () => {
    return this.regions.filter(item => item.code !== '190' && item.code !== '390');
  };
}

export default RegionsStore;

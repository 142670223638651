const buildOfferUrl = (offeringId: string, offerType: string, tab: string, lang?: string, offerId?: string) => {
  const params: string[] = [];
  if (offerId) {
    params.push(`offerId=${offerId}`);
  }
  if (lang) {
    params.push(`lang=${lang}`);
  }
  const qParams = params.length ? `?${params.join('&')}` : '';
  return `/offering/${offeringId}/${offerType.toLowerCase()}/${tab}${qParams}`;
};

export default buildOfferUrl;

import { makeObservable, observable, runInAction } from 'mobx';
import { Offer } from '../gql/generated';

class OffersStore {
  offers: Offer[] = [];
  currentOffer: Offer | undefined = undefined;
  loadedOffersByOfferingId: { [offeringId: string]: boolean } = {};

  constructor() {
    makeObservable(this, {
      offers: observable,
      currentOffer: observable,
    });
  }

  setCurrentOffer = (offer: Offer | undefined) => {
    runInAction(() => {
      this.currentOffer = offer;
    });
  };

  getOffersByOfferingId = (offeringId: string) => {
    return this.offers.filter(item => item.offeringId === offeringId);
  };

  hasLoadedOffers = (offeringId: string) => {
    return this.loadedOffersByOfferingId[offeringId];
  };

  loadOffers = (offeringId: string, offers: Offer[]) => {
    runInAction(() => {
      this.offers = [...this.offers.filter(offer => offer.offeringId !== offeringId), ...offers];
      this.loadedOffersByOfferingId = { ...this.loadedOffersByOfferingId, [offeringId]: true };
    });
  };

  offerExists = (offerId: string) => {
    return this.offers.findIndex(item => item.id === offerId) !== -1;
  };

  getOfferById = (offerId: string) => {
    return this.offers.find(item => item.id === offerId);
  };

  addOffer = (offer: Offer) => {
    runInAction(() => {
      this.offers = [...this.offers, offer];
    });
  };
  
  copyOffer = (offer: Offer) => {
    runInAction(() => { 
      this.offers = [...this.offers, offer];
    });
  };

  addOffers = (offers: Offer[]) => {
    runInAction(() => {
      this.offers = [...this.offers, ...offers];
    });
  };

  updateOffer = (offerId: string, props: Partial<Offer>) => {
    runInAction(() => {
      this.offers = this.offers.map(item => {
        if (item.id === offerId) {
          return {
            ...item,
            ...props,
          };
        }

        return item;
      });
    });
  };

  removeOffer = (offerId: string) => {
    runInAction(() => {
      this.offers = this.offers.filter(item => item.id !== offerId);
    });
  };

  removeOffersByOfferingId = (offeringId: string) => {
    runInAction(() => {
      this.offers = this.offers.filter(item => item.offeringId !== offeringId);
    });
  };
}

export default OffersStore;

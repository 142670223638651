import { OfferTypes, assignStringValue } from 'oat-common-ui';
import { useState } from 'react';
import OfferTabBreadCrumbs from '../../../components/OfferTabComponents/OfferTabBreadCrumbs';
import { Offering, useGetOfferByIdQuery, useGetOfferingByIdQuery } from '../../../gql/generated';
import useGetEfcSeriesData from '../../../hooks/useGetEfcSeriesData';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import Error from '../../Error';
import Loading from '../../Loading';
import InfoTabComponentBase from './InfoTabComponentBase';

interface Props {
  offerType: OfferTypes;
}

const InfoTabBase = ({ offerType }: Props) => {
  const { offeringsStore, offersStore, regionsStore, userInfoStore } = useStores();
  const { offeringId, offerId } = useUrlParams();

  // fetch offering
  const { data, loading, error } = useGetOfferingByIdQuery({
    variables: { id: offeringId },
    fetchPolicy: 'no-cache',
    skip: offeringsStore.offeringExists(offeringId),
  });

  // fetch offer data
  const {
    data: offerData,
    loading: offerLoading,
    error: offerError,
  } = useGetOfferByIdQuery({
    variables: { id: assignStringValue(offerId) },
    fetchPolicy: 'no-cache',
    skip: !offerId || offersStore.offerExists(offerId),
  });

  const { loading: seriesLoading, error: seriesError } = useGetEfcSeriesData();

  const [loaded, setLoaded] = useState(false);
  const breadCrumbs = OfferTabBreadCrumbs(userInfoStore.properties.fullName, offeringsStore.getOfferingById(offeringId), offerType);

  if (loading || seriesLoading || offerLoading) {
    return <Loading breadCrumbs={breadCrumbs} />;
  }

  if (error || seriesError || offerError) {
    return <Error breadCrumbs={breadCrumbs} />;
  }

  if (data) {
    offeringsStore.addOffering(data.offering as Offering);
  }

  if (offerData) {
    offersStore.addOffer(offerData.offer);
  }

  if (!loaded) {
    regionsStore.setRegions(userInfoStore.userInfo.brand);
    offersStore.setCurrentOffer(offersStore.getOfferById(assignStringValue(offerId)));
    offeringsStore.setCurrentOffering(offeringsStore.getOfferingById(offeringId) as Offering);
    setLoaded(true);
  }

  return <InfoTabComponentBase offerType={offerType} />;
};

export default InfoTabBase;

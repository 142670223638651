import { EfcApi } from 'oat-common-ui';
import { useEffect, useState } from 'react';
import useStores from '../stores/useStores';

const apiKey = process.env.REACT_APP_EFC_MS_API_KEY as string;
const domain = process.env.REACT_APP_EFC_MS_URL as string;

const useGetEfcData = () => {
  const { userInfoStore, efcStore } = useStores();

  const [loading, setLoading] = useState(!efcStore.series);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    if (!efcStore.series) {
      (async () => {
        try {
          const { brand } = userInfoStore.userInfo;
          const res = await EfcApi.getSeriesV1(domain, apiKey, brand);
          efcStore.setSeriesV1(res.data, brand);
          setError(undefined);
          setLoading(false);
        } catch (e) {
          setError(e as Error);
          setLoading(false);
        }
      })();
    }
  }, [efcStore, userInfoStore]);

  return {
    loading,
    error,
  };
};

export default useGetEfcData;

import { APRHeaderDetails, CashHeaderDetails, LeaseHeaderDetails, OfferTypes } from 'oat-common-ui';
import useStores from '../../stores/useStores';

type Props = {
  offerType: OfferTypes;
};

/**
 * Renders header details. Not used in financial tab because values need to change
 */
const RenderHeaderDetails = ({ offerType }: Props) => {
  const {
    offersStore: { currentOffer },
  } = useStores();

  switch (offerType) {
    case OfferTypes.APR: {
      return <APRHeaderDetails apr={currentOffer?.rate?.toString() || ''} terms={currentOffer?.term || ''} subVen={currentOffer?.subventionAmount?.toString() || ''} />;
    }
    case OfferTypes.LEASE: {
      return <LeaseHeaderDetails rcf={currentOffer?.rcf?.toString() || ''} term={currentOffer?.term || ''} />;
    }
    case OfferTypes.CUSTOMER_CASH: {
      return <CashHeaderDetails isMisc={false} cashAmount={currentOffer?.cashOfferAmount?.toString() || ''} miscType="" />;
    }
    case OfferTypes.MISCELLANEOUS: {
      return <CashHeaderDetails isMisc cashAmount={currentOffer?.cashOfferAmount?.toString() || ''} miscType={currentOffer?.miscType || ''} />;
    }
    default: {
      return null;
    }
  }
};

export default RenderHeaderDetails;

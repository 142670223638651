import {
  Button,
  ButtonGroup,
  Disclaimer,
  DragDrop,
  Draggable,
  DraggableProvided,
  DropResult,
  InlineInputLabel,
  Input,
  OfferMarketingDisclaimers,
  OfferTabBodyFooter,
  OfferTabError,
  OfferTypes,
  SnippetInput,
  Textarea,
  useInput,
  uuidv4
} from 'oat-common-ui';
import React, { useState } from 'react';
import { OfferMarketing } from '../../../../gql/generated';
import styles from './styles.module.scss';

interface Props {
  disclaimers: { brand: string; id: string; name: string; rev: string; text: string; selected?: boolean }[];
  snippets: { brand: string; id: string; name: string; rev: string; text: string }[];
  tokens: string[];
  isLexus?: boolean;
  data?: OfferMarketing;
  copyData?: OfferMarketing;
  showSpanish?: boolean;
  onSubmit: (
    offerCardTitle: string,
    offerDetailTitle: string,
    disclaimers: OfferMarketingDisclaimers[],
    language: string,
    back: boolean,
    miscType?: string,
    offerDetailAltText?: string,
    offerCardAltText?: string,
    imageDisclaimer?: string,
    description?: string,
    id?: string,
    rev?: string,
  ) => void;
  offerType: OfferTypes;
}

const labelWidth = 200;

const MarketingForm = ({ disclaimers, snippets, tokens, data, copyData, onSubmit, showSpanish, isLexus, offerType }: Props) => {
  const isOther = offerType === OfferTypes.OTHER;
  const [disclaimersList, setDisclaimersList] = useState<OfferMarketingDisclaimers[]>(
    data?.disclaimers.length ? data?.disclaimers : [{ uid: uuidv4(), tcuvDisclaimerFileId: '', sortOrder: 1, tcuvDisclaimerFileName: '', text: '' }],
  );
  const [miscType, setMiscType] = useState(data?.miscType || '');
  const [offerCardTitle, , offerCardTitleError, setOfferCardTitle] = useInput(data?.offerCardTitle || '', { required: !isLexus });
  const [offerCardAltText, , offerCardAltTextError, setOfferCardAltText] = useInput(data?.offerCardAltText || '');
  const [offerDetailTitle, , offerDetailTitleError, setOfferDetailTitle] = useInput(data?.offerDetailTitle || '', { required: !isLexus });
  const [offerDetailAltText, , offerDetailAltTextError, setOfferDetailAltText] = useInput(data?.offerDetailAltText || '');
  const [description, , descriptionError, setDescription] = useInput(data?.description || '');
  const [imageDisclaimer, , imageDisclaimerError, setImageDisclaimer] = useInput(data?.imageDisclaimer || '');
  const [submitted, setSubmitted] = useState(false);

  const hasError = (!isLexus && !offerCardTitle) || (!isLexus && !offerDetailTitle) || (!isLexus && disclaimersList.find(item => item.text === ''));

  const formatDisclaimersList = (list: OfferMarketingDisclaimers[]) => {
    return list.map(item => ({ sortOrder: item.sortOrder, text: item.text, tcuvDisclaimerFileName: item.tcuvDisclaimerFileName, tcuvDisclaimerFileId: item.tcuvDisclaimerFileId }));
  };

  const handleSubmit = (back: boolean) => {
    setSubmitted(true);

    if (hasError) {
      return;
    }

    onSubmit(
      offerCardTitle,
      offerDetailTitle,
      formatDisclaimersList(disclaimersList),
      showSpanish ? 'es' : 'en',
      back,
      miscType,
      offerDetailAltText,
      offerCardAltText,
      imageDisclaimer,
      description,
      data?.id || '',
      data?.rev || '',
    );
  };

  const handleAddDisclaimer = (index: number) => {
    setDisclaimersList([...disclaimersList, { uid: uuidv4(), tcuvDisclaimerFileId: '', sortOrder: index + 1, tcuvDisclaimerFileName: '', text: '' }]);
  };

  const handleRemoveDisclaimer = (uid: string) => {
    const newList = disclaimersList
      .filter(item => item.uid !== uid)
      .map((item, i) => ({
        uid: item.uid,
        tcuvDisclaimerFileId: item.tcuvDisclaimerFileId,
        sortOrder: i + 1,
        tcuvDisclaimerFileName: item.tcuvDisclaimerFileName,
        text: item.text,
      }));
    setDisclaimersList(newList);
  };

  const handleDisclaimersSort = (newSortOrder: number, currentSortOrder: number) => {
    let newIndex = newSortOrder - 1;
    const currentIndex = currentSortOrder - 1;

    const disclaimerToMove = disclaimersList[currentIndex];

    // Ex. Move disclaimer from 3 to 1
    if (newIndex < currentIndex || newIndex === currentIndex) {
      disclaimersList.splice(newIndex, 0, disclaimerToMove);
      disclaimersList.splice(currentIndex + 1, 1); // prev splice moved this item up. So add one to index
    }

    // Ex. Move disclaimer from 1 to 3
    if (newIndex > currentIndex) {
      disclaimersList.splice(newIndex + 1, 0, disclaimerToMove);
      disclaimersList.splice(currentIndex, 1);
    }

    const newDisclaimersList = disclaimersList.map((item, i) => ({
      uid: item.uid,
      sortOrder: i + 1,
      tcuvDisclaimerFileId: item.tcuvDisclaimerFileId,
      tcuvDisclaimerFileName: item.tcuvDisclaimerFileName,
      text: item.text,
    }));

    setDisclaimersList(newDisclaimersList);
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const disclaimersListCopy = [...disclaimersList];
    const [itemToMove] = disclaimersListCopy.splice(result.source.index, 1);
    disclaimersListCopy.splice(result.destination.index, 0, itemToMove);

    setDisclaimersList(
      disclaimersListCopy.map((item, i) => ({
        ...item,
        uid: item.uid,
        tcuvDisclaimerFileId: item.tcuvDisclaimerFileId,
        sortOrder: i + 1,
        tcuvDisclaimerFileName: item.tcuvDisclaimerFileName,
        text: item.text,
      })),
    );
  };

  const handleSetDisclaimersList = (uid: string, file: string, text: string, id: string) => {
    const disclaimersListCopy = [...disclaimersList];
    const index = disclaimersListCopy.findIndex(item => item.uid === uid);

    if (index !== -1) {
      const found = disclaimersListCopy[index];
      found.tcuvDisclaimerFileName = file;
      found.text = text;
      found.tcuvDisclaimerFileId = id;

      disclaimersListCopy.splice(index, 1, found);
      setDisclaimersList(disclaimersListCopy);
    }
  };

  const handleCopyFromEnglish = (engData: OfferMarketing) => {
    setMiscType(engData.miscType || '');
    setOfferCardTitle(engData.offerCardTitle || '');
    setOfferCardAltText(engData.offerCardAltText || '');
    setOfferDetailTitle(engData.offerDetailTitle || '');
    setOfferDetailAltText(engData.offerDetailAltText || '');
    setDescription(engData.description || '');
    setImageDisclaimer(engData.imageDisclaimer || '');
    setDisclaimersList(engData.disclaimers);
  };

  return (
    <div className={styles.marketingForm}>
      {showSpanish && (
        <InlineInputLabel label="Offer Card Esp" vertical width={labelWidth}>
          <Button
            variant="primary"
            id="copy-from-english-btn"
            onClick={() => {
              if (copyData) {
                handleCopyFromEnglish(copyData);
              }
            }}
          >
            Copy From English
          </Button>
        </InlineInputLabel>
      )}
      {
        isOther && (
          <InlineInputLabel vertical width={labelWidth} label="Miscellaneous Type">
            <Input id="mar-misc-type" darkTheme value={miscType} onChange={(e) => setMiscType(e.target.value)} className={styles.miscInput} />
          </InlineInputLabel>
        )
      }
      <InlineInputLabel vertical width={labelWidth} label="Offer Card Title">
        <SnippetInput id="offer-card-title" darkTheme value={offerCardTitle} setInputValue={setOfferCardTitle} snippets={snippets} tokens={tokens} error={offerCardTitleError} />
      </InlineInputLabel>
      <InlineInputLabel vertical width={labelWidth} label="Offer Card Alt Text">
        <SnippetInput
          id="offer-card-alt-text"
          darkTheme
          value={offerCardAltText}
          setInputValue={setOfferCardAltText}
          snippets={snippets}
          tokens={tokens}
          error={offerCardAltTextError}
        />
      </InlineInputLabel>
      <InlineInputLabel vertical width={labelWidth} label="Offer Detail Title">
        <SnippetInput
          id="offer-detail-title"
          darkTheme
          value={offerDetailTitle}
          snippets={snippets}
          tokens={tokens}
          setInputValue={setOfferDetailTitle}
          error={offerDetailTitleError}
        />
      </InlineInputLabel>
      <InlineInputLabel vertical width={labelWidth} label="Offer Detail Alt Text">
        <SnippetInput
          id="offer-detail-alt-text"
          darkTheme
          value={offerDetailAltText}
          setInputValue={setOfferDetailAltText}
          snippets={snippets}
          tokens={tokens}
          error={offerDetailAltTextError}
        />
      </InlineInputLabel>
      <InlineInputLabel vertical width={labelWidth} label="Description">
        <Textarea
          id="marketing-offer-description"
          className={styles.textArea}
          darkTheme
          value={description}
          onChange={e => setDescription(e.target.value)}
          tokens={tokens.map(name => ({ name, char: name }))}
          secondaryTokens={snippets.map(item => ({ name: item.name, char: item.text }))}
          error={descriptionError}
        />
      </InlineInputLabel>
      <InlineInputLabel vertical width={labelWidth} label="Image Disclaimer">
        <SnippetInput id="image-disclaimer" darkTheme value={imageDisclaimer} snippets={snippets} tokens={tokens} setInputValue={setImageDisclaimer} error={imageDisclaimerError} />
      </InlineInputLabel>
      <DragDrop droppableId="disclaimers" handleOnDragEnd={handleOnDragEnd}>
        {disclaimersList.map((item, i) => {
          return (
            <Draggable key={item.uid} draggableId={item.uid || ''} index={i}>
              {(provided: DraggableProvided) => {
                return (
                  <Disclaimer
                    innerRef={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={item.uid}
                    uid={item.uid || ''}
                    id={item.tcuvDisclaimerFileId || ''}
                    isLexus={isLexus}
                    file={item.tcuvDisclaimerFileName || ''}
                    text={item.text || ''}
                    sortOrder={item.sortOrder}
                    disclaimersList={disclaimersList}
                    setDisclaimersList={handleSetDisclaimersList}
                    addDisclaimer={handleAddDisclaimer}
                    removeDisclaimer={handleRemoveDisclaimer}
                    handleDisclaimersSort={handleDisclaimersSort}
                    disclaimers={disclaimers}
                    tokens={tokens.map(name => ({ name, char: name }))}
                    snippets={snippets.map(snippet => ({ name: snippet.name, char: snippet.text }))}
                    showAdd={i === disclaimersList.length - 1}
                  />
                );
              }}
            </Draggable>
          );
        })}
      </DragDrop>
      {submitted && hasError && <OfferTabError />}
      <OfferTabBodyFooter>
        <ButtonGroup>
          <Button variant="primary" id="back-btn" onClick={() => handleSubmit(true)}>
            Back
          </Button>
          <Button variant="primary" id="save-btn" onClick={() => handleSubmit(false)}>
            Save
          </Button>
        </ButtonGroup>
      </OfferTabBodyFooter>
    </div>
  );
};

export default MarketingForm;

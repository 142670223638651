import { SettingsBreadCrumbs } from '../../components/SettingsComponents';
import { Disclaimer, Snippet, useGetTokensSnippetsDisclaimersQuery } from '../../gql/generated';
import useStores from '../../stores/useStores';
import Error from '../Error';
import Loading from '../Loading';
import TokenSettingsComponent from './TokenSettingsComponent';

const TokenSettings = () => {
  const {
    userInfoStore: {
      properties: { fullName },
      userInfo,
    },
    tokenStore,
  } = useStores();

  const { data, loading, error } = useGetTokensSnippetsDisclaimersQuery({ variables: { brand: userInfo.brand }, fetchPolicy: 'no-cache' });

  if (loading) {
    return <Loading breadCrumbs={SettingsBreadCrumbs(fullName)} />;
  }

  if (error) {
    return <Error />;
  }

  if (data) {
    tokenStore.setTokensSnippetsDisclaimers(data?.tokens, data?.snippets as Snippet[], data?.disclaimers as Disclaimer[]);
  }
  return <TokenSettingsComponent />;
};

export default TokenSettings;

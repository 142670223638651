import { SeriesV1Response } from 'oat-common-ui';

type Item = {
  id: string;
  data: string;
  index?: number;
};

const seriesListFilter = (seriesList: SeriesV1Response, seriesManagerData: string[]): Item[] => {
  const seriesMap = new Map<string, string>();
  const endYear = new Date().getFullYear() + 1;
  const startYear = new Date().getFullYear() - 7;

  // Process EFC first
  seriesList.series_data.forEach(series => {
    series.series_list.forEach(seriesItem => {
      seriesMap.set(seriesItem.code, seriesItem.title);
    });
  });

  // Process Series Settings
  seriesManagerData.forEach(series => {
    const code = series.split(' ').join('').toLowerCase();
    seriesMap.set(code, series);
  });

  // get keys and sort them (sorts by series name)
  // then sort per series (sorts array by year) and concat them to retArray
  let retArray: Item[] = [];
  let currIndex = 0;
  Array.from(seriesMap.keys())
    .sort()
    .forEach(code => {
      const title = seriesMap.get(code);
      let currentYear = startYear;
      while (currentYear <= endYear) {
        retArray.push({
          id: `${currentYear} ${code}`,
          data: `${currentYear} ${title}`,
          index: currIndex,
        });
        currentYear += 1;
        currIndex += 1;
      }
    });

  return retArray;
};

export default seriesListFilter;

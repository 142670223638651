import { SeriesManagerLayout, SettingsLayout, useToast } from 'oat-common-ui';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import MainHeader from '../../components/MainHeader';
import { SettingsBreadCrumbs, SettingsNav } from '../../components/SettingsComponents';
import { useSaveSeriesManagerMutation } from '../../gql/generated';
import useStores from '../../stores/useStores';

const SeriesManagerSettingsComponent = () => {
  const { error } = useToast();
  const { seriesManagerStore, userInfoStore } = useStores();
  const [saveSeries] = useSaveSeriesManagerMutation();

  const handleOnSave = async (series: string[]) => {
    try {
      const res = await trackPromise(
        saveSeries({
          variables: {
            brand: userInfoStore.userInfo.brand,
            series,
          },
        }),
      );

      seriesManagerStore.setSeriesSettings(res.data?.saveSeries.series as string[]);
    } catch (err) {
      const e = err as Error;
      error(e.message);
    }
  };

  return (
    <>
      <MainHeader showMiscLinks={false} breadCrumbs={SettingsBreadCrumbs(userInfoStore.properties.fullName)} />
      <SettingsLayout settings={SettingsNav}>
        <SeriesManagerLayout seriesList={seriesManagerStore.seriesSettings} onSave={handleOnSave} />
      </SettingsLayout>
    </>
  );
};

export default SeriesManagerSettingsComponent;

import { IMultiItem, sortFieldsHelper, UniqueSeriesType } from 'oat-common-ui';

export const multiSeriesOptions = (seriesOptions: UniqueSeriesType[], seriesYearRange: number) => {
  const finalSeriesOptions = [] as { id: string; data: string; selected: boolean; index?: number }[];

  if (seriesOptions) {
    const sortedSeriesOptions = seriesOptions.sort(sortFieldsHelper('title', true));

    sortedSeriesOptions.forEach(option => {
      const maxYear = new Date().getFullYear() + 1;
      let currYear = maxYear - seriesYearRange;

      while (currYear <= maxYear) {
        finalSeriesOptions.push({
          id: `${currYear.toString()} ${option.code}`,
          data: `${currYear.toString()} ${option.title}`,
          selected: false,
        });

        currYear += 1;
      }
    });
  }

  return finalSeriesOptions.map((item, index) => ({ id: item.id, data: item.data, selected: item.selected, index }));
};

export const sortStates = (arr: IMultiItem[]) => {
  return arr.sort((a, b) => {
    if (a.id > b.id) {
      return 1;
    }

    if (b.id > a.id) {
      return -1;
    }

    return 0;
  });
};

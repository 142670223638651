import {
  Dropdown,
  DropdownItem,
  FinancialTabFooter,
  InlineInputLabel,
  Input,
  IOffer,
  IOffering,
  LeaseHeaderDetails,
  OfferTabBodyLayout,
  OfferTabError,
  OfferTabsHeader,
  OfferTabsNav,
  OfferTypes,
  UniqueSeriesType,
  formatRcf,
  useInput,
  usePromiseLoading,
} from 'oat-common-ui';
import { ReactNode, useState } from 'react';
import { TermsLeaseOptions, TiersLeaseOptions } from './leaseOptions';
import styles from './styles.module.scss';

interface Props {
  navList: ReactNode[];
  offering: IOffering;
  offer?: IOffer;
  seriesMapData: UniqueSeriesType[];
  onSubmit: (term: string, tier: string, rcf: number, back: boolean, next: boolean) => Promise<void>;
}

const labelWidth = 275;

const LeaseFinancialForm = ({ navList, offer, seriesMapData, onSubmit }: Props) => {
  const [tiersFilter, setTiersFilter] = useState<DropdownItem | undefined>(TiersLeaseOptions.find(item => item.value === offer?.tier) || { value: '', label: 'Select Tier' });
  const [termsFilter, setTermsFilter] = useState<DropdownItem | undefined>(TermsLeaseOptions.find(item => item.value === offer?.term) || { value: '', label: 'Select Term' });
  const [rcf, rcfBindFn, rcfError] = useInput(formatRcf(offer?.rcf) || '0', { required: true, isRcf: true });
  const [submitted, setSubmitted] = useState(false);

  const { promiseLoading, loadPromise } = usePromiseLoading();

  const handleTiersChange = (newVal: DropdownItem) => {
    if (typeof newVal.label === 'string') {
      setTiersFilter({ value: newVal.value, label: newVal.label });
    }
  };

  const handleTermsChange = (newVal: DropdownItem) => {
    if (typeof newVal.label === 'string') {
      setTermsFilter({ value: newVal.value, label: newVal.label });
    }
  };

  const hasError = !tiersFilter?.value || !termsFilter?.value || rcfError;

  const handleOnSubmit = async (back: boolean, next: boolean) => {
    setSubmitted(true);
    if (hasError) {
      return;
    }

    await loadPromise(onSubmit(termsFilter?.value || '', tiersFilter?.value || '', Number(rcf), back, next));
  };

  return (
    <>
      <OfferTabsHeader
        offerType={OfferTypes.LEASE}
        endDate={offer?.endDate || ''}
        status={offer?.status || ''}
        seriesMap={seriesMapData}
        offerSeries={offer?.series || ''}
        renderHeaderContent={<LeaseHeaderDetails rcf={rcf} term={termsFilter?.value || ''} />}
        renderNav={<OfferTabsNav navList={navList} />}
      />
      <OfferTabBodyLayout
        renderContent={
          <>
            <div className={styles.form}>
              <InlineInputLabel vertical width={labelWidth} label="Term" className={styles.label}>
                <div className={styles.input}>
                  <Dropdown id="fin-terms-series" placeholder="Select Terms" options={TermsLeaseOptions} value={termsFilter} onChange={handleTermsChange} darkTheme />
                </div>
              </InlineInputLabel>
              <InlineInputLabel vertical width={labelWidth} label="Tier" className={styles.label}>
                <div className={styles.input}>
                  <Dropdown id="fin-tiers-series" placeholder="Select Tier" options={TiersLeaseOptions} value={tiersFilter} onChange={handleTiersChange} darkTheme />
                </div>
              </InlineInputLabel>
              <InlineInputLabel vertical width={labelWidth} label="Rent Charge Factor">
                <Input id="fin-rcf" darkTheme value={rcf} {...rcfBindFn} error={rcfError} />
              </InlineInputLabel>
            </div>
            {submitted && hasError && <OfferTabError />}
          </>
        }
        renderFooter={<FinancialTabFooter onSubmit={handleOnSubmit} disabled={promiseLoading} isNextDisabled={!offer?.isAdvertised} />}
      />
    </>
  );
};

export default LeaseFinancialForm;
